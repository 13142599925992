import { css } from '@emotion/react'
import { FontIcon } from '@fluentui/react'
import { IHouseholdInsight } from 'store/api/insights'

const classes = {
  introContainer: css({
    borderBottom: 'solid 1px #B49D77',
    paddingBottom: '10px',
    marginBottom: '10px'
  }),
  introIcon: css({
    color: '#B49D77',
    fontSize: '10px',
    marginRight: '5px'
  }),
  introLink: css({
    color: '#0E687B',
    cursor: 'pointer'
  }),
  introStatement: css({
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: '15px',
    paddingTop: '5px',
    fontSize: '13px'
  })
}

export interface IInsightIntroProps {
  insight: IHouseholdInsight
  onClick: (index: number) => void
}

export const InsightIntro: React.FC<IInsightIntroProps> = ({
  insight,
  onClick
}) => {
  const statements = insight.statements ?? []
  return (
    <>
      <p>
        Currently, this household has {statements.length} insights for review:
      </p>
      {statements.map((stmt, index) => (
        <div key={index} css={classes.introContainer}>
          <div>
            <FontIcon iconName="ChevronRightSmall" css={classes.introIcon} />
            <span css={classes.introLink} onClick={() => onClick(index)}>
              {stmt.type}
            </span>
          </div>
          <div
            css={classes.introStatement}
            dangerouslySetInnerHTML={{
              __html: stmt.statement
            }}
          />
        </div>
      ))}
    </>
  )
}
