import { Callout, ITheme } from '@fluentui/react'
import { useBoolean, useId } from '@fluentui/react-hooks'
import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { IPerformanceReportLog } from 'api/performancereport.types'
import { format } from 'date-fns'
import { groupBy, reverse, sortBy } from 'lodash'
import { FC, useMemo } from 'react'
import { isValidDate } from 'shared/dates'
import { useClasses } from 'shared/hooks/useClasses'
import { useGetPerformanceReportsLogsQuery } from 'store/api/datahub'

const getThemedClasses = (theme: ITheme) => {
  return {
    shareReportCallout: {
      boxShadow: theme.effects.elevation16,
      padding: 10,
      width: 360
    },
    calloutLabel: {
      cursor: 'pointer',
      color: 'rgb(0, 120, 212)'
    },
    scrollBox: {
      minHeight: 100,
      maxHeight: 250,
      overflow: 'auto'
    },
    textLight: {
      color: theme.palette.neutralSecondary
    }
  }
}

const invalidDate = 'Invalid Date'

const getPerformanceReportsLogsPayload = (srcsystemid: string) => {
  return {
    count: true,
    select: ['createdOn', 'requestedBy', 'shareWithUser'],
    filters: [`srcsystemid eq '${srcsystemid}'`, "logType eq 'INFO'"]
  }
}

const getLogsGroupedSorted = (data: IPerformanceReportLog[]) => {
  if (!data.length) {
    return []
  }
  // map the data to format the date or mark it as invalid
  const logsMapped = data.map((log) => {
    const { createdOn = '' } = log
    const createdOnDate = isValidDate(createdOn)
      ? format(createdOn, 'yyyy-MM-dd')
      : invalidDate
    return {
      ...log,
      createdOn: createdOnDate
    }
  })
  // Group by created-on date (formatted yyyy-MM-dd) and person requested-by
  const logsGrouped = groupBy(
    logsMapped,
    (log) => `${log.createdOn}-${log.requestedBy}`
  )
  // convert grouped object into array
  const logsGroupedArray = Object.entries(logsGrouped)
  // Sort the array by createdOn descending
  const logsGroupedSorted = reverse(
    sortBy(logsGroupedArray, [
      (group) => {
        return group[1][0].createdOn
      }
    ])
  )

  return logsGroupedSorted
}

interface IShareReportCalloutProps {
  srcSystemId: string
  sharedReport: string
}

export const ShareReportCallout: FC<IShareReportCalloutProps> = ({
  srcSystemId,
  sharedReport
}) => {
  const [isCalloutVisible, { setTrue: showCallout, setFalse: hideCallout }] =
    useBoolean(false)
  const buttonId = useId(`callout-button-${srcSystemId}`)
  const classes = useClasses(getThemedClasses)

  const performanceReportsLogsPayload = isCalloutVisible
    ? getPerformanceReportsLogsPayload(srcSystemId)
    : skipToken

  const {
    data: reportsLogsData,
    error,
    isFetching
  } = useGetPerformanceReportsLogsQuery(performanceReportsLogsPayload)

  const dataOnlyWhenSuccessful = useMemo(() => {
    const empty: IPerformanceReportLog[] = []
    return error ? empty : reportsLogsData || empty
  }, [reportsLogsData, error])

  const showNoData = useMemo(
    () => !dataOnlyWhenSuccessful?.length && !isFetching,
    [dataOnlyWhenSuccessful?.length, isFetching]
  )

  const calloutPlaceholder = useMemo(() => {
    if (isFetching) {
      return <p>Loading data...</p>
    }
    if (showNoData) {
      return <p>No logs available</p>
    }

    return null
  }, [isFetching, showNoData])

  const logsGroupedSorted = useMemo(
    () => getLogsGroupedSorted(dataOnlyWhenSuccessful),
    [dataOnlyWhenSuccessful]
  )

  return (
    <>
      <span
        onClick={showCallout}
        id={buttonId}
        className={classes.calloutLabel}
      >
        {sharedReport}
      </span>
      {isCalloutVisible && (
        <Callout
          target={`#${buttonId}`}
          className={classes.shareReportCallout}
          onDismiss={hideCallout}
          setInitialFocus
        >
          <div className={classes.scrollBox}>
            {calloutPlaceholder ||
              logsGroupedSorted.map((log) => {
                const { createdOn, requestedBy } = log[1][0]
                const dateValid = createdOn !== invalidDate
                const createdOnDate = dateValid
                  ? format(createdOn, 'M-d-yyyy')
                  : createdOn
                const shareWithUser = log[1]
                  .map((log) => log.shareWithUser)
                  .join(', ')

                return (
                  <div key={log[0]} css={{ marginBottom: 10 }}>
                    <strong>{createdOnDate}</strong>
                    <div>
                      Shared by:{' '}
                      <span className={classes.textLight}>{requestedBy}</span>
                    </div>
                    <div>
                      Shared with:{' '}
                      <span className={classes.textLight}>{shareWithUser}</span>
                    </div>
                  </div>
                )
              })}
          </div>
        </Callout>
      )}
    </>
  )
}
