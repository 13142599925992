import { SearchBox } from '@fluentui/react'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchResponseType } from '../../../../api/common.types'
import { IListsActions } from '../store/actions'
import { IListsSelectors } from '../store/selectors'

export const createConnectedListsSearchComponent = <
  T extends SearchResponseType
>(
  listActions: IListsActions<T>,
  listSelectors: IListsSelectors<T>
) => {
  const { uiActions } = listActions
  const { uiSelectors } = listSelectors

  const ConnectedListsSearchComponent: React.FC = () => {
    const dispatch = useDispatch()
    const searchText = useSelector(uiSelectors.getSearchText)

    const onLegalEntityFiltered = useCallback(
      (_: any, newValue?: string) => {
        dispatch(uiActions.updateSearchText(newValue || ''))
      },
      [dispatch]
    )

    return (
      <SearchBox
        placeholder="Search"
        onChange={onLegalEntityFiltered}
        autoComplete="off"
        value={searchText || ''}
        styles={{
          root: { width: 250 }
        }}
      />
    )
  }
  return ConnectedListsSearchComponent
}
