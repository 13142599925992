import { createReducer } from 'typesafe-actions'
import {
  closeMonthActions,
  exportReportRetirementPlansToExcelActions,
  exportRetirementPlansToExcelActions,
  fetchPlanProvidersActions,
  fetchPlansByProviderActions,
  fetchPlansToDistributeCashActions,
  fetchProjectedAmountByPlansActions,
  fetchRetirementPlanAllocationActions,
  fetchRetirementPlanReportActions,
  fetchRetirementPlanTransactionsAction,
  fetchRetirementPlanTransactionsByClientActions,
  fetchRetirementProductPlans,
  getLookUPFieldsActions,
  RetirementProductActionTypes,
  setSelectedAccrualPeriodActions,
  setSelectedMonthYearActions,
  setSelectedRetirementPlanTypeActions,
  setSelectedPeriodActions,
  setSelectedRetirementPlan,
  updateCashReceiptToPlanActions,
  updatePlanPlanByProviderActions,
  updatePlanProviderActions,
  updateProjectedAmountByPlansActions,
  updateRetirementCashReceiptActions,
  viewStatementActions,
  exportPlanProvidersActions
} from './actions'
import {
  IExportPlanProviderToExcel,
  IFetchPlanToDistributeCash,
  ILookupFieldsResponse,
  IPlanAllocations,
  IPlanByProvider,
  IPlanProvider,
  IProjectedAmount,
  IRetirementPlanReport,
  IRetirementProductCashReceipt,
  IRetirementProductPlan
} from './types'

export interface IRetirementProductState {
  selectedRetirementPlanType?: string
  retirementPlans: {
    isLoading: boolean
    plans?: IRetirementProductPlan[]
    error?: Error
  }
  retirementTransactions?: {
    isLoading: boolean
    revenues?: IRetirementProductCashReceipt[]
    cashreceipts?: IRetirementProductCashReceipt[]
    error?: Error
  }

  planByProvidersForExcelState?: {
    isLoading: boolean
    error?: Error
    planProvidersForExcel?: IExportPlanProviderToExcel[]
  }
  planAllocations?: {
    isLoading: boolean
    error?: Error
    allocations?: IPlanAllocations
  }
  selectedPlan?: IRetirementProductPlan
  updatedCashReceipt?: {
    isLoading: boolean
    error?: Error
  }
  selectedMonthYear?: string
  selectedPeriod?: string
  projectAmountByPlan?: {
    projectedAmounts?: IProjectedAmount[]
    isLoading: boolean
    error?: Error
  }
  updateByPlanId?: {
    inProgress: boolean
    error?: Error
  }
  planProviders?: {
    isLoading: boolean
    error?: Error
    planProviders?: IPlanProvider[]
  }
  updatePlanProvider?: {
    inProgress: boolean
    error?: Error
  }
  exportRetirementPlan?: {
    inProgress: boolean
    error?: Error
  }
  plansToDistributeCash?: {
    isLoading: boolean
    error?: Error
    plans?: IFetchPlanToDistributeCash[]
  }
  updateCashReceiptToPlan?: {
    inProgress: boolean
    error?: Error
  }
  selectedAccrualPeriod?: string
  plansByProviderState?: IPlanByProviderState[]
  closeMonthState?: {
    inProgress: boolean
    error?: Error
    successMsg?: string
  }
  retirementPlanRpt?: {
    isLoading: boolean
    error?: Error
    plans?: IRetirementPlanReport[]
  }
  updatePlanByProviderState?: {
    inProgress: boolean
    error?: Error
    successMsg?: string
  }
  exportReportRetirementPlan?: {
    inProgress: boolean
    error?: Error
  }
  lookupFields?: {
    isLoading: boolean
    error?: Error
    lookupFieldResponse?: ILookupFieldsResponse
  }
}
interface IPlanByProviderState {
  isLoading: boolean
  planproviderId?: number
  error?: Error
  plans?: IPlanByProvider[]
}

const initialState: IRetirementProductState = {
  selectedRetirementPlanType: 'Y',
  retirementPlans: {
    isLoading: false
  }
}

export const retirementProductReducer = createReducer<
  IRetirementProductState,
  RetirementProductActionTypes
>(initialState)
  .handleAction(fetchRetirementProductPlans.request, (state) => ({
    ...state,
    retirementPlans: {
      ...state.retirementPlans,
      error: undefined,
      isLoading: true
    },
    closeMonthState: undefined
  }))
  .handleAction(fetchRetirementProductPlans.success, (state, action) => ({
    ...state,
    retirementPlans: {
      ...state.retirementPlans,
      plans: action.payload || [],
      error: undefined,
      isLoading: false
    }
  }))
  .handleAction(fetchRetirementProductPlans.failure, (state, action) => ({
    ...state,
    retirementPlans: {
      ...state.retirementPlans,
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(fetchRetirementPlanTransactionsAction.request, (state) => ({
    ...state,
    retirementTransactions: {
      ...state.retirementTransactions,
      isLoading: true,
      cashreceipts: undefined,
      revenues: undefined,
      error: undefined
    },
    closeMonthState: undefined
  }))
  .handleAction(
    fetchRetirementPlanTransactionsAction.success,
    (state, action) => ({
      ...state,
      retirementTransactions: {
        ...state.retirementTransactions,
        isLoading: false,
        error: undefined,
        cashreceipts: action.payload?.cashreceipts || [],
        revenues: action.payload?.revenue || []
      }
    })
  )
  .handleAction(
    fetchRetirementPlanTransactionsAction.failure,
    (state, action) => ({
      ...state,
      retirementTransactions: {
        ...state.retirementTransactions,
        isLoading: false,
        error: action.payload
      }
    })
  )
  .handleAction(fetchRetirementPlanTransactionsAction.clear, (state) => ({
    ...state,
    retirementTransactions: undefined,
    closeMonthState: undefined
  }))
  .handleAction(setSelectedRetirementPlan.selectedPlan, (state, action) => ({
    ...state,
    retirementTransactions: undefined,
    selectedPlan: action.payload || undefined
  }))
  .handleAction(updateRetirementCashReceiptActions.request, (state) => ({
    ...state,
    updatedCashReceipt: {
      isLoading: true,
      error: undefined
    },
    closeMonthState: undefined
  }))
  .handleAction(
    updateRetirementCashReceiptActions.failure,
    (state, action) => ({
      ...state,
      updatedCashReceipt: {
        isLoading: false,
        error: action.payload
      }
    })
  )
  .handleAction(updateRetirementCashReceiptActions.success, (state) => ({
    ...state,
    updatedCashReceipt: {
      isLoading: false,
      error: undefined
    }
  }))
  .handleAction(
    fetchRetirementPlanTransactionsByClientActions.request,
    (state) => ({
      ...state,
      retirementTransactions: {
        ...state.retirementTransactions,
        isLoading: true,
        cashreceipts: undefined,
        revenues: undefined,
        error: undefined
      }
    })
  )
  .handleAction(
    fetchRetirementPlanTransactionsByClientActions.success,
    (state, action) => ({
      ...state,
      retirementTransactions: {
        ...state.retirementTransactions,
        isLoading: false,
        error: undefined,
        cashreceipts: [],
        revenues: action.payload || []
      }
    })
  )
  .handleAction(
    fetchRetirementPlanTransactionsByClientActions.failure,
    (state, action) => ({
      ...state,
      retirementTransactions: {
        ...state.retirementTransactions,
        isLoading: false,
        error: action.payload
      }
    })
  )
  .handleAction(
    setSelectedMonthYearActions.selectedMonthYear,
    (state, action) => ({
      ...state,
      selectedMonthYear: action.payload || undefined,
      closeMonthState: undefined
    })
  )
  .handleAction(fetchProjectedAmountByPlansActions.request, (state) => ({
    ...state,
    projectAmountByPlan: {
      ...state?.projectAmountByPlan,
      isLoading: true,
      error: undefined
    },
    closeMonthState: undefined
  }))
  .handleAction(
    fetchProjectedAmountByPlansActions.success,
    (state, action) => ({
      ...state,
      projectAmountByPlan: {
        projectedAmounts: action.payload || [],
        isLoading: false,
        error: undefined
      }
    })
  )
  .handleAction(
    fetchProjectedAmountByPlansActions.failure,
    (state, action) => ({
      ...state,
      projectAmountByPlan: {
        ...state.projectAmountByPlan,
        isLoading: false,
        error: action.payload
      }
    })
  )
  .handleAction(fetchProjectedAmountByPlansActions.clear, (state) => ({
    ...state,
    projectAmountByPlan: undefined,
    updateByPlanId: undefined
  }))
  .handleAction(updateProjectedAmountByPlansActions.request, (state) => ({
    ...state,
    updateByPlanId: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(updateProjectedAmountByPlansActions.success, (state) => ({
    ...state,
    updateByPlanId: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(
    updateProjectedAmountByPlansActions.failure,
    (state, action) => ({
      ...state,
      updateByPlanId: {
        inProgress: false,
        error: action.payload
      }
    })
  )
  .handleAction(fetchPlanProvidersActions.request, (state) => ({
    ...state,
    planProviders: {
      isLoading: true,
      error: undefined,
      planProviders: undefined
    }
  }))
  .handleAction(fetchPlanProvidersActions.success, (state, action) => ({
    ...state,
    planProviders: {
      isLoading: false,
      error: undefined,
      planProviders: action.payload
    }
  }))
  .handleAction(fetchPlanProvidersActions.failure, (state, action) => ({
    ...state,
    planProviders: {
      ...state.planProviders,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchPlanProvidersActions.clear, (state) => ({
    ...state,
    planProviders: undefined
  }))
  .handleAction(updatePlanProviderActions.request, (state) => ({
    ...state,
    updatePlanProvider: {
      inProgress: true,
      error: undefined
    },
    closeMonthState: undefined
  }))
  .handleAction(updatePlanProviderActions.success, (state) => ({
    ...state,
    updatePlanProvider: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(updatePlanProviderActions.failure, (state, action) => ({
    ...state,
    updatePlanProvider: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(viewStatementActions.request, (state) => ({
    ...state,
    viewStatement: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(viewStatementActions.success, (state) => ({
    ...state,
    viewStatement: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(viewStatementActions.failure, (state, action) => ({
    ...state,
    viewStatement: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(exportRetirementPlansToExcelActions.request, (state) => ({
    ...state,
    exportRetirementPlan: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(exportRetirementPlansToExcelActions.success, (state) => ({
    ...state,
    exportRetirementPlan: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(
    exportRetirementPlansToExcelActions.failure,
    (state, action) => ({
      ...state,
      exportRetirementPlan: {
        inProgress: false,
        error: action.payload
      }
    })
  )
  .handleAction(setSelectedPeriodActions.setPeriod, (state, action) => ({
    ...state,
    selectedPeriod: action.payload
  }))
  .handleAction(fetchPlansToDistributeCashActions.request, (state) => ({
    ...state,
    plansToDistributeCash: {
      ...state.plansToDistributeCash,
      isLoading: true,
      plans: undefined,
      error: undefined
    }
  }))
  .handleAction(fetchPlansToDistributeCashActions.success, (state, action) => ({
    ...state,
    plansToDistributeCash: {
      ...state.plansToDistributeCash,
      isLoading: false,
      error: undefined,
      plans: action.payload
    }
  }))
  .handleAction(fetchPlansToDistributeCashActions.failure, (state, action) => ({
    ...state,
    plansToDistributeCash: {
      ...state.plansToDistributeCash,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchPlansToDistributeCashActions.clear, (state) => ({
    ...state,
    plansToDistributeCash: undefined
  }))
  .handleAction(updateCashReceiptToPlanActions.request, (state) => ({
    ...state,
    updateCashReceiptToPlan: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(updateCashReceiptToPlanActions.failure, (state, action) => ({
    ...state,
    updateCashReceiptToPlan: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(updateCashReceiptToPlanActions.success, (state) => ({
    ...state,
    updateCashReceiptToPlan: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(setSelectedAccrualPeriodActions.setPeriod, (state, action) => ({
    ...state,
    selectedAccrualPeriod: action.payload
  }))
  .handleAction(fetchPlansByProviderActions.request, (state, action) => {
    const clonedPlanProviderState = state?.plansByProviderState
      ? [...state.plansByProviderState]
      : []
    const matchedItemIdx = clonedPlanProviderState?.findIndex(
      (x) => x.planproviderId === action.payload.planproviderid
    )
    if (matchedItemIdx === -1) {
      clonedPlanProviderState.push({
        isLoading: false,
        error: undefined,
        plans: undefined,
        planproviderId: action.payload.planproviderid
      })
    } else {
      clonedPlanProviderState?.push({
        isLoading: true,
        error: undefined,
        planproviderId: action.payload?.planproviderid,
        plans: []
      })
    }
    return {
      ...state,
      plansByProviderState: clonedPlanProviderState,
      updatePlanByProviderState: undefined
    }
  })
  .handleAction(fetchPlansByProviderActions.success, (state, action) => {
    const clonedPlanProviderState = state?.plansByProviderState
      ? [...state.plansByProviderState]
      : []
    const matchedItemIdx = clonedPlanProviderState?.findIndex(
      (x) => x.planproviderId === action.payload.planproviderId
    )
    if (matchedItemIdx === -1) {
      clonedPlanProviderState.push({
        isLoading: false,
        error: undefined,
        plans: action.payload.plans,
        planproviderId: action.payload.planproviderId
      })
    } else {
      clonedPlanProviderState.splice(matchedItemIdx, 1, {
        isLoading: false,
        error: undefined,
        plans: action.payload.plans,
        planproviderId: action.payload.planproviderId
      })
    }
    return {
      ...state,
      plansByProviderState: clonedPlanProviderState,
      updatePlanByProviderState: undefined
    }
  })
  .handleAction(fetchPlansByProviderActions.failure, (state, action) => {
    const clonedPlanProviderState = state?.plansByProviderState
      ? [...state.plansByProviderState]
      : []
    const matchedItemIdx = clonedPlanProviderState?.findIndex(
      (x) => x.planproviderId === action.payload.planproviderId
    )
    clonedPlanProviderState.splice(matchedItemIdx, 1, {
      isLoading: false,
      error: action.payload.error,
      plans: undefined,
      planproviderId: action.payload.planproviderId
    })
    return {
      ...state,
      plansByProviderState: clonedPlanProviderState,
      updatePlanByProviderState: undefined
    }
  })
  .handleAction(closeMonthActions.request, (state) => ({
    ...state,
    closeMonthState: {
      inProgress: true,
      error: undefined,
      successMsg: undefined
    }
  }))
  .handleAction(closeMonthActions.failure, (state, action) => ({
    ...state,
    closeMonthState: {
      inProgress: false,
      error: action.payload,
      successMsg: undefined
    }
  }))
  .handleAction(closeMonthActions.success, (state, action) => ({
    ...state,
    closeMonthState: {
      inProgress: false,
      error: undefined,
      successMsg: action.payload || undefined
    }
  }))
  .handleAction(fetchRetirementPlanReportActions.request, (state) => ({
    ...state,
    retirementPlanRpt: {
      ...state.retirementPlanRpt,
      error: undefined,
      isLoading: true
    }
  }))
  .handleAction(fetchRetirementPlanReportActions.success, (state, action) => ({
    ...state,
    retirementPlanRpt: {
      error: undefined,
      isLoading: false,
      plans: action.payload
    }
  }))
  .handleAction(fetchRetirementPlanReportActions.failure, (state, action) => ({
    ...state,
    retirementPlanRpt: {
      ...state.retirementPlanRpt,
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(updatePlanPlanByProviderActions.request, (state) => ({
    ...state,
    updatePlanByProviderState: {
      inProgress: true,
      error: undefined,
      successMsg: undefined
    }
  }))
  .handleAction(updatePlanPlanByProviderActions.failure, (state, action) => ({
    ...state,
    updatePlanByProviderState: {
      inProgress: false,
      error: action.payload,
      successMsg: undefined
    }
  }))
  .handleAction(updatePlanPlanByProviderActions.success, (state, action) => ({
    ...state,
    updatePlanByProviderState: {
      inProgress: false,
      error: undefined,
      successMsg: action.payload
    }
  }))
  .handleAction(exportReportRetirementPlansToExcelActions.request, (state) => ({
    ...state,
    exportReportRetirementPlan: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(exportReportRetirementPlansToExcelActions.success, (state) => ({
    ...state,
    exportReportRetirementPlan: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(
    exportReportRetirementPlansToExcelActions.failure,
    (state, action) => ({
      ...state,
      exportReportRetirementPlan: {
        inProgress: false,
        error: action.payload
      }
    })
  )
  .handleAction(fetchRetirementPlanAllocationActions.request, (state) => ({
    ...state,
    planAllocations: {
      ...state.planAllocations,
      isLoading: true,
      error: undefined
    },
    closeMonthState: undefined
  }))
  .handleAction(
    fetchRetirementPlanAllocationActions.success,
    (state, action) => ({
      ...state,
      planAllocations: {
        isLoading: false,
        error: undefined,
        allocations: action.payload
      }
    })
  )
  .handleAction(
    fetchRetirementPlanAllocationActions.failure,
    (state, action) => ({
      ...state,
      planAllocations: {
        ...state.planAllocations,
        isLoading: false,
        error: action.payload
      }
    })
  )
  .handleAction(fetchRetirementPlanAllocationActions.clear, (state) => ({
    ...state,
    planAllocations: undefined,
    closeMonthState: undefined
  }))
  .handleAction(getLookUPFieldsActions.request, (state) => ({
    ...state,
    lookupFields: {
      ...state.lookupFields,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(getLookUPFieldsActions.success, (state, action) => ({
    ...state,
    lookupFields: {
      ...state.lookupFields,
      isLoading: false,
      error: undefined,
      lookupFieldResponse: action.payload
    }
  }))
  .handleAction(getLookUPFieldsActions.failure, (state, action) => ({
    ...state,
    lookupFields: {
      ...state.lookupFields,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(
    setSelectedRetirementPlanTypeActions.selectedRetirementPlanType,
    (state, action) => ({
      ...state,
      selectedRetirementPlanType: action.payload || ''
    })
  )
  .handleAction(exportPlanProvidersActions.request, (state) => ({
    ...state,
    planByProvidersForExcelState: {
      ...state.planByProvidersForExcelState,
      error: undefined,
      isLoading: true
    }
  }))
  .handleAction(exportPlanProvidersActions.success, (state, action) => ({
    ...state,
    planByProvidersForExcelState: {
      isLoading: false,
      error: undefined,
      planProvidersForExcel: action.payload
    }
  }))
  .handleAction(exportPlanProvidersActions.failure, (state, action) => ({
    ...state,
    planProvidersForExcelState: {
      ...state.planByProvidersForExcelState,
      error: action.payload,
      isLoading: false
    }
  }))
