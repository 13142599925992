import { format } from 'date-fns'

import { PaymentMM } from '../../Dashboard/modules/Dashboard/store/types'
import { IMMSummarySI } from '../api/types'
import {
  CheckingorSavings,
  ReceiveorDisbursement,
  RecipientType,
  SIMMDirection
} from '../EFT/Constants'

import { ISIValidateRequest } from '../store/EFT/types'
import { IMMValidateRequest, IWireInitiationForm } from '../store/types'
import {
  generateMMValidateRequestForWire,
  getRetirementDistribution,
  processDate,
  // generateMMValidateRequestForWire,
  //guid,
  //guid,
  truncateFFCFields
} from './Utilities'

export const getAccountType = (accountType?: string | null) => {
  if (
    accountType?.toLowerCase() === 'c' ||
    accountType?.toLowerCase() === 's'
  ) {
    return accountType
  }

  if (accountType?.toLowerCase() === CheckingorSavings.Checking) {
    return 'C'
  } else if (accountType?.toLowerCase() === CheckingorSavings.Savings) {
    return 'S'
  }

  return ''
}

const getFrom = (siData: IWireInitiationForm) => {
  return {
    accountnumber: '',
    isexternal: true,
    accountname: '',
    bankidtype: 'F',
    isira: siData?.senderDetails?.isRetirementAccount,
    accounttype: '',
    siid: siData?.wireAmount?.standingInstructionId,
    abanumber: siData.beneficiary?.bankIdentifierNumber || '',
    bankname: '',
    isthirdparty:
      siData?.wireAmount?.recipientType === RecipientType.ThirdParty,
    isthirdpartycommon: false
  }
}

const toAccount = (siData: IWireInitiationForm) => {
  return {
    accountnumber: '',
    accountname: '',
    bankidtype: 'F',
    accounttype: '',
    abanumber: siData.beneficiary?.bankIdentifierNumber || '',
    swiftcode: undefined,
    bankname: '',
    isthirdparty:
      siData?.wireAmount?.recipientType === RecipientType.ThirdParty,
    isexternal: false,
    isira: siData?.senderDetails?.isRetirementAccount,
    siid: siData?.wireAmount?.standingInstructionId,
    isthirdpartycommon: false
  }
}

const updateFromToAccount = (siData: IWireInitiationForm) => {
  let fromaccount = getFrom(siData)
  let toaccount = toAccount(siData)

  const transactionType = siData.wireAmount?.isexternal?.toLocaleLowerCase()

  if (
    transactionType === ReceiveorDisbursement.Disbursement.toLocaleLowerCase()
  ) {
    fromaccount = {
      ...fromaccount,
      accountnumber: siData.senderDetails?.account ?? '',
      accountname: siData.senderDetails?.name ?? '',
      isexternal:
        transactionType !==
        ReceiveorDisbursement.Disbursement.toLocaleLowerCase(),
      accounttype: getAccountType(siData?.beneficiary?.accountType ?? '')
    }
    toaccount = {
      ...toaccount,
      accountnumber: siData.beneficiary?.account ?? '',
      accountname: siData.beneficiary?.name ?? '',
      accounttype: getAccountType(siData?.beneficiary?.accountType ?? ''),
      isexternal:
        transactionType ===
        ReceiveorDisbursement.Disbursement.toLocaleLowerCase(),
      bankname: siData.beneficiary?.bankName ?? '',
      isthirdparty:
        siData?.wireAmount?.recipientType === RecipientType.ThirdParty,
      isthirdpartycommon: !!(
        siData?.wireAmount?.useExistingSI &&
        siData?.wireAmount?.recipientType === RecipientType.ThirdPartyCommon
      )
    }
  } else {
    fromaccount = {
      ...fromaccount,

      accountnumber: siData.beneficiary?.account ?? '',
      accountname: siData.beneficiary?.name ?? '',
      isexternal:
        transactionType === ReceiveorDisbursement.Receipt.toLocaleLowerCase(),
      bankname: siData.beneficiary?.bankName ?? '',
      accounttype: getAccountType(siData?.beneficiary?.accountType ?? '') ?? '',
      isthirdpartycommon: !!(
        siData?.wireAmount?.useExistingSI &&
        siData?.wireAmount?.recipientType === RecipientType.ThirdPartyCommon
      )
    }
    toaccount = {
      ...toaccount,
      accountnumber: siData.senderDetails?.account ?? '',
      accounttype: getAccountType(siData?.beneficiary?.accountType ?? '') ?? '',
      accountname: siData.senderDetails?.name ?? '',
      abanumber: siData.beneficiary?.bankIdentifierNumber ?? '',
      isexternal:
        transactionType !== ReceiveorDisbursement.Receipt.toLocaleLowerCase(),
      bankname: siData.beneficiary?.bankName ?? '',
      isthirdparty:
        siData?.wireAmount?.recipientType === RecipientType.ThirdParty
    }
  }
  return { fromaccount: fromaccount, toaccount: toaccount }
}

export const generateMMValidateRequestForEFT = (
  siData: IWireInitiationForm,
  wireType?: string
): IMMValidateRequest => {
  const { toaccount, fromaccount } = updateFromToAccount(siData)
  const today = new Date()
  const currentYear = format(today, 'yyyy')
  const prevYear = (Number(currentYear) - 1).toString()
  const request = {
    ...generateMMValidateRequestForWire(
      siData,
      wireType ?? siData?.wireType ?? 'A'
    ),
    fromaccount: { ...fromaccount },
    toaccount: {
      ...toaccount,
      isprioryear:
        siData?.wireAmount?.isexternal === ReceiveorDisbursement.Receipt &&
        siData?.taxWithholdings?.conPromptYearInd === 'Y' &&
        siData?.taxWithholdings?.year === prevYear
    },
    destinationcountry: 'US',
    isfuturedated:
      siData?.wireAmount?.tranDate && today < siData?.wireAmount?.tranDate,
    iseft: true,
    iswire: false,
    isira: siData?.senderDetails?.isRetirementAccount,
    loi: true,
    amount: getAmountForMMValidate(siData),
    retdistreason:
      siData?.wireAmount?.isexternal === ReceiveorDisbursement.Disbursement
        ? getRetirementDistribution(siData)
        : undefined,
    istrnamtincludetaxwithhold:
      siData?.wireAmount?.isexternal === ReceiveorDisbursement.Disbursement
        ? !(siData?.taxWithholdings?.includeTax || false)
        : false,

    transactiondate: processDate(siData)
  }

  if (
    siData?.senderDetails?.isRetirementAccount &&
    siData?.wireAmount?.isexternal === ReceiveorDisbursement.Receipt
  ) {
    request.retcontribreason = siData?.taxWithholdings?.conReasonCode
      ? [
          {
            reasoncode: siData?.taxWithholdings?.conReasonCode,
            reasondesc: siData?.taxWithholdings?.conReasonDesc,
            reasonsubcode: siData?.taxWithholdings?.conReasonSubcode,
            promptyearind: siData?.taxWithholdings?.conPromptYearInd
          }
        ]
      : undefined
    request.currentyear =
      siData?.taxWithholdings?.conPromptYearInd === 'Y' &&
      siData?.taxWithholdings?.year === currentYear
        ? currentYear
        : undefined

    request.prioryear =
      siData?.taxWithholdings?.conPromptYearInd === 'Y' &&
      siData?.taxWithholdings?.year === prevYear
        ? prevYear
        : undefined
  }
  return request
}

const getAmountForMMValidate = (siData: IWireInitiationForm) => {
  if (siData?.wireAmount?.accountCloseout) {
    return siData?.senderDetails?.availablecashtowithdraw || 1
  }
  return siData?.wireAmount?.amount ? Number(siData?.wireAmount?.amount) : 0
}

export const generateSIValidateRequestForEFT = (
  siData: IWireInitiationForm
): ISIValidateRequest => {
  return {
    seqid: '1',
    standinginstructionid: siData.wireAmount?.standingInstructionId,
    accountnumber: siData.senderDetails?.account,
    iseft: true,
    isjnl: false,
    isthirdparty: siData.wireAmount?.recipientType === RecipientType.ThirdParty,
    isthirdpartycommon: siData.wireAmount?.isThirdPartyCommon,
    abanumber: siData.beneficiary?.bankIdentifierNumber,
    bankaccountname: truncateFFCFields(siData.beneficiary?.bankName, 22),
    bankname: truncateFFCFields(siData.beneficiary?.bankName, 22),
    bankaddress: '',
    bankaccountnumber: siData.beneficiary?.account,
    bankaccounttype: truncateFFCFields(siData.beneficiary?.accountType, 1),
    memo: siData.details?.memo,
    beneficiarynameline1: siData.beneficiary?.name
  }
  // return SIrequest
}

export const isReceiptorisDisbursement = (
  item: IMMSummarySI
): {
  isExternal: string
  siimiDirection: string
} => {
  if (
    !item?.isthirdparty &&
    !item?.isrecepientonly &&
    !item?.isdisbursemenonly
  ) {
    return {
      isExternal: '',
      siimiDirection: SIMMDirection.Both
    }
  } else if (!item?.isthirdparty && item?.isrecepientonly) {
    return {
      isExternal: ReceiveorDisbursement.Receipt,
      siimiDirection: SIMMDirection.Receive
    }
  } else if (item?.isthirdparty || item?.isdisbursemenonly) {
    return {
      isExternal: ReceiveorDisbursement.Disbursement,
      siimiDirection: SIMMDirection.Disbursement
    }
  } else {
    return {
      isExternal: '',
      siimiDirection: ''
    }
  }
}

export const getrecipient = (receipient: PaymentMM | undefined) => {
  if (receipient?.tranInfo?.isThirdPartyCommon) {
    return RecipientType.ThirdPartyCommon
  } else if (receipient?.tranInfo?.isThirdParty) {
    return RecipientType.ThirdParty
  } else {
    return RecipientType.FirstParty
  }
}
