import { IColumnDefinition } from '../core/contracts/IColumnDefinition'
import { BaseColumnDefinitions } from '../shared/BaseColumnDefinitions'
import { CommonColumnDefinitions } from '../shared/CommonColumnDefinitions'
import { CommonColumnIdsEnum } from '../shared/CommonColumnIdsEnum'

enum ColumnIdsEnum {
  lastUpdated = 'lastUpdated',
  lastModified = 'lastModified',
  created = 'created',
  revenueYTD = 'revenueYTD',
  revenueMTD = 'revenueMTD',
  revenueQTD = 'revenueQTD',
  revenueT12 = 'revenueT12',
  activeInd = 'activeInd',
  feeEligibleInd = 'feeEligibleInd',
  employeeInd = 'employeeInd',
  feeChargeType = 'feeChargeType',
  nextBillingCycleChargeDate = 'nextBillingCycleChargeDate',
  nextBillingCycleMeasureDate = 'nextBillingCycleMeasureDate',
  billingAUS = 'billingAUS',
  MonthlyAvgSweepBal = 'MonthlyAvgSweepBal',
  AdvisedOnly = 'AdvisedOnly',
  LoanOutstandingOutsideNfs = 'LoanOutstandingOutsideNfs',
  LoanOutstandingNfs = 'LoanOutstandingNfs',
  AdminReporting = 'AdminReporting',
  RockConnectAccBalance = 'RockConnectAccBalance',
  Office = 'Office',
  Division = 'Division'
}

export const HouseholdColumnIds = { ...ColumnIdsEnum, ...CommonColumnIdsEnum }
export type HouseholdColumnIds = typeof HouseholdColumnIds

export const HouseholdColumnDefinitions: Record<string, IColumnDefinition> = {
  ...CommonColumnDefinitions,
  [HouseholdColumnIds.clientAdvisorId]: {
    id: HouseholdColumnIds.clientAdvisorId,
    name: 'Advisor Id',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: false,
    searchable: true,
    facetable: true,
    collectionPath: 'Advisors',
    dataPath: 'ClientAdvisorID'
  },
  [HouseholdColumnIds.clientAdvisor]: {
    id: HouseholdColumnIds.clientAdvisor,
    name: 'Advisor',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: false,
    searchable: true,
    facetable: true,
    collectionPath: 'Advisors',
    dataPath: 'ClientAdvisor'
  },
  [HouseholdColumnIds.team]: {
    id: HouseholdColumnIds.team,
    name: 'Team',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: false,
    searchable: true,
    facetable: true,
    collectionPath: 'Advisors',
    dataPath: 'ClientAdvisorTeam'
  },
  [HouseholdColumnIds.Office]: {
    id: HouseholdColumnIds.Office,
    name: 'Office',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: false,
    searchable: true,
    facetable: true,
    collectionPath: 'Advisors',
    dataPath: 'HubName'
  },
  [HouseholdColumnIds.Division]: {
    id: HouseholdColumnIds.Division,
    name: 'Division',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: false,
    searchable: true,
    facetable: true,
    collectionPath: 'Advisors',
    dataPath: 'Division'
  },
  [HouseholdColumnIds.householdName]: {
    id: HouseholdColumnIds.householdName,
    select: ['id', 'householdId', 'householdName', 'Account', 'Parties'],
    searchFields: ['householdName', 'Account', 'Parties/LegalEntityName'],
    name: 'Household Name',
    dataPath: 'householdName',
    type: 'string',
    width: 380,
    filterable: true,
    sortable: true,
    facetable: false,
    searchable: true
  },
  [HouseholdColumnIds.aum]: {
    ...(BaseColumnDefinitions.aum as IColumnDefinition),
    dataPath: 'householdKPI/AumManaged'
  },
  [HouseholdColumnIds.aus]: {
    ...(BaseColumnDefinitions.aus as IColumnDefinition),
    select: [
      'householdKPI/AumBrokerage',
      'householdKPI/AumManaged',
      'householdKPI/LoanOutstandingNfs',
      'householdKPI/LoanOutstandingOutsideNfs',
      'householdKPI/annuity',
      'householdKPI/AdvisedOnly',
      'householdKPI/AdminReportingAssets',
      'householdKPI/AumTotal'
    ],
    dataPath: 'householdKPI/AumTotal'
  },
  [HouseholdColumnIds.brokerageAssets]: {
    ...(BaseColumnDefinitions.brokerageAssets as IColumnDefinition),
    dataPath: 'householdKPI/AumBrokerage'
  },
  [HouseholdColumnIds.loansOutstanding]: {
    ...(BaseColumnDefinitions.loansOutstanding as IColumnDefinition),
    dataPath: 'householdKPI/LoanOutstanding'
  },
  [HouseholdColumnIds.purchasingPower]: {
    ...(BaseColumnDefinitions.purchasingPower as IColumnDefinition),
    dataPath: 'householdKPI/cashAvlToTrade'
  },
  [HouseholdColumnIds.netNewAssets]: {
    ...(BaseColumnDefinitions.netNewAssets as IColumnDefinition),
    dataPath: 'householdKPI/TOA'
  },
  [HouseholdColumnIds.netNewMoney]: {
    ...(BaseColumnDefinitions.netNewMoney as IColumnDefinition),
    dataPath: 'householdKPI/NetNewMoney'
  },
  [HouseholdColumnIds.annuity]: {
    ...(BaseColumnDefinitions.annuity as IColumnDefinition),
    type: 'number',
    dataPath: 'householdKPI/annuity'
  },
  [HouseholdColumnIds.lastUpdated]: {
    id: HouseholdColumnIds.lastUpdated,
    name: 'Last Updated Date',
    type: 'date',
    width: 100,
    filterable: true,
    sortable: true,
    dataPath: 'LastUpdatedAt'
  },
  [HouseholdColumnIds.lastModified]: {
    id: HouseholdColumnIds.lastModified,
    name: 'Last Modified Date',
    type: 'date',
    width: 100,
    filterable: true,
    sortable: true,
    dataPath: 'lastUpdatedDate'
  },
  [HouseholdColumnIds.created]: {
    id: HouseholdColumnIds.created,
    name: 'Created Date',
    type: 'date',
    width: 100,
    filterable: true,
    sortable: true,
    dataPath: 'createdDate'
  },
  [HouseholdColumnIds.revenueMTD]: {
    id: HouseholdColumnIds.revenueMTD,
    name: 'MTD Revenue',
    dataPath: 'revenue/MTDcompRevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [HouseholdColumnIds.revenueQTD]: {
    id: HouseholdColumnIds.revenueQTD,
    name: 'QTD Revenue',
    dataPath: 'revenue/QTDcompRevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [HouseholdColumnIds.revenueYTD]: {
    id: HouseholdColumnIds.revenueYTD,
    name: 'YTD Revenue',
    dataPath: 'revenue/YTDcompRevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [HouseholdColumnIds.revenueT12]: {
    id: HouseholdColumnIds.revenueT12,
    name: 'T12 Revenue',
    dataPath: 'revenue/ttmrevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [HouseholdColumnIds.activeInd]: {
    id: HouseholdColumnIds.activeInd,
    name: 'Active Indicator',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    facetable: true,
    searchable: false,
    dataPath: 'ActiveFlag'
  },
  [HouseholdColumnIds.feeEligibleInd]: {
    id: HouseholdColumnIds.feeEligibleInd,
    name: 'Household Fee Eligible',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    facetable: true,
    searchable: false,
    dataPath: 'householdFeeIndicator'
  },
  [HouseholdColumnIds.employeeInd]: {
    id: HouseholdColumnIds.employeeInd,
    name: 'Employee Indicator',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true,
    searchable: false,
    dataPath: 'employeeCode'
  },
  [HouseholdColumnIds.feeChargeType]: {
    id: HouseholdColumnIds.feeChargeType,
    name: 'Billing Methodology',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    facetable: true,
    searchable: false,
    dataPath: 'chargeType'
  },
  [HouseholdColumnIds.nextBillingCycleChargeDate]: {
    id: HouseholdColumnIds.nextBillingCycleChargeDate,
    name: 'Next Billing Cycle Charge Date',
    type: 'date-only',
    filterable: true,
    sortable: true,
    dataPath: 'nextBillingCycleChargeDate'
  },
  [HouseholdColumnIds.nextBillingCycleMeasureDate]: {
    id: HouseholdColumnIds.nextBillingCycleMeasureDate,
    name: 'Next Billing Cycle Measure Date',
    type: 'date-only',
    filterable: true,
    sortable: true,
    dataPath: 'nextBillingCycleMeasureDate'
  },
  [HouseholdColumnIds.billingAUS]: {
    id: HouseholdColumnIds.billingAUS,
    name: 'Billing AUS',
    type: 'number',
    filterable: true,
    sortable: true,
    dataPath: 'householdKPI/AumTotal_NFS'
  },
  [HouseholdColumnIds.MonthlyAvgSweepBal]: {
    id: HouseholdColumnIds.MonthlyAvgSweepBal,
    name: 'Monthly Average Sweep Balance',
    dataPath: 'MonthlyAvgSweepBal',
    type: 'number',
    searchable: false,
    filterable: true,
    sortable: true
  },
  [HouseholdColumnIds.AdvisedOnly]: {
    id: HouseholdColumnIds.AdvisedOnly,
    name: 'Advised Only',
    dataPath: 'householdKPI/AdvisedOnly',
    type: 'number',
    searchable: false,
    filterable: true,
    sortable: true
  },
  [HouseholdColumnIds.LoanOutstandingOutsideNfs]: {
    id: HouseholdColumnIds.LoanOutstandingOutsideNfs,
    name: 'Loan Outstanding Outside NFS',
    dataPath: 'householdKPI/LoanOutstandingOutsideNfs',
    type: 'number',
    searchable: false,
    filterable: true,
    sortable: true
  },
  [HouseholdColumnIds.LoanOutstandingNfs]: {
    id: HouseholdColumnIds.LoanOutstandingNfs,
    name: 'Loan Outstanding NFS',
    dataPath: 'householdKPI/LoanOutstandingNfs',
    type: 'number',
    searchable: false,
    filterable: true,
    sortable: true
  },
  [HouseholdColumnIds.AdminReporting]: {
    id: HouseholdColumnIds.AdminReporting,
    name: 'Admin Reporting',
    dataPath: 'householdKPI/AdminReportingAssets',
    type: 'number',
    searchable: false,
    filterable: true,
    sortable: true
  },
  [HouseholdColumnIds.RockConnectAccBalance]: {
    id: HouseholdColumnIds.RockConnectAccBalance,
    name: 'Household Balances',
    dataPath: 'RockConnectAccBalance',
    type: 'number',
    searchable: false,
    filterable: true,
    sortable: true
  }
}
