import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ExpandedState } from '@tanstack/react-table'
import {
  IncomeReturnType,
  PerformanceData,
  PerformanceDateRange,
  PerformanceType
} from 'api/performance.types'
import { startOfMonth, startOfQuarter, startOfYear } from 'date-fns'
import { flow } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertToUtcMidnight } from 'shared'
import { isNotNullOrEmpty } from 'shared/guards'
import { AppState } from 'store'

export const getIsValidPerformance = (value?: number) =>
  Math.abs((value || 0) * 100) <= 99

const getStartPeriodDate = (dateRange: 'MTD' | 'QTD' | 'YTD') => {
  const startDate = (() => {
    switch (dateRange) {
      case 'MTD':
        return startOfMonth(new Date())
      case 'QTD':
        return startOfQuarter(new Date())
      case 'YTD':
        return startOfYear(new Date())
    }
  })()

  return startDate && convertToUtcMidnight(startDate)
}

const safeParseDate = (date?: string) => {
  if (!isNotNullOrEmpty(date)) {
    return undefined
  }

  const [year, month, day] = date
    .split(/\D/)
    .slice(0, 3)
    .map((x) => parseInt(x, 10))
  return new Date(year, month - 1, day)
}

export type PerfDateRange = 'MTD' | 'QTD' | 'YTD' | 'ITD'

export const getIsPartialPeriod = (
  dateRange: PerfDateRange,
  performanceItem: PerformanceData,
  performanceParent?: PerformanceData
) => {
  const isItd = dateRange === 'ITD'
  const parentItdcStartDate = flow(
    safeParseDate,
    convertToUtcMidnight
  )(performanceParent?.ITDCPerformance?.StartDate)
  const itemItdcStartDate = flow(
    safeParseDate,
    convertToUtcMidnight
  )(performanceItem?.ITDCPerformance?.StartDate)

  const periodStart = isItd
    ? parentItdcStartDate || itemItdcStartDate
    : getStartPeriodDate(dateRange)

  const isPartialPeriod =
    periodStart && itemItdcStartDate && periodStart < itemItdcStartDate

  return isPartialPeriod
}

export interface IActivityDetailUiState {
  searchText: string
  assetType: IncomeReturnType
  selectedDateRange: PerfDateRange
  selectedPerformanceType: string
  defaultExpanded?: ExpandedState
  isSingleAccount: boolean
}

const { actions, reducer } = createSlice({
  name: 'performanceDetailsUiState',
  initialState: {
    searchText: '',
    assetType: IncomeReturnType.NetTWR,
    selectedDateRange: PerformanceDateRange.monthToDate,
    selectedPerformanceType: PerformanceType.combined,
    defaultExpanded: undefined,
    isSingleAccount: false
  } as IActivityDetailUiState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    setAssetType: (state, action: PayloadAction<IncomeReturnType>) => {
      state.assetType = action.payload
    },
    setDateRange: (state, action: PayloadAction<PerfDateRange>) => {
      state.selectedDateRange = action.payload
    },
    setPerformanceType: (state, action: PayloadAction<string>) => {
      state.selectedPerformanceType = action.payload
    },
    setDefaultExpanded: (
      state,
      action: PayloadAction<ExpandedState | undefined>
    ) => ({
      ...state,
      defaultExpanded: action.payload
    }),
    setisSingleAccount: (state, action: PayloadAction<boolean>) => {
      state.isSingleAccount = action.payload
    }
  }
})

export { reducer as rdot360PerformanceReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.features.performance

const getSearchText = flow(rootSelector, (x) => x.searchText)
const getAssetType = flow(rootSelector, (x) => x.assetType)
const getDateRange = flow(rootSelector, (x) => x.selectedDateRange)
const getPerformanceType = flow(rootSelector, (x) => x.selectedPerformanceType)
const getDefaultExpanded = flow(rootSelector, (x) => x.defaultExpanded)
const getIsSingleAccount = flow(rootSelector, (x) => x.isSingleAccount)

export const usePerformanceState = () => {
  const dispatch = useDispatch()
  const searchText = useSelector(getSearchText)
  const assetType = useSelector(getAssetType)
  const dateRange = useSelector(getDateRange)
  const performanceType = useSelector(getPerformanceType)
  const defaultExpanded = useSelector(getDefaultExpanded)
  const isSingleAccount = useSelector(getIsSingleAccount)

  const setSearchText = useCallback(
    (search?: string) => {
      dispatch(actions.setSearchText(search || ''))
    },
    [dispatch]
  )

  const setAssetType = useCallback(
    (type: IncomeReturnType) => {
      dispatch(actions.setAssetType(type))
    },
    [dispatch]
  )

  const setDateRange = useCallback(
    (dateRange: PerfDateRange) => {
      dispatch(actions.setDateRange(dateRange))
    },
    [dispatch]
  )

  const setPerformanceType = useCallback(
    (performanceType: PerfDateRange) => {
      dispatch(actions.setPerformanceType(performanceType))
    },
    [dispatch]
  )

  const setDefaultExpanded = useCallback(
    (value?: ExpandedState) => {
      dispatch(actions.setDefaultExpanded(value))
    },
    [dispatch]
  )

  const setIsSingleAccount = useCallback(
    (value: boolean) => {
      dispatch(actions.setisSingleAccount(value))
    },
    [dispatch]
  )

  return {
    searchText,
    setSearchText,
    assetType,
    setAssetType,
    dateRange,
    performanceType,
    setPerformanceType,
    setDateRange,
    defaultExpanded,
    setDefaultExpanded,
    isSingleAccount,
    setIsSingleAccount
  }
}
