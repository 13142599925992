import { useMemo } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'
import {
  IPerformanceReportPayload,
  useGetPerformanceReportsQuery
} from '../../../store/datahub'
import { useRdot360AccountContext } from '../../../store/rdot360Context'
import { IPerformanceReport } from '../../../store/types'

const performanceReportsQuerySelect = [
  'accountKey',
  'accountNumber',
  'clientName',
  'fileLocation',
  'id',
  'fileId',
  'portfolioId',
  'portfolioName',
  'reportCategory',
  'reportDate',
  'reportId',
  'reportName'
]

const performanceReportsQueryPayload: IPerformanceReportPayload = {
  queryType: 'full',
  count: true,
  top: 1000,
  select: performanceReportsQuerySelect.join(','),
  orderby: 'id desc'
}

export const usePerformanceReportsData = (
  category?: string,
  dateRange?: string
) => {
  const { selectedAccountKeys } = useRdot360AccountContext()
  const selectedAccountKeysJoined = selectedAccountKeys?.join('|')
  const fromToDates = dateRange ? dateRange.split(',') : []
  const [startDate, endDate] = fromToDates
  const endDateWithISOTime = endDate && `${endDate}T23:59:59Z`
  const isDatesSet = startDate && endDateWithISOTime

  const filterItems = [
    `accountKey/any(accountKey: search.in(accountKey, '${selectedAccountKeysJoined}', '|'))`,
    isDatesSet
      ? `reportDate ge '${startDate}' and reportDate le '${endDateWithISOTime}'`
      : null,
    category && category !== 'ALL' ? `reportCategory eq '${category}'` : null
  ]
  const filter = filterItems.filter(isNotNullOrUndefined).join(' and ')

  const { data, error, isFetching, isUninitialized } =
    useGetPerformanceReportsQuery({
      ...performanceReportsQueryPayload,
      filter
    })

  const dataOnlyWhenSuccessful = useMemo(() => {
    const empty: IPerformanceReport[] = []
    return error ? empty : data || empty
  }, [data, error])

  return { data: dataOnlyWhenSuccessful, isFetching, isUninitialized }
}
