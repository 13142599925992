import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { addMonths } from 'date-fns'
import { AppState } from 'store'
import { IExportData } from '../export/worker'

export interface IRevenuePostSplitState {
  endDate: Date
  startDate: Date
  searchText: string
  exportData: IExportData
}

const now = new Date()
const first = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0)
const endDate = addMonths(first, -1)
const startDate = addMonths(endDate, -4)
const initialState: IRevenuePostSplitState = {
  endDate,
  startDate,
  searchText: '',
  exportData: { headers: [], items: [] }
}

export const {
  actions: revenuePostSplitActions,
  reducer: revenuePostSplitReducer
} = createSlice({
  name: '@modules/@advisory/@modules/@revenuePostSplit/',
  initialState,
  reducers: {
    updateSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    updateStartDate: (state, action: PayloadAction<Date>) => {
      state.startDate = action.payload
    },
    updateEndDate: (state, action: PayloadAction<Date>) => {
      state.endDate = action.payload
    },
    updateExportData: (state, action: PayloadAction<IExportData>) => {
      state.exportData = action.payload
    }
  }
})

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.revenuePostSplit

export const selectDateRange = createSelector(
  rootSelector,
  ({ startDate, endDate }) => ({ startDate, endDate })
)

export const selectExportData = createSelector(
  rootSelector,
  ({ exportData }) => exportData
)

export const selectSearchText = createSelector(
  rootSelector,
  ({ searchText }) => searchText
)
