import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

const { actions, reducer } = createSlice({
  name: 'createNoteCallout',
  initialState: {} as ICreateNoteCalloutState,
  reducers: {
    show: () => ({
      showCallout: true
    }),
    hide: () => ({
      showCallout: false,
      error: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    })
  }
})

export { reducer as createNoteCalloutReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.secondaryHeader.notes.createNoteCallout

const getShouldShowCallout = flow(rootSelector, (x) => x.showCallout)
const getPanelError = flow(rootSelector, (x) => x.error)

export interface ICreateNoteCalloutState {
  showCallout?: boolean
  error?: Error
}

export const useCreateNoteCallout = () => {
  const dispatch = useDispatch()

  const hide = useCallback(() => {
    dispatch(actions.hide())
  }, [dispatch])

  const show = useCallback(() => {
    dispatch(actions.show())
  }, [dispatch])

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const showCallout = useSelector(getShouldShowCallout)

  const error = useSelector(getPanelError)

  return {
    hide,
    show,
    setError,
    showCallout,
    error
  }
}
