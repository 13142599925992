import { combineReducers } from 'redux'
import { createNoteCalloutReducer } from './createNoteCallout'
import { notesCalloutReducer } from './notesCallout'
import { notesUiReducer } from './notesUi'

export const notes360Reducer = combineReducers({
  notesCallout: notesCalloutReducer,
  notesUi: notesUiReducer,
  createNoteCallout: createNoteCalloutReducer
})
