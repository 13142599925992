import { useMemo } from 'react'
import { IndeterminateProgressIndicator } from '../../../components/shared'
import { IncomeChart } from '../../../features/Income'
import { useGetProjectedIncomeChartQueryForSelectedAccounts } from '../../../store/rdot360Context'
import { IncomeDisclaimer } from './IncomeDisclaimer'
import { IncomeWidgetHeader } from './IncomeWidgetHeader'

export const IncomeWidgetChartProjected: React.FC = () => {
  const {
    data: incomeWidgetChartResponse,
    isFetching,
    isLoading,
    isError
  } = useGetProjectedIncomeChartQueryForSelectedAccounts(2)

  const data = useMemo(
    () =>
      isFetching || isError || !incomeWidgetChartResponse
        ? undefined
        : incomeWidgetChartResponse,
    [isFetching, isError, incomeWidgetChartResponse]
  )

  return (
    <>
      <IncomeWidgetHeader data={data} />
      <IncomeChart data={data?.incomeSubTotal} includeOptionPremiums={true} />
      <IncomeDisclaimer />
      <div
        css={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%'
        }}
      >
        {(isLoading || isFetching) && <IndeterminateProgressIndicator />}
      </div>
    </>
  )
}
