import { IActivity } from 'api/activity.types'
import { IODataListState } from 'features/OdataList/store/odataListWithFacetsStore'
import { combineReducers, Reducer } from 'redux'
import { IAsyncReducerState } from 'store/shared'
import {
  activitiesListExportReducer,
  activitiesListExportSagas
} from './export'
import {
  reducer as activitiesListReducer,
  sagas as activitiesListSagas
} from './store'

export interface IActivityListState {
  list: IODataListState<IActivity>
  export: IAsyncReducerState<unknown, unknown>
}

export const activityListReducer: Reducer<IActivityListState> = combineReducers(
  { list: activitiesListReducer, export: activitiesListExportReducer }
)

export const activityListSagas = [
  ...activitiesListSagas,
  ...activitiesListExportSagas
]
