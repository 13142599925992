import { keyBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useUserSettings } from 'shared/hooks/useUserSettings'
import { IEnvironmentAppConfigurations } from 'shared/services/environment/IEnvironmentConfiguration'
import { useGetApplicationsForUserQuery } from 'store/api/graph_v1'
import { getEnvironmentConfigApps } from 'store/system'
import { useRdotUser } from 'store/user/useRdotUser'
import { appConfig, IAppConfig } from './appConfig'

interface IMappedAppConfig extends IAppConfig {
  id: string
  appId?: string
  url?: string
}

const mapAppConfig = (
  configApps?: IEnvironmentAppConfigurations,
  appConfig?: IAppConfig[]
) => {
  if (!appConfig) {
    return []
  }

  return appConfig.map((val) => {
    if (val.configApp) {
      const appId = configApps && configApps[val.configApp]?.appId
      const url = configApps && configApps[val.configApp]?.url
      return { ...val, appId, url } as IMappedAppConfig
    }
    return val as IMappedAppConfig
  })
}

export const useAppConfig = () => {
  const { data: accessibleApps } = useGetApplicationsForUserQuery()
  const configApps = useSelector(getEnvironmentConfigApps)
  const [navItems, setNavItems] = useState<IMappedAppConfig[]>([])
  const { roles } = useRdotUser()
  const { appsOrder } = useUserSettings()

  const updatedAppConfig = useMemo(
    () => mapAppConfig(configApps, appConfig),
    [configApps]
  )

  useEffect(() => {
    const appLookup = keyBy(accessibleApps, ({ appId }) => appId || '')
    const appsOrderPref = appsOrder || []
    const filteredApps = updatedAppConfig
      ?.filter((x) => !x.appId || appLookup[x.appId])
      ?.filter(
        (x) => !x.roles?.length || x.roles.some((role) => roles?.includes(role))
      )
      .map((x) => ({
        ...x,
        imgSrc: x.imgSrc || (x.appId && appLookup[x.appId]?.info?.logoUrl),
        id: x.name || ''
      }))
    filteredApps.sort(
      (a, b) => appsOrderPref.indexOf(a.id) - appsOrderPref.indexOf(b.id)
    )

    setNavItems(filteredApps)
  }, [accessibleApps, appsOrder, roles, updatedAppConfig])

  return { navItems, setNavItems }
}
