import { ActionType, createAction } from 'typesafe-actions'
import { IPaymentVerificationForm, IWireInitiationForm } from '../types'
import { ISIValidateRequest, ISIValidateResponse } from './types'

export const SI_VALIDATE = `@modules/@transfers/@moneymovement/SI_VALIDATE`
export const SI_VALIDATE_SETSTATUS = `@modules/@transfers/@moneymovement/SI_VALIDATE_SETSTATUS`
export const SI_VALIDATE_SUCCESS = `@modules/@transfers/@moneymovement/SI_VALIDATE_SUCCESS`
export const SI_VALIDATE_FAILURE = `@modules/@transfers/@moneymovement/SI_VALIDATE_FAILURE`
export const SI_VALIDATE_CLEAR = `@modules/@transfers/@moneymovementSI_VALIDATE_CLEAR`

export const siValidatePaymentActions = {
  request: createAction(SI_VALIDATE)<{
    validateRequest: ISIValidateRequest
    wireData: IWireInitiationForm
    verificationData?: IPaymentVerificationForm
    paymentType?: string
  }>(),
  success: createAction(SI_VALIDATE_SUCCESS)<ISIValidateResponse>(),
  failure: createAction(SI_VALIDATE_FAILURE)<{
    Error: Error
    validateResponse: ISIValidateResponse | undefined
  }>(),
  clear: createAction(SI_VALIDATE_CLEAR)()
}

export type SIActions = ActionType<typeof siValidatePaymentActions>
