import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GroupingState, SortingState, Updater } from '@tanstack/react-table'
import { RdotAccountCategoryCode } from 'api/account.types'
import { isFunction, keys } from 'lodash'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNotNullOrEmpty } from 'shared/guards'
import { AppState } from 'store'
import {
  selectGroupingPreference,
  useLazyClientDashboardCommonPreferences
} from '../../../hooks/useClientDashboardPreferences'
import { getAccountKey } from '../../../store/rdot360Context'
import { selectPreferenceAccounts } from '../../../store/rdot360Context/useRdot360AccountContext'
import { selectBalanceLookupByKey } from '../../../store/rdot360Context/useRdot360BalancesContext'
import {
  IAccountSelectorAccount,
  rdotCategoryToClientDashboardCategoryMap
} from '../types'

export const accountSelectorColumnNames = {
  accountNumber: 'accountNumber',
  accountRegistration: 'accountRegistration',
  custodianType: 'custodianType',
  value: 'value',
  changeValue: 'changeValue',
  legalEntityId: 'legalEntityId',
  taxable: 'taxable',
  ausClass: 'ausClass',
  todayschange: 'todayschange',
  clientDashboardCategoryCode: 'clientDashboardCategoryCode',
  clientDashboardCategory: 'clientDashboardCategory',
  CustodianName: 'CustodianName',
  customAccountGroup: 'cag'
}

export type AccountSelectorColumnNames = keyof typeof accountSelectorColumnNames

export interface IAccountSelectorFeatureState {
  sorting: SortingState
  selectedIds: string[]
  searchText: string
}

const initialState = {
  sorting: [
    { id: accountSelectorColumnNames.clientDashboardCategoryCode, desc: false },
    { id: accountSelectorColumnNames.value, desc: true }
  ],
  selectedIds: [],
  includeClosedAccounts: false,
  searchText: ''
} as IAccountSelectorFeatureState

const accountSelectorFeatureSlice = createSlice({
  name: '@modules/@rdot360/@features/@accountSelector',
  initialState,
  reducers: {
    setSorting: (state, action: PayloadAction<SortingState | undefined>) => ({
      ...state,
      sorting: action.payload ?? []
    }),
    setSelection: (state, action: PayloadAction<string[] | undefined>) => ({
      ...state,
      selectedIds: action.payload ?? []
    }),
    resetTableState: () => ({
      ...initialState
    }),
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    }
  }
})

export const { reducer: accountSelectorReducer } = accountSelectorFeatureSlice
const { actions } = accountSelectorFeatureSlice

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.features.accountSelector
    .accountSelector
const getSearchText = flow(rootSelector, (x) => x.searchText)
const selectSelectedIds = createSelector([rootSelector], (x) => x.selectedIds)
const selectSelectedIdsLookup = createSelector([selectSelectedIds], (ids) =>
  ids?.reduce((a, x) => ({ ...a, [x]: true }), {} as Record<string, boolean>)
)
const selectFilteredAccounts = createSelector(
  [selectPreferenceAccounts, getSearchText],
  (accounts, searchText) => {
    const searchString = (searchText ?? '').toLowerCase()
    return searchString
      ? accounts?.filter(
          ({
            preferredNickname,
            CustodyAccount,
            registrationtype,
            registrationDesc
          }) =>
            [
              preferredNickname,
              CustodyAccount,
              registrationtype,
              registrationDesc
            ]
              .filter(isNotNullOrEmpty)
              .some((value) => value?.toLowerCase().includes(searchString))
        )
      : accounts
  }
)

export const selectGroupingPreferenceWithDefault = createSelector(
  [selectGroupingPreference],
  (grouping) => grouping ?? [accountSelectorColumnNames.clientDashboardCategory]
)

const empty: IAccountSelectorAccount[] = []
const selectAccountswithBalances = createSelector(
  [
    selectFilteredAccounts,
    selectBalanceLookupByKey,
    selectGroupingPreferenceWithDefault
  ],
  (filteredAccounts, balanceLookupByKey, grouping) => {
    const [firstGroup] = grouping || []
    const isGroupedByDefaultView =
      firstGroup === accountSelectorColumnNames.clientDashboardCategory

    const placeholders = isGroupedByDefaultView
      ? keys(rdotCategoryToClientDashboardCategoryMap).map(
          (x): IAccountSelectorAccount => ({
            id: x,
            clientDashboardCategoryCode: x as RdotAccountCategoryCode,
            placeholder: true
          })
        )
      : [
          {
            id: 'placeholder',
            placeholder: true
          }
        ]

    const selectorAccounts =
      filteredAccounts?.map(
        (x): IAccountSelectorAccount => ({
          id: x.id ?? '',
          clientDashboardCategoryCode: x.RDOTAccountCategoryCode || '04',
          account: x,
          balance: balanceLookupByKey[getAccountKey(x)]
        })
      ) ?? empty
    return [
      ...selectorAccounts,
      ...(selectorAccounts?.length && !isGroupedByDefaultView
        ? empty
        : placeholders)
    ]
  }
)

export const useAccountSelectorTableStore = () => {
  const { setPreferences } = useLazyClientDashboardCommonPreferences()
  const dispatch = useDispatch()
  const { sorting, selectedIds } = useSelector(rootSelector)

  const grouping = useSelector(selectGroupingPreferenceWithDefault)

  const setSorting = useCallback(
    (updater: Updater<SortingState>) => {
      const value = isFunction(updater) ? updater(sorting ?? []) : updater
      dispatch(actions.setSorting(value))
    },
    [dispatch, sorting]
  )

  const setGrouping = useCallback(
    (updater: Updater<GroupingState>) => {
      const value = isFunction(updater) ? updater(grouping ?? []) : updater
      setPreferences({ grouping: value })
    },
    [grouping, setPreferences]
  )

  const setSelectedIds = useCallback(
    (newSelection?: string[]) => {
      dispatch(actions.setSelection(newSelection))
    },
    [dispatch]
  )

  const resetTableState = useCallback(() => {
    dispatch(actions.resetTableState())
  }, [dispatch])

  const setSearchText = useCallback(
    (search?: string) => {
      dispatch(actions.setSearchText(search ?? ''))
    },
    [dispatch]
  )

  const selectedIdsLookup = useSelector(selectSelectedIdsLookup)
  const searchText = useSelector(getSearchText)
  const filteredAccounts = useSelector(selectFilteredAccounts)
  const accountsWithBalances = useSelector(selectAccountswithBalances)

  return {
    sorting,
    setSorting,
    grouping,
    setGrouping,
    selectedIds,
    setSelectedIds,
    selectedIdsLookup,
    resetTableState,
    setSearchText,
    searchText,
    filteredAccounts,
    accountsWithBalances
  }
}
