import { css } from '@emotion/react'
import { MessageBar, MessageBarType } from '@fluentui/react'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { orderBy, sumBy } from 'lodash'
import { useMemo } from 'react'
import { IndeterminateProgressIndicator } from '../../../components/shared'
import { HeaderContainer } from '../../../components/shared/DetailTables/HeaderContainer'
import { useGetRevenueTableData } from '../../../features/Revenue/useRevenueContext'
import {
  PercentCell,
  USDCell,
  WidgetTable
} from '../../../features/WidgetTable/WidgetTable'
import { categoryLookup } from './RevenueSummaryChart'

const classes = {
  progressIndicatorContainer: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  })
}
function getColumns(totalSum: number) {
  const columnHelper = createColumnHelper<
    | {
        assetType: string
        allocatedAmount: number
        percentage: number
      }
    | undefined
  >()
  const columns = [
    columnHelper.accessor((asset) => asset?.assetType, {
      id: 'assetType',
      cell: (asset) => <RevenueTextCell value={asset.getValue()} />,
      header: (props) => (
        <HeaderContainer
          title="Category"
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 100,
      footer: () => <div css={{ fontWeight: 'bold' }}>Total</div>
    }),
    columnHelper.accessor((asset) => asset, {
      id: 'amount',
      cell: (asset) => <RevenueUSDCell value={asset.getValue()} />,
      header: (props) => (
        <HeaderContainer
          title="Total"
          isSorted={props.column.getIsSorted()}
          rightAlign={true}
        />
      ),
      footer: () => (
        <div css={{ fontWeight: 'bold' }}>
          <USDCell value={totalSum} defaultValue="--" />
        </div>
      ),
      size: 65
    }),
    columnHelper.accessor((asset) => asset?.percentage, {
      id: 'revenuePercentage',
      cell: (props) => <PercentCell value={props.getValue()} />,
      header: (props) => (
        <HeaderContainer
          title="Percentage"
          isSorted={props.column.getIsSorted()}
          rightAlign={true}
        />
      ),
      size: 95
    })
  ]
  return columns
}

export const RevenueTable: React.FC<{
  startDate: Date
  endDate: Date
}> = ({ startDate, endDate }) => {
  const { data, isFetching, isUninitialized } = useGetRevenueTableData(
    format(startDate, 'yyyy-MM-dd'),
    format(endDate, 'yyyy-MM-dd')
  )

  const totalSum = useMemo(() => {
    return sumBy(data, (x) => x.allocatedAmount || 0)
  }, [data])
  const formattedData = useMemo(() => {
    let formattedData = data?.map((x) => {
      const percentage = (x.allocatedAmount / totalSum) * 100
      return {
        assetType: x.assetType,
        allocatedAmount: x.allocatedAmount,
        percentage: isNaN(percentage) || !isFinite(percentage) ? 0 : percentage
      }
    })
    formattedData = orderBy(formattedData, (x) => x.allocatedAmount, 'desc')
    return formattedData
  }, [data, totalSum])
  const columns = useMemo(() => {
    return getColumns(totalSum)
  }, [totalSum])

  return (
    <>
      {formattedData?.length === 0 && !isUninitialized && !isFetching ? (
        <MessageBar messageBarType={MessageBarType.info}>
          No data available
        </MessageBar>
      ) : (
        <WidgetTable
          data={formattedData}
          sort={[{ id: 'amount', desc: true }] as any}
          isFooter={true}
          columns={columns}
          isLoading={isFetching}
          isUninitialized={isUninitialized}
        />
      )}
      {isFetching && (
        <div css={classes.progressIndicatorContainer}>
          <IndeterminateProgressIndicator />
        </div>
      )}
    </>
  )
}
export const RevenueTextCell: React.FC<{ value?: string }> = ({ value }) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <div
        css={{
          width: '10px',
          height: '10px',
          minWidth: '10px',
          backgroundColor: `${categoryLookup[value ?? 'Other']}`,
          marginTop: '2px'
        }}
      />
      <div
        css={{
          textAlign: 'left',
          fontWeight: 'normal',
          paddingLeft: '15px',
          textOverflow: 'ellipsis'
        }}
      >
        {value}
      </div>
    </div>
  )
}

export const RevenueUSDCell: React.FC<{
  value?: {
    assetType: string
    allocatedAmount?: number
    percentage?: number
  }
}> = ({ value }) => {
  const totalAmount = value?.allocatedAmount?.toLocaleString('en-US', {
    style: 'currency',
    currencySign: 'standard',
    maximumFractionDigits: 0,
    currency: 'USD'
  })
  return (
    <div
      css={{
        textAlign: 'right',
        fontWeight: 'normal'
      }}
    >
      {totalAmount}
    </div>
  )
}
