import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  Row,
  VisibilityState
} from '@tanstack/react-table'
import { useWindowVirtualizer } from '@tanstack/react-virtual'
import { tokenizeQuery } from 'api/search'
import { sum, toLower } from 'lodash'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { FormattedNumber } from 'react-intl'
import { USD } from 'shared/components/Formatting'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { isNotNullOrEmpty } from 'shared/guards'
import { useDebounce } from 'shared/hooks/useDebounce'
import { IndeterminateProgressIndicator } from '../../../components/shared'
import { detailTable } from '../../../components/shared/DataTable/DetailTable'
import { SnackBar } from '../../../components/shared/Snackbar'
import { useActivityDetails } from '../../../hooks/useActivityDetails'
import { constants } from '../../../shared/theme'
import { useRdot360AccountContext } from '../../../store/rdot360Context/useRdot360AccountContext'
import { IActivity } from '../../../store/types'
import { useActivityDetailUiState } from '../activityDetailsUiState'
import { activityDetailColumns } from '../shared'
import { constructColumns, formatValueUSD } from './ActivityDetailsTableColumns'

const globalFilterFn = (
  row: Row<IActivity>,
  columnId: string,
  filterValue: string
) => {
  if (!isNotNullOrEmpty(filterValue)) {
    return true
  }

  const value = row.getValue<number | string | undefined>(columnId)
  if (columnId === activityDetailColumns.amount) {
    const formattedValue = formatValueUSD(
      row.getValue<number | undefined>(columnId)
    )
    return (
      value?.toString().includes(filterValue) ||
      formattedValue?.includes(filterValue) ||
      false
    )
  }

  const tokens = tokenizeQuery(filterValue).map(toLower)
  const lowerCaseValue = value?.toString()?.toLowerCase()

  return tokens.every((token) => lowerCaseValue?.includes(token))
}

const ActivityDetailsTable = () => {
  const { activityData, isFetching, error, isUninitialized } =
    useActivityDetails()
  const { searchText, setSearchText, isDownloading } =
    useActivityDetailUiState()
  const debouncedSearchText = useDebounce(searchText, 100)
  const noData = useMemo(
    () => !activityData?.activities?.length,
    [activityData?.activities?.length]
  )

  const columnVisibility: VisibilityState = {
    [activityDetailColumns.nickName]: false,
    [activityDetailColumns.registrationType]: false,
    [activityDetailColumns.symbol]: false,
    [activityDetailColumns.cusip]: false,
    [activityDetailColumns.settlementDate]: false,
    [activityDetailColumns.entryDate]: false
  }

  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()
  const columns = useMemo(
    () => constructColumns(debouncedSearchText, accountLookupByAccountIdOrKey),
    [accountLookupByAccountIdOrKey, debouncedSearchText]
  )
  const activity = useMemo(
    () => activityData?.activities || [],
    [activityData?.activities]
  )
  const [sorting, setSorting] = useState<SortingState>([
    { id: activityDetailColumns.activityDate, desc: true }
  ])
  const table = useReactTable({
    data: activity,
    columns,
    state: {
      sorting,
      globalFilter: debouncedSearchText,
      columnVisibility
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    onGlobalFilterChange: setSearchText,
    globalFilterFn,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  const tableContainerRef = useRef<HTMLDivElement | null>(null)
  const tableContainerOffsetRef = useRef(0)

  useLayoutEffect(() => {
    tableContainerOffsetRef.current = tableContainerRef.current?.offsetTop || 0
  }, [])

  const { rows } = table.getRowModel()

  const activitiesCount = useMemo(() => rows.length, [rows.length])
  const totalAmount = useMemo(
    () => sum(rows.map((row) => row.getValue(activityDetailColumns.amount))),
    [rows]
  )

  const virtualizer = useWindowVirtualizer({
    count: rows.length,
    estimateSize: () => 55,
    scrollMargin: tableContainerOffsetRef.current
  })
  const items = virtualizer.getVirtualItems()

  return (
    <HorizontalScrollContainer>
      <>
        <div
          css={{
            padding: '5px',
            paddingBottom: '10px'
          }}
        >
          Total Activities:{' '}
          <FormattedNumber value={activitiesCount} maximumFractionDigits={0} />{' '}
          | Net Amount: <USD value={totalAmount} currencySign="standard" />
        </div>

        <div css={{ height: '3px' }}>
          {(isFetching || isDownloading) && <IndeterminateProgressIndicator />}
        </div>
        <table
          css={[
            detailTable.detailTable,
            detailTable.leftRightPadding,
            {
              minWidth: '975px',
              position: 'sticky',
              top: constants.headerOffset,
              zIndex: 1
            }
          ]}
        >
          <thead>
            <tr>
              {table.getFlatHeaders().map((header) => {
                return (
                  <th
                    key={header.id}
                    style={{
                      width: header.getSize(),
                      height: 0,
                      padding: 0,
                      margin: 0
                    }}
                  />
                )
              })}
            </tr>
            <tr>
              {table.getFlatHeaders().map((header) => {
                return (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          onClick: header.column.getToggleSortingHandler()
                        }}
                        css={{
                          cursor: 'pointer'
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          </thead>
        </table>
        {(error || noData) && !isUninitialized && !isFetching ? (
          <div css={{ marginTop: '5px', width: '100%' }}>
            <SnackBar
              type={error ? 'Failure' : 'Info'}
              message={
                error
                  ? (error as Error)?.message || 'An unknown error occurred'
                  : 'No activity for selected filter choices'
              }
            />
          </div>
        ) : null}
        <div
          ref={tableContainerRef}
          style={{
            position: 'relative',
            height: virtualizer.getTotalSize(),
            width: '100%'
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              transform: `translateY(${
                items?.[0]?.start - virtualizer.options.scrollMargin
              }px)`
            }}
          >
            <table
              css={[
                detailTable.detailTable,
                detailTable.leftRightPadding,
                {
                  minWidth: '975px'
                }
              ]}
            >
              <thead>
                <tr>
                  {table.getFlatHeaders()?.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: header.getSize(),
                          height: 0,
                          padding: 0,
                          margin: 0
                        }}
                      />
                    )
                  })}
                </tr>
              </thead>

              <tbody>
                {items.map((virtualRow) => {
                  const row = rows[virtualRow.index] as Row<IActivity>
                  return (
                    <tr
                      key={virtualRow.key}
                      data-index={virtualRow.index}
                      ref={virtualizer.measureElement}
                      css={{ height: '55px' }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </HorizontalScrollContainer>
  )
}

export default ActivityDetailsTable
