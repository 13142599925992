import { css } from '@emotion/react'
import {
  ExpandedState,
  GroupingState,
  VisibilityState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { map, sum } from 'lodash'
import { useClientDashboardTilePreferences } from 'modules/Advisory/modules/Rdot360/hooks/useClientDashboardPreferences'
import { useBalanceSummaryDetailsUiState } from 'modules/Advisory/modules/Rdot360/modules/Balances/balanceSummaryDetailsUIState'
import { PrintHeaderGlobal } from 'modules/Advisory/modules/Rdot360/shared/PrintHeaderGlobal'
import { ClientDashboardTiles } from 'modules/Advisory/modules/Rdot360/shared/types'
import { useMemo, useState } from 'react'
import { getBalancesDetailTableColumns } from '../../BalanceSummaryTableColumns'
import {
  balancesDetailTableGroupingColumnNames,
  balancesDetailTableColumnNames as colNames
} from '../../shared'
import { useBalancesData } from '../../useBalancesData'
import { PrintDisclaimer } from './PrintDisclaimer'
import { PrintHeaderClient } from './PrintHeaderClient'
import { PrintTable } from './PrintTable'

const getClasses = () => ({
  body: css({
    fontFamily: 'sans-serif',
    '@media screen': {
      maxWidth: '1600px',
      margin: '2rem auto',
      '& .header-global': {
        maxWidth: '1600px'
      }
    },
    '@media print': {
      zoom: '60%',
      margin: 0,
      '& .print-table': {
        breakAfter: 'page'
      },
      '@page': {
        size: 'letter landscape'
      }
    },
    '& h1': {
      fontSize: '26px',
      margin: 0
    },
    '& mark': {
      backgroundColor: 'transparent',
      color: '#000',
      fontWeight: '400 !important'
    },
    '& .header-global': {
      borderBottom: '2px solid #676767',
      paddingBottom: '20px',
      width: '100%',
      backgroundColor: '#FFF'
    },
    '& .header-client': {
      borderBottom: '1px solid #CDCDCD',
      marginBottom: '-30px'
    },
    '& .top-margin': {
      marginTop: '70px'
    },
    '& .top-margin-table': {
      marginTop: '47px'
    },
    '& .header-columns': {
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& .header-column > div': {
      padding: '5px 0'
    },
    '& .header-left': {
      textAlign: 'left',
      width: '70%'
    },
    '& .header-right': {
      textAlign: 'right',
      width: '30%'
    },
    '& .header-table': {
      backgroundColor: '#DFE8EE'
    },
    '& .header-column-half': {
      width: '50%'
    },
    '& .table-spacer > th': {
      padding: '5px',
      borderColor: '#FFF'
    },
    '& .footer-table': {
      backgroundColor: '#EBEBEB'
    },
    '& .footer-table td': {
      padding: '20px 15px'
    },
    '& .print-table': {
      borderCollapse: 'collapse',
      width: '100%'
    },
    '& .disclaimer': {
      paddingTop: '20px',
      fontSize: '9pt'
    },
    '& tr': {
      borderBottom: '1px solid #F0F0F0',
      pageBreakInside: 'avoid'
    },
    '& td': {
      padding: '15px',
      textAlign: 'right'
    },
    '& th': {
      padding: '15px',
      textAlign: 'right'
    },
    '& col:nth-child(1), & th:nth-child(1), & td:nth-child(1)': {
      width: '170px',
      whiteSpace: 'nowrap',
      textAlign: 'left'
    },
    'td:nth-child(1)': { display: 'block' },
    '& col:nth-child(2), & th:nth-child(2), & td:nth-child(2)': {
      width: '15%'
    },
    '& col:nth-child(3), & th:nth-child(3), & td:nth-child(3)': {
      width: '15%'
    },
    '& col:nth-child(4), & th:nth-child(4), & td:nth-child(4)': {
      width: '15%'
    },
    '& col:nth-child(5), & th:nth-child(5), & td:nth-child(5)': {
      width: '15%'
    },
    '& col:nth-child(6), & th:nth-child(6), & td:nth-child(6)': {
      width: '15%'
    },
    '& col:nth-child(7), & th:nth-child(7), & td:nth-child(7)': {
      width: '15%'
    },
    '& col:nth-child(8), & th:nth-child(8), & td:nth-child(8)': {
      width: '15%'
    },
    '& col:nth-child(9), & th:nth-child(9), & td:nth-child(9)': {
      width: '15%',
      paddingRight: '25px'
    },
    '& td svg': {
      display: 'none'
    },
    '& td:first-child, & th:first-child': {
      textAlign: 'left',
      paddingLeft: '25px'
    },
    '& td:first-child > div': {
      display: 'flex',
      flexWrap: 'wrap',
      whiteSpace: 'nowrap',
      fontWeight: 500,
      fontSize: '16px'
    },
    '& td:first-child > div > span': {
      whiteSpace: 'nowrap',
      paddingRight: '5px'
    },
    '& td:first-child > div > div': {
      whiteSpace: 'nowrap',
      paddingLeft: '3px'
    },
    '& td:first-child sup': {
      fontWeight: 'bold',
      fontSize: '9px',
      marginLeft: '2px'
    },
    '& .footer-note': {
      width: '100% !important'
    },
    '& .logo-row': {
      padding: '0 0 10px 0 !important',
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: -1,
      borderBottom: '1px solid #CDCDCD'
    },
    '& .logo-col': { padding: '0 !important' },
    label: {
      color: '#676767'
    },
    '& .balances-table-row': { backgroundColor: '#FFF' },
    '& .balances-table-group-row': { backgroundColor: '#EFF3F6' },
    '& .masked': { display: 'block !important' }
  })
})

const visibility: VisibilityState = {
  [colNames.clientDashboardCategory]: false,
  [colNames.clientDashboardCategoryCode]: false,
  [colNames.custodianName]: false,
  [colNames.taxable]: false,
  [colNames.legalEntityId]: false,
  [colNames.accountRegistration]: false,
  [colNames.ausClass]: false,
  [colNames.cag]: false,
  [colNames.availableToInvest]: false,
  [colNames.availableToWithdraw]: false
}

interface IPrintHTMLProps {
  masked: boolean
  hideHousehold: boolean
}

export const PrintHTML: React.FC<IPrintHTMLProps> = ({
  masked,
  hideHousehold
}) => {
  const classes = useMemo(() => getClasses(), [])
  const tileName = ClientDashboardTiles.balanceTile
  const { tilePreferences } = useClientDashboardTilePreferences(tileName)
  const viewType = useMemo(
    () =>
      tilePreferences?.viewByKey ||
      balancesDetailTableGroupingColumnNames.clientDashboardCategory,
    [tilePreferences]
  )
  const groupState = useMemo(() => [viewType] as GroupingState, [viewType])
  const skipCag = viewType !== colNames.cag
  const { dataWithCustodian: data, cagData } = useBalancesData(skipCag)
  const columns = useMemo(getBalancesDetailTableColumns, [])
  const { searchText, sorting } = useBalanceSummaryDetailsUiState()
  const [expanded] = useState<ExpandedState>({})
  const table = useReactTable({
    data: skipCag ? data : cagData,
    columns,
    state: {
      sorting,
      grouping: groupState,
      expanded,
      columnVisibility: visibility,
      globalFilter: searchText
    },
    autoResetExpanded: false,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  const getTotal = (id: string) => {
    const rows = table.getRowModel().rows
    const values = map(rows, (row) => row.getValue(id) as number)
    const total = sum(values)
    return total
  }
  const total = getTotal(colNames.netBalance)
  const daysChange = getTotal(colNames.todaysChange)

  return (
    <html>
      <head>
        <meta charSet="UTF-8" />
        <title>&nbsp;</title>
        <script>
          {`
            window.onload = function() {
              setTimeout(() => {
                window.print();
              }, 100)
            }
          `}
        </script>
      </head>
      <body css={classes.body}>
        <header className="header-global">
          <PrintHeaderGlobal name="Balances" />
        </header>
        <header className="header-client header-columns">
          <PrintHeaderClient
            skipCag={skipCag}
            masked={masked}
            hideHousehold={hideHousehold}
            total={total}
            daysChange={daysChange}
            searchText={searchText}
          />
        </header>
        <PrintTable table={table} skipCag={skipCag} masked={masked} />
        <PrintDisclaimer />
      </body>
    </html>
  )
}
