import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export interface IActivityDetailUiState {
  searchText: string
}

const { actions, reducer } = createSlice({
  name: 'InvestmentsDetailsUIState',
  initialState: {
    searchText: ''
  } as IActivityDetailUiState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    }
  }
})

export { reducer as InvestmentsDetailsUIStateReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.investments.features
    .investmentsDetails

const getSearchText = flow(rootSelector, (x) => x.searchText)

export const useInvestmentsDetailsUIState = () => {
  const dispatch = useDispatch()
  const searchText = useSelector(getSearchText)

  const setSearchText = useCallback(
    (search?: string) => {
      dispatch(actions.setSearchText(search || ''))
    },
    [dispatch]
  )

  return {
    searchText,
    setSearchText
  }
}
