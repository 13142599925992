import { css, useTheme } from '@emotion/react'
import {
  ContextualMenu,
  Dialog,
  DialogFooter,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  MessageBar,
  MessageBarType,
  ProgressIndicator
} from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { INfsProfile } from 'api/datahub'
import { format } from 'date-fns'
import { difference } from 'lodash'
import {
  Icon,
  IconType
} from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { maskAccountNumber } from 'modules/Advisory/modules/Rdot360/shared/utilities'
import { useShareReportWithUserV2Mutation } from 'modules/Advisory/modules/Rdot360/store/documentsApi'
import { IPerformanceReport } from 'modules/Advisory/modules/Rdot360/store/types'
import { useEffect, useMemo, useState, FC, FormEvent, useCallback } from 'react'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/guards'
import { useFetchNfsProfilesQuery } from 'store/api/datahub'
import { ButtonFooter } from './ButtonFooter'
import { getRelatedPartyDropdownOptions } from './getNfsProfileDropdownOptions'
import { getSharedReportsResponsesGroupedByType } from './getSharedReportsResponsesGroupedByType'
import { Message } from './Message'
// import { getMockResponses } from './testData'
import { SharedReportResponseStates } from './types'

interface IShareReportDialogProps {
  showShareReportDialog: boolean
  setShowShareReportDialog: React.Dispatch<React.SetStateAction<boolean>>
  report: IPerformanceReport
}

interface IPartialVisibility {
  loginId: string
  missingAccounts: string[]
}

const classes = {
  reportList: css({
    listStyleType: 'none',
    padding: 0,
    marginTop: 0,
    li: {
      fontSize: 12,
      padding: '2px 0'
    }
  })
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { borderRadius: 5, width: 340 },
  dropdownItemsWrapper: { maxHeight: 300, overflowY: 'auto' },
  dropdownItem: { minHeight: 48 },
  dropdownItemSelected: { minHeight: 48 }
}

const maskAccounts = (accounts: string[]) => {
  return accounts.map((account) => maskAccountNumber(account))
}

const onRenderOption = (option?: IDropdownOption): JSX.Element => {
  const { iconType, iconColor } = option?.data || {}
  const { itemType, key, text } = option || {}

  return (
    <>
      {iconType != null && (
        <span style={{ margin: '0 7px 0 6px' }}>
          <Icon type={iconType} width={16} height={16} color={iconColor} />
        </span>
      )}
      {itemType != null ? (
        <span>{text}</span>
      ) : (
        <div>
          <div>{(text && text.trim()) || '--'}</div>
          {key != null && <div css={{ color: 'gray' }}>{key}</div>}
        </div>
      )}
    </>
  )
}

const getNfsProfilesPayload = (reportAccountList: string) => ({
  count: true,
  select: [
    'id',
    'fullname',
    'loginid',
    'role',
    'accounts/accountKey',
    'accounts/accountNumber'
  ],
  filters: [
    `accounts/any(x: x/accountKey in (${reportAccountList}))`,
    'loginid ne null'
  ]
})

export const ShareReportDialog: FC<IShareReportDialogProps> = ({
  showShareReportDialog,
  setShowShareReportDialog,
  report
}) => {
  const [step, setStep] = useState(1)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [selectedLoginIds, setSelectedLoginIds] = useState<string[]>([])
  const [selectedNames, setSelectedNames] = useState<string[]>([])
  const [promiseAllResolved, setPromiseAllResolved] = useState(false)
  const [shareReportWithUser] = useShareReportWithUserV2Mutation()
  const theme = useTheme()
  const [
    clientsWithPartialAccountVisibility,
    setClientsWithPartialAccountVisibility
  ] = useState<IPartialVisibility[]>([])
  const [clientStatus, setClientStatus] = useState<SharedReportResponseStates>({
    success: [],
    failure: []
  } as SharedReportResponseStates)
  const closeModal = () => {
    setShowShareReportDialog(false)
  }

  /* const { selectedHouseholdId } = useRdot360Context()
  const { data, error, isFetching, isUninitialized } =
    useGetRelatedPartiesByHouseholdIdQuery(selectedHouseholdId || '') */

  // DEBUG: Test code for setting client success, fail or partially visible
  // Used inside getClientsWithPartialAccountVisibility()
  // const clientAccountVisibility = getClientAccountVisibility(
  //   data,
  //   report.accountKey
  // )

  const reportAccountList = useMemo(
    () => (report.accountKey ?? []).map((account) => `'${account}'`).join(', '),
    [report.accountKey]
  )

  const nfsProfilesPayload = reportAccountList.length
    ? getNfsProfilesPayload(reportAccountList)
    : skipToken

  const {
    data: NfsProfiles,
    error,
    isFetching,
    isUninitialized
  } = useFetchNfsProfilesQuery(nfsProfilesPayload)

  const dataOnlyWhenSuccessful = useMemo(() => {
    const empty: INfsProfile[] = []
    return error ? empty : NfsProfiles || empty
  }, [NfsProfiles, error])

  const showNoData = useMemo(
    () =>
      !reportAccountList.length ||
      (!isUninitialized && !dataOnlyWhenSuccessful?.length && !isFetching),
    [
      dataOnlyWhenSuccessful?.length,
      isFetching,
      isUninitialized,
      reportAccountList.length
    ]
  )

  useEffect(() => {
    setIsSubmitDisabled(!selectedLoginIds.length)
  }, [selectedLoginIds])

  const getClientsWithPartialAccountVisibility = useCallback(() => {
    const partialVisibility: IPartialVisibility[] = selectedLoginIds
      .map((loginId) => {
        const client = dataOnlyWhenSuccessful.find((x) => x.loginid === loginId)
        const accounts: string[] =
          client?.accounts
            ?.map((account) => account.accountKey)
            .filter(isNotNullOrEmpty) || []
        const missing = difference(report.accountKey, accounts)
        if (missing.length) {
          const masked = maskAccounts(missing)
          return {
            loginId,
            missingAccounts: masked
          }
        }
        return
      })
      .filter(isNotNullOrUndefined)
    return partialVisibility
  }, [dataOnlyWhenSuccessful, report.accountKey, selectedLoginIds])

  const ReportDetails = useCallback(() => {
    const { reportName, portfolioId, portfolioName, reportId, reportCategory } =
      report
    const reportDetails = [
      { label: 'Report Name', value: reportName },
      { label: 'Portfolio ID', value: portfolioId },
      { label: 'Portfolio Name', value: portfolioName },
      { label: 'Report ID', value: reportId },
      { label: 'Report Category', value: reportCategory }
    ]
    return (
      <ul css={classes.reportList}>
        {reportDetails.map((item, index) => (
          <li key={index}>
            <strong>{item.label}:</strong> {item.value}
          </li>
        ))}
      </ul>
    )
  }, [report])

  const shareReport = async () => {
    setIsSubmitDisabled(true)
    const promises = selectedLoginIds.map((loginId) => {
      const reportDate = new Date(report.reportDate || '')
      const asOfDate = format(reportDate, 'yyyy-MM-dd')
      const documentName = `${report.fileId}_${report.reportName}`
      const documentPath = `${report.fileLocation}/${documentName}`
      const auditMetadata = JSON.stringify({
        srcsystemid: report.id
        // autoshared: false
      })
      const payload = {
        shareWithUser: loginId,
        documentPath,
        documentName,
        documentType: 'PDF',
        accountNumbers: report.accountKey,
        type: 'PerformanceReport',
        asOfDate,
        validateAccount: false,
        auditMetadata,
        sourceSystemId: report.id
      }
      return shareReportWithUser(payload)
    })
    setStep(3)

    // DEBUG: Mocking responses with random success, failure
    // **************************************
    // const mockResponses = getMockResponses(selectedLoginIds.length)
    // console.log('mockResponses', mockResponses.length) // DEBUG: to prevent Lint error when using real response
    // **************************************

    try {
      const responses = await Promise.all(promises)
      const responsesMappedAndGrouped = getSharedReportsResponsesGroupedByType(
        //mockResponses, // DEBUG: placeholder for responses array from Promise.all(promises)
        responses,
        selectedNames,
        selectedLoginIds
      )
      setPromiseAllResolved(true)
      setClientStatus(responsesMappedAndGrouped)
    } catch (error) {
      console.error('Try/Catch Error sharing report:', error)
      // DEBUG: Should we do something here?
    } finally {
      setIsSubmitDisabled(false)
    }
  }

  const checkForPartialVisibility = () => {
    const partialVisibility = getClientsWithPartialAccountVisibility()
    if (partialVisibility.length > 0) {
      setClientsWithPartialAccountVisibility(partialVisibility)
      setStep(2)
    } else {
      shareReport()
    }
  }

  const onChange = (
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ): void => {
    if (option) {
      // set list of loginIds selected
      setSelectedLoginIds(
        option.selected
          ? [...selectedLoginIds, option.key as string]
          : selectedLoginIds.filter((key) => key !== option.key)
      )
      // set list of Names selected
      setSelectedNames(
        option.selected
          ? [...selectedNames, option.text as string]
          : selectedNames.filter((key) => key !== option.key)
      )
    }
  }

  const getMenuItem = (
    id?: string,
    name?: string,
    iconType?: IconType,
    iconColor: string = theme.colors.tertiaryBlue1
  ): IDropdownOption => ({
    key: id || '',
    text: name || '',
    data: {
      iconType,
      iconColor
    }
  })

  const options = getRelatedPartyDropdownOptions(
    dataOnlyWhenSuccessful,
    getMenuItem
  )

  const optionsPlaceholder = useMemo(() => {
    if (isFetching) {
      return 'Loading Clients...'
    }
    if (showNoData) {
      return 'No Clients Available'
    }
    return 'Select Client(s)'
  }, [isFetching, showNoData])

  return (
    <Dialog
      hidden={!showShareReportDialog}
      onDismiss={closeModal}
      minWidth={650}
      modalProps={{
        isBlocking: true,
        dragOptions: {
          moveMenuItemText: 'Move',
          closeMenuItemText: 'Close',
          menu: ContextualMenu
        }
      }}
      dialogContentProps={{
        title: 'Share Performance Report',
        styles: {
          content: { padding: 0 },
          innerContent: { minHeight: 250 }
        }
      }}
    >
      {step === 1 && (
        <>
          <ReportDetails />
          <MessageBar
            css={{ marginBottom: 10 }}
            messageBarType={MessageBarType.info}
            isMultiline={true}
          >
            Shared Reports will securely be sent to the Client(s) Documents
            portal.
          </MessageBar>
          <Dropdown
            label="Share with"
            placeholder={optionsPlaceholder}
            multiSelect
            options={options}
            styles={dropdownStyles}
            onRenderOption={onRenderOption}
            onChange={onChange}
            selectedKeys={selectedLoginIds}
          />
        </>
      )}

      {step === 2 && (
        <>
          <ReportDetails />
          <MessageBar
            css={{ marginBottom: 10 }}
            messageBarType={MessageBarType.warning}
            isMultiline={true}
          >
            The following client(s) you are sharing this report with do not have
            visibility to the following accounts:
          </MessageBar>
          <ul css={classes.reportList}>
            {clientsWithPartialAccountVisibility.map((client, i) => (
              <li key={i}>
                <strong>{client?.loginId}</strong> -{' '}
                {client?.missingAccounts.join(', ')}
              </li>
            ))}
          </ul>
        </>
      )}

      {step === 3 && (
        <>
          <ReportDetails />
          <ProgressIndicator
            progressHidden={promiseAllResolved}
            styles={{
              itemProgress: { padding: 0, margin: 0 }
            }}
          />
          {!!clientStatus.success?.length && (
            <Message
              message="The following client(s) were shared the report:"
              type="success"
              clientInfo={clientStatus.success}
            />
          )}

          {!!clientStatus.failure?.length && (
            <Message
              message="The following client(s) were NOT shared the report:"
              type="failure"
              clientInfo={clientStatus.failure}
            />
          )}
        </>
      )}

      <DialogFooter>
        {step === 1 && (
          <ButtonFooter
            defaultAction={closeModal}
            primaryText="Share Report"
            primaryAction={checkForPartialVisibility}
            primaryDisabled={isSubmitDisabled}
          />
        )}
        {step === 2 && (
          <ButtonFooter
            defaultText="Back"
            defaultAction={() => setStep(1)}
            primaryText="Yes"
            primaryAction={shareReport}
            label="Do you want to continue sharing this report?"
          />
        )}
        {step === 3 && (
          <ButtonFooter primaryText="Close" primaryAction={closeModal} />
        )}
      </DialogFooter>
    </Dialog>
  )
}
