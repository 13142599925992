import { constructOdataQuery } from 'api/odata'
import { IOdataRequest } from 'api/odata.types'
import axios, { CancelTokenSource } from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { getRockefellerApiOptions } from 'store/shared'
import { call, cancelled } from 'typed-redux-saga'
import { IRetirementAsset, IRetirementFund } from './retirementTypes'

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

const getRetirementData = <T>(options: IApiOptions, url: string) => {
  const { cancelToken, accessToken } = options

  return axios
    .get<IOdataResult<T>>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

export const fetchRetirementAssets = function* (request: IOdataRequest) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  const { apiRoot } = apiOptions
  const url = `${apiRoot}/datahub/RetirementAssets?${constructOdataQuery(
    request
  )}`
  try {
    return yield* call(getRetirementData<IRetirementAsset>, apiOptions, url)
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

export const fetchRetirementFunds = function* (request: IOdataRequest) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  const { apiRoot } = apiOptions
  const url = `${apiRoot}/datahub/RetirementFunds?${constructOdataQuery(
    request
  )}`
  try {
    return yield* call(getRetirementData<IRetirementFund>, apiOptions, url)
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}
