import { css, useTheme } from '@emotion/react'
import {
  Callout,
  Checkbox,
  PrimaryButton,
  Stack,
  Toggle
} from '@fluentui/react'
import { useState } from 'react'
import { Icon } from '../features/Icons/Icon'

const printDropdownClasses = () => ({
  block: css({
    padding: 15,
    width: 300,
    borderBottom: '1px solid #EBEBEB'
  }),
  heading: css({
    backgroundColor: '#F9FBFC',
    padding: '12px',
    borderBottom: '1px solid #EBEBEB',
    fontWeight: '500',
    fontSize: '16px'
  }),
  right: css({
    display: 'flex',
    justifyContent: 'flex-end'
  }),
  note: css({
    color: '#377EA3'
  }),
  label: css({
    paddingBottom: 10
  }),
  selected: css({
    color: '#1D679D',
    fontWeight: 'bold'
  }),
  unselected: css({
    color: '#AFB1B6'
  }),
  toggle: css({
    '& .ms-Toggle-thumb': css({
      backgroundColor: '#3C6F80 !important'
    }),
    '& .ms-Toggle-background': css({
      backgroundColor: '#ADD2E4 !important'
    })
  }),
  button: css({
    borderRadius: 12,
    backgroundColor: 'black',
    padding: 20,
    '&:hover': css({
      backgroundColor: 'black'
    })
  })
})

interface IPrintDropdownMenuProps {
  print: (masked?: boolean, PrintDropdownMenu?: boolean) => void
  displayDisclaimer: boolean
  isDisabled: boolean
}

export const PrintDropdownMenu: React.FC<IPrintDropdownMenuProps> = ({
  print,
  displayDisclaimer,
  isDisabled
}) => {
  const [showPrinterDropdown, setShowPrinterDropdown] = useState(false)
  const [maskAccountNumbers, setMaskAccountNumbers] = useState(true)
  const [hideRelationshipName, setHideRelationshipName] = useState(true)
  const theme = useTheme()
  const showPrinterDropdownMenu = () => setShowPrinterDropdown(true)

  const printPDF = () => {
    setShowPrinterDropdown(false)
    setMaskAccountNumbers(true)
    setHideRelationshipName(true)
    print(maskAccountNumbers, hideRelationshipName)
  }

  const classes = printDropdownClasses()
  return (
    <>
      <div id="PrintButton">
        <Icon
          type="Print"
          width={24}
          height={24}
          color={theme.colors.extraBlue2}
          onClick={isDisabled ? undefined : showPrinterDropdownMenu}
          isDisabled={isDisabled}
        />
      </div>
      {showPrinterDropdown && (
        <Callout
          target="#PrintButton"
          role="dialog"
          onDismiss={() => setShowPrinterDropdown(false)}
          styles={{
            beak: {
              display: 'none'
            }
          }}
        >
          <div css={classes.heading}>Print PDF</div>
          <div css={classes.block}>
            <div css={classes.label}>
              <strong>Account Numbers</strong>
            </div>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <span
                css={maskAccountNumbers ? classes.selected : classes.unselected}
              >
                Masked
              </span>
              <Toggle
                onChange={() => setMaskAccountNumbers(!maskAccountNumbers)}
                css={classes.toggle}
              />
              <span
                css={
                  !maskAccountNumbers ? classes.selected : classes.unselected
                }
              >
                Unmasked
              </span>
            </Stack>
            {displayDisclaimer && maskAccountNumbers && (
              <div>
                Note: Unmasked account numbers may show in the Description
                column
              </div>
            )}
          </div>
          <div css={classes.block}>
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Checkbox
                  checked={hideRelationshipName}
                  onChange={() =>
                    setHideRelationshipName(!hideRelationshipName)
                  }
                />
                <label css={classes.note}>
                  Do not display Relationship Name
                </label>
              </Stack>
              <div>
                Household name will be displayed at the top of the report by
                default
              </div>
            </Stack>
          </div>
          <div css={classes.block}>
            <div css={classes.right}>
              <PrimaryButton
                text="Continue"
                onClick={() => printPDF()}
                css={classes.button}
              />
            </div>
          </div>
        </Callout>
      )}
    </>
  )
}
