import { IAccount } from 'api/account.types'
import { IClient } from 'api/client.types'
import { ISearchResult } from 'api/common.types'
import { createAction, ActionType } from 'typesafe-actions'
import {
  IAbaNumber,
  IWirePayment,
  ICurrencyCode,
  ICreatePaymentResponse,
  IInsufFundSolution,
  IWireDocType,
  IWireUploadDocRequest,
  IWireSaveDocRequest,
  IMMSummarySI,
  IStandingInstruction
} from '../api/types'
import { IJournalInitiationForm, IMMConfig } from './Journal/types'
import { IPeriodicPayment } from './PeriodicPayment/types'
import {
  IAccountBalance,
  IAccountDetails,
  IAccountHolderStatus,
  IAnticipatedBalance,
  IAttensionResponse,
  IAuthUserList,
  ICountryList,
  IForeignCurrencyPosition,
  IgetWireTypeResponse,
  IHolidayItem,
  IMMDeviceInfoResponse,
  IMMValidateRequest,
  IMMValidateResponse,
  IOwners,
  IPastPaymentsRequest,
  IPastPaymentsResponse,
  IPaymentApproveDetailsResponse,
  IPaymentOPSAssignmentRequest,
  IPaymentReviewRequest,
  IPaymentReviewResponse,
  IPaymentVerificationForm,
  IPeriodicPaymentHistory,
  IPeriodicPlanHistory,
  IStandingInstructionLimit,
  IWireInitiationForm,
  IWireRequest,
  IWireRiskAckRequest,
  IWireRiskAckResponse
} from './types'

export const FETCH_ABA_DETAIL_REQUEST = `@modules/@transfers/@moneymovement/FETCH_ABA_DETAIL_REQUEST`
export const FETCH_ABA_DETAIL_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_ABA_DETAIL_SUCCESS`
export const FETCH_ABA_DETAIL_FAILURE = `@modules/@transfers/@moneymovement/FETCH_ABA_DETAIL_FAILURE`
export const CLEAR_ABA_DETAIL = `@modules/@transfers/@moneymovement/CLEAR_ABA_DETAIL`

export const FETCH_SWIFT_DETAIL_REQUEST = `@modules/@transfers/@moneymovement/FETCH_SWIFT_DETAIL_REQUEST`
export const FETCH_SWIFT_DETAIL_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_SWIFT_DETAIL_SUCCESS`
export const FETCH_SWIFT_DETAIL_FAILURE = `@modules/@transfers/@moneymovement/FETCH_SWIFT_DETAIL_FAILURE`
export const CLEAR_SWIFT_DETAIL = `@modules/@transfers/@moneymovement/CLEAR_SWIFT_DETAIL`

export const FETCH_WIRE_PAYMENTS_REQUEST = `@modules/@transfers/@moneymovement/FETCH_WIRE_PAYMENTS_REQUEST`
export const FETCH_WIRE_PAYMENTS_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_WIRE_PAYMENTS_SUCCESS`
export const FETCH_WIRE_PAYMENTS_FAILURE = `@modules/@transfers/@moneymovement/FETCH_WIRE_PAYMENTS_FAILURE`

export const SUBMIT_PAYMENT_REVIEW_REQUEST = `@modules/@transfers/@moneymovement/SUBMIT_PAYMENT_REVIEW_REQUEST`
export const SUBMIT_PAYMENT_REVIEW_SUCCESS = `@modules/@transfers/@moneymovement/SUBMIT_PAYMENT_REVIEW_SUCCESS`
export const SUBMIT_PAYMENT_REVIEW_FAILURE = `@modules/@transfers/@moneymovement/SUBMIT_PAYMENT_REVIEW_FAILURE`
export const SUBMIT_PAYMENT_REVIEW_CLEAR = `@modules/@transfers/@moneymovement/SUBMIT_PAYMENT_REVIEW_CLEAR`

export const SUBMIT_OPS_ASSIGNMENT_REQUEST = `@modules/@transfers/@moneymovement/SUBMIT_OPS_ASSIGNMENT_REQUEST`
export const SUBMIT_OPS_ASSIGNMENT_SUCCESS = `@modules/@transfers/@moneymovement/SUBMIT_OPS_ASSIGNMENTSUCCESS`
export const SUBMIT_OPS_ASSIGNMENT_FAILURE = `@modules/@transfers/@moneymovement/SUBMIT_OPS_ASSIGNMENT_FAILURE`
export const SUBMIT_OPS_ASSIGNMENT_CLEAR = `@modules/@transfers/@moneymovement/SUBMIT_OPS_ASSIGNMENT_CLEAR`

export const FETCH_PAYMENT_APPROVEDETAILS_REQUEST = `@modules/@transfers/@moneymovement/FETCH_PAYMENT_APPROVEDETAILS_REQUEST`
export const FETCH_PAYMENT_APPROVEDETAILS_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_PAYMENT_APPROVEDETAILS_SUCCESS`
export const FETCH_PAYMENT_APPROVEDETAILS_FAILURE = `@modules/@transfers/@moneymovement/FETCH_PAYMENT_APPROVEDETAILS_FAILURE`
export const FETCH_PAYMENT_APPROVEDETAILS_CLEAR = `@modules/@transfers/@moneymovement/FETCH_PAYMENT_APPROVEDETAILS_CLEAR`

export const FETCH_HOLIDAYS_REQUEST = `@modules/@transfers/@moneymovement/FETCH_HOLIDAYS_REQUEST`
export const FETCH_HOLIDAYS_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_HOLIDAYS_SUCCESS`
export const FETCH_HOLIDAYS_FAILURE = `@modules/@transfers/@moneymovement/FETCH_HOLIDAYS_FAILURE`

export const FETCH_PERIODIC_PAYMENT_REQUEST = `@modules/@transfers/@moneymovement/FETCH_PERIODIC_PAYMENT_REQUEST`
export const FETCH_PERIODIC_PAYMENT_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_PERIODIC_PAYMENT_SUCCESS`
export const FETCH_PERIODIC_PLAN_REQUEST = `@modules/@transfers/@moneymovement/FETCH_PERIODIC_PLAN_REQUEST`
export const FETCH_PERIODIC_PLAN_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_PERIODIC_PLAN_SUCCESS`
export const FETCH_PERIODIC_PAYMENT_FAILURE = `@modules/@transfers/@moneymovement/FETCH_PERIODIC_PAYMENT_FAILURE`
export const FETCH_PERIODIC_PLAN_FAILURE = `@modules/@transfers/@moneymovement/FETCH_PERIODIC_PLAN_FAILURE`
export const FETCH_PERIODIC_PAYMENT_CLEAR = `@modules/@transfers/@moneymovement/FETCH_PERIODIC_PAYMENT_CLEAR`

export const fetchPeriodicHistoryActions = {
  requestPaymentHistory: createAction(FETCH_PERIODIC_PAYMENT_REQUEST)<string>(),
  successPaymentHistory: createAction(FETCH_PERIODIC_PAYMENT_SUCCESS)<
    IPeriodicPaymentHistory[]
  >(),
  requestPlanHistory: createAction(FETCH_PERIODIC_PLAN_REQUEST)<string>(),
  successPlanHistory: createAction(FETCH_PERIODIC_PLAN_SUCCESS)<
    IPeriodicPlanHistory[]
  >(),
  paymentfailure: createAction(FETCH_PERIODIC_PAYMENT_FAILURE)<Error>(),
  planfailure: createAction(FETCH_PERIODIC_PLAN_FAILURE)<Error>()
}

export const fetchHolidaysActions = {
  request: createAction(FETCH_HOLIDAYS_REQUEST)<string>(),
  success: createAction(FETCH_HOLIDAYS_SUCCESS)<IHolidayItem[]>(),
  failure: createAction(FETCH_HOLIDAYS_FAILURE)<Error>()
}

export const FetchAbaDetailActions = {
  request: createAction(FETCH_ABA_DETAIL_REQUEST)<{
    routingNumber: string
    country?: string
  }>(),
  success: createAction(FETCH_ABA_DETAIL_SUCCESS)<IAbaNumber>(),
  failure: createAction(FETCH_ABA_DETAIL_FAILURE)<Error>(),
  clear: createAction(CLEAR_ABA_DETAIL)()
}

export const FetchSwiftCodeDetailActions = {
  request: createAction(FETCH_SWIFT_DETAIL_REQUEST)<{
    swiftCode: string
  }>(),
  success: createAction(FETCH_SWIFT_DETAIL_SUCCESS)<IAbaNumber>(),
  failure: createAction(FETCH_SWIFT_DETAIL_FAILURE)<Error>(),
  clear: createAction(CLEAR_SWIFT_DETAIL)()
}

export const FetchWirePaymentActions = {
  request: createAction(FETCH_WIRE_PAYMENTS_REQUEST)<{
    fromDate: string
    toDate: string
  }>(),
  success: createAction(FETCH_WIRE_PAYMENTS_SUCCESS)<IWirePayment[]>(),
  failure: createAction(FETCH_WIRE_PAYMENTS_FAILURE)<Error>()
}

export const SubmitPaymentReviewActions = {
  request: createAction(SUBMIT_PAYMENT_REVIEW_REQUEST)<IPaymentReviewRequest>(),
  success: createAction(
    SUBMIT_PAYMENT_REVIEW_SUCCESS
  )<IPaymentReviewResponse>(),
  failure: createAction(SUBMIT_PAYMENT_REVIEW_FAILURE)<Error>(),
  clear: createAction(SUBMIT_PAYMENT_REVIEW_CLEAR)()
}

export const SubmitOPSAssignmentActions = {
  request: createAction(
    SUBMIT_OPS_ASSIGNMENT_REQUEST
  )<IPaymentOPSAssignmentRequest>(),
  success: createAction(SUBMIT_OPS_ASSIGNMENT_SUCCESS)(),
  failure: createAction(SUBMIT_OPS_ASSIGNMENT_FAILURE)<Error>(),
  clear: createAction(SUBMIT_OPS_ASSIGNMENT_CLEAR)()
}
export const FetchPaymentApproveDetailsActions = {
  request: createAction(FETCH_PAYMENT_APPROVEDETAILS_REQUEST)<string>(),
  success: createAction(
    FETCH_PAYMENT_APPROVEDETAILS_SUCCESS
  )<IPaymentApproveDetailsResponse>(),
  failure: createAction(FETCH_PAYMENT_APPROVEDETAILS_FAILURE)<Error>(),
  clear: createAction(FETCH_PAYMENT_APPROVEDETAILS_CLEAR)()
}

export const FETCH_COUNTRIES_LIST = `@modules/@transfers/@moneymovement/FETCH_COUNTRIES_LIST`
export const FETCH_COUNTRIES_LIST_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_COUNTRIES_LIST_SUCCESS`
export const FETCH_COUNTRIES_LIST_FAILURE = `@modules/@transfers/@moneymovement/FETCH_COUNTRIES_LIST_FAILURE`
export const FETCH_COUNTRIES_LIST_CLEAR = `@modules/@transfers/@moneymovement/FETCH_COUNTRIES_LIST_CLEAR`

export const fetchCountriesListActions = {
  request: createAction(FETCH_COUNTRIES_LIST)<IMMValidateRequest>(),
  success: createAction(FETCH_COUNTRIES_LIST_SUCCESS)<ICountryList[]>(),
  failure: createAction(FETCH_COUNTRIES_LIST_FAILURE)<Error>(),
  clear: createAction(FETCH_COUNTRIES_LIST_CLEAR)()
}

export const FETCH_AUTH_USER_LIST = `@modules/@transfers/@moneymovement/FETCH_AUTH_USER_LIST`
export const FETCH_AUTH_USER_LIST_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_AUTH_USER_LIST_SUCCESS`
export const FETCH_AUTH_USER_LIST_FAILURE = `@modules/@transfers/@moneymovement/FETCH_AUTH_USER_LIST_FAILURE`
export const FETCH_AUTH_USER_LIST_CLEAR = `@modules/@transfers/@moneymovement/FETCH_AUTH_USER_LIST_CLEAR`

export const fetchAuthUserListActions = {
  request: createAction(FETCH_AUTH_USER_LIST)<string>(),
  success: createAction(FETCH_AUTH_USER_LIST_SUCCESS)<{
    authUsers: IAuthUserList[]
    accountPhNo: IAccountDetails
  }>(),
  failure: createAction(FETCH_AUTH_USER_LIST_FAILURE)<Error>(),
  clear: createAction(FETCH_AUTH_USER_LIST_CLEAR)()
}

export const FETCH_PARTY_PHONE_LIST = `@modules/@transfers/@moneymovement/FETCH_PARTY_PHONE_LIST`
export const FETCH_PARTY_PHONE_LIST_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_PARTY_PHONE_LIST_SUCCESS`
export const FETCH_PARTY_PHONE_LIST_FAILURE = `@modules/@transfers/@moneymovement/FETCH_PARTY_PHONE_LIST_FAILURE`
export const FETCH_PARTY_PHONE_LIST_CLEAR = `@modules/@transfers/@moneymovement/FETCH_PARTY_PHONE_LIST_CLEAR`

export const fetchPartyPhoneListActions = {
  request: createAction(FETCH_PARTY_PHONE_LIST)<string>(),
  success: createAction(FETCH_PARTY_PHONE_LIST_SUCCESS)<string[]>(),
  failure: createAction(FETCH_PARTY_PHONE_LIST_FAILURE)<Error>(),
  clear: createAction(FETCH_PARTY_PHONE_LIST_CLEAR)()
}

export const PROCESS_MMDEVICEINFO_REQUEST = `@modules/@transfers/@moneymovement/PROCESS_MMDEVICEINFO_REQUEST`
export const PROCESS_MMDEVICEINFO_SUCCESS = `@modules/@transfers/@moneymovement/PROCESS_MMDEVICEINFO_SUCCESS`
export const PROCESS_MMDEVICEINFO_FAILURE = `@modules/@transfers/@moneymovement/PROCESS_MMDEVICEINFO_FAILURE`

export const processMMDeviceInfoActions = {
  request: createAction(PROCESS_MMDEVICEINFO_REQUEST)<string>(),
  success: createAction(PROCESS_MMDEVICEINFO_SUCCESS)<IMMDeviceInfoResponse>(),
  failure: createAction(PROCESS_MMDEVICEINFO_FAILURE)<Error>()
}

export const FETCH_CURRENCY_CODE_LIST = `@modules/@transfers/@moneymovement/FETCH_CURRENCY_CODE_LIST`
export const FETCH_CURRENCY_CODE_LIST_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_CURRENCY_CODE_LIST_SUCCESS`
export const FETCH_CURRENCY_CODE_LIST_FAILURE = `@modules/@transfers/@moneymovement/FETCH_CURRENCY_CODE_LIST_FAILURE`
export const FETCH_CURRENCY_CODE_LIST_CLEAR = `@modules/@transfers/@moneymovement/FETCH_CURRENCY_CODE_LIST_CLEAR`

export const fetchCurrencyCodeListActions = {
  request: createAction(FETCH_CURRENCY_CODE_LIST)(),
  success: createAction(FETCH_CURRENCY_CODE_LIST_SUCCESS)<ICurrencyCode[]>(),
  failure: createAction(FETCH_CURRENCY_CODE_LIST_FAILURE)<Error>(),
  clear: createAction(FETCH_CURRENCY_CODE_LIST_CLEAR)()
}
export const FETCH_INSUFFICIENTFUND_SOL_LIST_REQUEST = `@modules/@transfers/@moneymovement/FETCH_INSUFFICIENTFUND_SOL_LIST_REQUEST`
export const FETCH_INSUFFICIENTFUND_SOL_LIST_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_INSUFFICIENTFUND_SOL_LIST_SUCCESS`
export const FETCH_INSUFFICIENTFUND_SOL_LIST_FAILURE = `@modules/@transfers/@moneymovement/FETCH_INSUFFICIENTFUND_SOL_LIST_FAILURE`

export const fetchInsufficientFundSolListActions = {
  request: createAction(FETCH_INSUFFICIENTFUND_SOL_LIST_REQUEST)(),
  success: createAction(FETCH_INSUFFICIENTFUND_SOL_LIST_SUCCESS)<
    IInsufFundSolution[]
  >(),
  failure: createAction(FETCH_INSUFFICIENTFUND_SOL_LIST_FAILURE)<Error>()
}

export const FETCH_WIRE_DOCTYPE_LIST_REQUEST = `@modules/@transfers/@moneymovement/FETCH_WIRE_DOCTYPE_LIST_REQUEST`
export const FETCH_WIRE_DOCTYPE_LIST_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_WIRE_DOCTYPE_LIST_SUCCESS`
export const FETCH_WIRE_DOCTYPE_LIST_FAILURE = `@modules/@transfers/@moneymovement/FETCH_WIRE_DOCTYPE_LIST_FAILURE`

export const fetchWireDocTypeListActions = {
  request: createAction(FETCH_WIRE_DOCTYPE_LIST_REQUEST)<{
    tranType?: string
    tranId?: string
  }>(),
  success: createAction(FETCH_WIRE_DOCTYPE_LIST_SUCCESS)<IWireDocType[]>(),
  failure: createAction(FETCH_WIRE_DOCTYPE_LIST_FAILURE)<Error>()
}

export const UPLOAD_WIRE_DOC_REQUEST = `@modules/@transfers/@moneymovement/UPLOAD_WIRE_DOC_REQUEST`
export const UPLOAD_WIRE_DOC_SUCCESS = `@modules/@transfers/@moneymovement/UPLOAD_WIRE_DOC_SUCCESS`
export const UPLOAD_WIRE_DOC_FAILURE = `@modules/@transfers/@moneymovement/UPLOAD_WIRE_DOC_FAILURE`
export const UPLOAD_WIRE_DOC_CLEAR = `@modules/@transfers/@moneymovement/UPLOAD_WIRE_DOC_CLEAR`

export const uploadWireDocumentActions = {
  request: createAction(UPLOAD_WIRE_DOC_REQUEST)<IWireUploadDocRequest>(),
  success: createAction(UPLOAD_WIRE_DOC_SUCCESS)<IWireSaveDocRequest>(),
  failure: createAction(UPLOAD_WIRE_DOC_FAILURE)<Error>(),
  clear: createAction(UPLOAD_WIRE_DOC_CLEAR)()
}

export const VIEW_WIRE_DOC = `@modules/@transfers/@moneymovement/VIEW_WIRE_DOC`
export const viewWireDocumentActions = {
  view: createAction(VIEW_WIRE_DOC)<{ fileId: string; storageDocKey: string }>()
}

export const DELETE_WIRE_DOC_REQUEST = `@modules/@transfers/@moneymovement/DELETE_WIRE_DOC_REQUEST`
export const DELETE_WIRE_DOC_SUCCESS = `@modules/@transfers/@moneymovement/DELETE_WIRE_DOC_SUCCESS`
export const DELETE_WIRE_DOC_FAILURE = `@modules/@transfers/@moneymovement/DELETE_WIRE_DOC_FAILURE`
export const DELETE_WIRE_DOC_CLEAR = `@modules/@transfers/@moneymovement/DELETE_WIRE_DOC_CLEAR`
export const deleteWireDocumentActions = {
  request: createAction(DELETE_WIRE_DOC_REQUEST)<{
    tranId: string
    fileId: string
    storageDocKey: string
  }>(),
  success: createAction(DELETE_WIRE_DOC_SUCCESS)<string>(),
  failure: createAction(DELETE_WIRE_DOC_FAILURE)<Error>(),
  clear: createAction(DELETE_WIRE_DOC_CLEAR)()
}

export const FETCH_METHOD_OF_INSTRUCTION_LIST = `@modules/@transfers/@moneymovement/FETCH_METHOD_OF_INSTRUCTION_LIST`
export const FETCH_METHOD_OF_INSTRUCTION_LIST_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_METHOD_OF_INSTRUCTION_LIST_SUCCESS`
export const FETCH_METHOD_OF_INSTRUCTION_LIST_FAILURE = `@modules/@transfers/@moneymovement/FETCH_METHOD_OF_INSTRUCTION_LIST_FAILURE`
export const FETCH_METHOD_OF_INSTRUCTION_LIST_CLEAR = `@modules/@transfers/@moneymovement/FETCH_METHOD_OF_INSTRUCTION_LIST_CLEAR`

export const fetchMethodOfInstructionListActions = {
  request: createAction(FETCH_METHOD_OF_INSTRUCTION_LIST)(),
  success: createAction(FETCH_METHOD_OF_INSTRUCTION_LIST_SUCCESS)<string[]>(),
  failure: createAction(FETCH_METHOD_OF_INSTRUCTION_LIST_FAILURE)<Error>(),
  clear: createAction(FETCH_METHOD_OF_INSTRUCTION_LIST_CLEAR)()
}

export const CREATE_PAYMENT = `@modules/@transfers/@moneymovement/CREATE_PAYMENT`
export const CREATE_PAYMENT_SUCCESS = `@modules/@transfers/@moneymovement/CREATE_PAYMENT_SUCCESS`
export const CREATE_PAYMENT_FAILURE = `@modules/@transfers/@moneymovement/CREATE_PAYMENT_FAILURE`
export const CREATE_PAYMENT_CLEAR = `@modules/@transfers/@moneymovement/CREATE_PAYMENT_CLEAR`

export const createPaymentActions = {
  request: createAction(CREATE_PAYMENT)<IWireRequest>(),
  success: createAction(CREATE_PAYMENT_SUCCESS)<ICreatePaymentResponse>(),
  failure: createAction(CREATE_PAYMENT_FAILURE)<Error>(),
  clear: createAction(CREATE_PAYMENT_CLEAR)()
}

export const UPDATE_FED_REF_NO = `@modules/@transfers/@moneymovement/UPDATE_FED_REF_NO`
export const UPDATE_FED_REF_NO_SUCCESS = `@modules/@transfers/@moneymovement/UPDATE_FED_REF_NO_SUCCESS`
export const UPDATE_FED_REF_NO_FAILURE = `@modules/@transfers/@moneymovement/UPDATE_FED_REF_NO_FAILURE`
export const UPDATE_FED_REF_NO_CLEAR = `@modules/@transfers/@moneymovement/UPDATE_FED_REF_NO_CLEAR`

export const updateFedRefNoActions = {
  request: createAction(UPDATE_FED_REF_NO)<IPaymentReviewRequest>(),
  success: createAction(UPDATE_FED_REF_NO_SUCCESS)<string>(),
  failure: createAction(UPDATE_FED_REF_NO_FAILURE)<Error>(),
  clear: createAction(UPDATE_FED_REF_NO_CLEAR)()
}

export const SET_WIRE_FORM_DATA = `@modules/@transfers/@moneymovement/SET_WIRE_FORM_DATA`
export const SET_PERIODIC_PAYMENT = `@modules/@transfers/@moneymovement/SET_PERIODIC_PAYMENT`
export const CLEAR_WIRE_FORM_DATA = `@modules/@transfers/@moneymovement/CLEAR_WIRE_FORM_DATA`
export const SET_DISBURSMENT_APPROVAL_VIEW_DATA = `@modules/@transfers/@moneymovement/SET_DISBURSMENT_APPROVAL_VIEW_DATA`
export const SET_UNSAVED_DISBURSMENT_DATA = `@modules/@transfers/@moneymovement/SET_UNSAVED_DISBURSMENT_DATA`
export const CLEAR_DISBURSMENT_APPROVAL_VIEW_DATA = `@modules/@transfers/@moneymovement/CLEAR_DISBURSMENT_APPROVAL_VIEW_DATA`
export const CLEAR_UNSAVED_DISBURSMENT_DATA = `@modules/@transfers/@moneymovement/CLEAR_UNSAVED_DISBURSMENT_DATA`
export const CLEAR_PERIODIC_PAYMENT = `@modules/@transfers/@moneymovement/CLEAR_PERIODIC_PAYMENT`

export const setFormDataActions = {
  setWireFormData: createAction(SET_WIRE_FORM_DATA)<
    IWireInitiationForm | IJournalInitiationForm
  >(),
  setPeriodicPaymentData:
    createAction(SET_PERIODIC_PAYMENT)<IPeriodicPayment>(),
  setDisbursmentApprovalViewData: createAction(
    SET_DISBURSMENT_APPROVAL_VIEW_DATA
  )<IPaymentVerificationForm>(),
  setUnsavedDisbursmentData: createAction(
    SET_UNSAVED_DISBURSMENT_DATA
  )<IPaymentVerificationForm>(),
  clearFormData: createAction(CLEAR_WIRE_FORM_DATA)(),
  clearDIsbursmentApprovalData: createAction(
    CLEAR_DISBURSMENT_APPROVAL_VIEW_DATA
  )(),
  clearUnsavedDisbursmentData: createAction(CLEAR_UNSAVED_DISBURSMENT_DATA)(),
  clearPeriodicPayment: createAction(CLEAR_PERIODIC_PAYMENT)()
}

export const FETCH_PAYMENT_ATTENSION = `@modules/@transfers/@moneymovement/FETCH_PAYMENT_ATTENSION`
export const FETCH_PAYMENT_ATTENSION_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_PAYMENT_ATTENSION_SUCCESS`
export const FETCH_PAYMENT_ATTENSION_FAILURE = `@modules/@transfers/@moneymovement/FETCH_PAYMENT_ATTENSION_FAILURE`
export const CLEAR_PAYMENT_ATTENSION = `@modules/@transfers/@moneymovement/CLEAR_PAYMENT_ATTENSION`

export const fetchPaymentAttensionActions = {
  request: createAction(FETCH_PAYMENT_ATTENSION)<IWireRequest>(),
  success: createAction(FETCH_PAYMENT_ATTENSION_SUCCESS)<IAttensionResponse>(),
  failure: createAction(FETCH_PAYMENT_ATTENSION_FAILURE)<Error>(),
  clear: createAction(CLEAR_PAYMENT_ATTENSION)()
}

export const RISK_ACKNOWLEDGEMENT_SET = `@modules/@transfers/@moneymovement/RISK_ACKNOWLEDGEMENT_SET`
export const RISK_ACKNOWLEDGEMENT = `@modules/@transfers/@moneymovement/RISK_ACKNOWLEDGEMENT`
export const RISK_ACKNOWLEDGEMENT_SUCCESS = `@modules/@transfers/@moneymovement/RISK_ACKNOWLEDGEMENT_SUCCESS`
export const RISK_ACKNOWLEDGEMENT_FAILURE = `@modules/@transfers/@moneymovement/RISK_ACKNOWLEDGEMENT_FAILURE`
export const RISK_ACKNOWLEDGEMENT_CLEAR = `@modules/@transfers/@moneymovement/RISK_ACKNOWLEDGEMENT_CLEAR`

export const RiskAcknowledgementActions = {
  setRiskAck: createAction(RISK_ACKNOWLEDGEMENT_SET)<IWireRiskAckRequest>(),
  request: createAction(RISK_ACKNOWLEDGEMENT)<IWireRiskAckRequest>(),
  success: createAction(RISK_ACKNOWLEDGEMENT_SUCCESS)<IWireRiskAckResponse>(),
  failure: createAction(RISK_ACKNOWLEDGEMENT_FAILURE)<Error>(),
  clear: createAction(RISK_ACKNOWLEDGEMENT_CLEAR)()
}

export const FETCH_PAYMENT_SI = `@modules/@transfers/@moneymovement/FETCH_PAYMENT_SI`
export const FETCH_PAYMENT_SI_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_PAYMENT_SI_SUCCESS`
export const FETCH_PAYMENT_SI_FAILURE = `@modules/@transfers/@moneymovement/FETCH_PAYMENT_SI_FAILURE`

export const fetchPaymentStandingInstructionsActions = {
  request: createAction(FETCH_PAYMENT_SI)<{
    account: string
    By?: string
    IncludingBalance?: string
    IncludingRejectedSI?: string
  }>(),
  success: createAction(FETCH_PAYMENT_SI_SUCCESS)<IStandingInstruction[]>(),
  failure: createAction(FETCH_PAYMENT_SI_FAILURE)<Error>()
}

export const FETCH_PAST_PAYMENTS =
  '@modules/@transfers/@moneymovement/FETCH_PAST_PAYMENTS'
export const FETCH_PAST_PAYMENTS_SUCCESS =
  '@modules/@transfers/@moneymovement/FETCH_PAST_PAYMENTS_SUCCESS'
export const FETCH_PAST_PAYMENTS_FAILURE =
  '@modules/@transfers/@moneymovement/FETCH_PAST_PAYMENTS_FAILURE'

export const fetchPastPaymentsActions = {
  request: createAction(FETCH_PAST_PAYMENTS)<IPastPaymentsRequest>(),
  success: createAction(FETCH_PAST_PAYMENTS_SUCCESS)<IPastPaymentsResponse>(),
  failure: createAction(FETCH_PAST_PAYMENTS_FAILURE)<Error>()
}

export const FETCH_PAST_STANDING =
  '@modules/@transfers/@moneymovement/FETCH_PAST_STANDING'
export const FETCH_PAST_STANDING_SUCCESS =
  '@modules/@transfers/@moneymovement/FETCH_PAST_STANDING_SUCCESS'
export const FETCH_PAST_STANDING_FAILURE =
  '@modules/@transfers/@moneymovement/FETCH_PAST_STANDING_FAILURE'

export const fetchPastStandingDataActions = {
  request: createAction(FETCH_PAST_STANDING)<{
    account: string
    By: string
  }>(),
  success: createAction(FETCH_PAST_STANDING_SUCCESS)<IMMSummarySI[]>(),
  failure: createAction(FETCH_PAST_STANDING_FAILURE)<Error>()
}

export const REPROCESS_REQUEST =
  '@modules/@transfers/@moneymovement/REPROCESS_REQUEST'
export const REPROCESS_SUCCESS =
  '@modules/@transfers/@moneymovement/REPROCESS_SUCCESS'
export const REPROCESS_FAILURE =
  '@modules/@transfers/@moneymovement/REPROCESS_FAILURE'
export const REPROCESS_CLEAR =
  '@modules/@transfers/@moneymovement/REPROCESS_CLEAR'

export const reprocessTransactionActions = {
  request: createAction(REPROCESS_REQUEST)<string>(),
  success: createAction(REPROCESS_SUCCESS)(),
  failure: createAction(REPROCESS_FAILURE)<Error>(),
  clear: createAction(REPROCESS_CLEAR)()
}

export const DECLINE_REQUEST =
  '@modules/@transfers/@moneymovement/DECLINE_REQUEST'
export const DECLINE_SUCCESS =
  '@modules/@transfers/@moneymovement/DECLINE_SUCCESS'
export const DECLINE_FAILURE =
  '@modules/@transfers/@moneymovement/DECLINE_FAILURE'
export const DECLINE_CLEAR = '@modules/@transfers/@moneymovement/DECLINE_CLEAR'

export const declineTransactionActions = {
  request: createAction(DECLINE_REQUEST)<string>(),
  success: createAction(DECLINE_SUCCESS)(),
  failure: createAction(DECLINE_FAILURE)<Error>(),
  clear: createAction(DECLINE_CLEAR)()
}

export const CLOSE_REQUEST = '@modules/@transfers/@moneymovement/CLOSE_REQUEST'
export const CLOSE_SUCCESS = '@modules/@transfers/@moneymovement/CLOSE_SUCCESS'
export const CLOSE_FAILURE = '@modules/@transfers/@moneymovement/CLOSE_FAILURE'
export const CLOSE_CLEAR = '@modules/@transfers/@moneymovement/CLOSE_CLEAR'

export const closeTransactionActions = {
  request: createAction(CLOSE_REQUEST)<string>(),
  success: createAction(CLOSE_SUCCESS)(),
  failure: createAction(CLOSE_FAILURE)<Error>(),
  clear: createAction(CLOSE_CLEAR)()
}

export const DELETE_REQUEST =
  '@modules/@transfers/@moneymovement/DELETE_REQUEST'
export const DELETE_SUCCESS =
  '@modules/@transfers/@moneymovement/DELETE_SUCCESS'
export const DELETE_FAILURE =
  '@modules/@transfers/@moneymovement/DELETE_FAILURE'
export const DELETE_CLEAR = '@modules/@transfers/@moneymovement/DELETE_CLEAR'

export const deleteTransactionActions = {
  request: createAction(DELETE_REQUEST)<string>(),
  success: createAction(DELETE_SUCCESS)(),
  failure: createAction(DELETE_FAILURE)<Error>(),
  clear: createAction(DELETE_CLEAR)()
}

export const MM_VALIDATE = `@modules/@transfers/@moneymovement/MM_VALIDATE`
export const MM_VALIDATE_PREAPPROVAL = `@modules/@transfers/@moneymovement/MM_VALIDATE_PREAPPROVAL`
export const MM_VALIDATE_SETSTATUS = `@modules/@transfers/@moneymovement/MM_VALIDATE_SETSTATUS`
export const MM_VALIDATE_SUCCESS = `@modules/@transfers/@moneymovement/MM_VALIDATE_SUCCESS`
export const MM_VALIDATE_FAILURE = `@modules/@transfers/@moneymovement/MM_VALIDATE_FAILURE`
export const MM_VALIDATE_CLEAR = `@modules/@transfers/@moneymovement/MM_VALIDATE_CLEAR`

export const mmValidatePaymentActions = {
  request: createAction(MM_VALIDATE)<{
    validateRequest: IMMValidateRequest
    wireData: IWireInitiationForm
    verificationData?: IPaymentVerificationForm
    paymentType?: string
    skipVerification?: boolean
  }>(),
  // account_request: createAction(MM_VALIDATE_ACCOUNT)<IMMValidateRequest>(),
  preapproval_request: createAction(
    MM_VALIDATE_PREAPPROVAL
  )<IMMValidateRequest>(),
  success: createAction(MM_VALIDATE_SUCCESS)<IMMValidateResponse>(),
  failure: createAction(MM_VALIDATE_FAILURE)<Error>(),
  clear: createAction(MM_VALIDATE_CLEAR)()
}

export const MM_PROCESS = `@modules/@transfers/@moneymovement/MM_PROCESS`
export const MM_PROCESS_SUCCESS = `@modules/@transfers/@moneymovement/MM_PROCESS_SUCCESS`
export const MM_PROCESS_FAILURE = `@modules/@transfers/@moneymovementMM_PROCESS_FAILURE`
export const MM_PROCESS_CLEAR = `@modules/@transfers/@moneymovement/MM_PROCESS_CLEAR`

export const mmProcessActions = {
  request: createAction(MM_PROCESS)<{
    wireData: IWireInitiationForm
    verificationData?: IPaymentVerificationForm
    paymentType: string | undefined
  }>(),
  // account_request: createAction(MM_VALIDATE_ACCOUNT)<IMMValidateRequest>(),
  preapproval_request: createAction(
    MM_VALIDATE_PREAPPROVAL
  )<IMMValidateRequest>(),
  success: createAction(MM_PROCESS_SUCCESS)(),
  failure: createAction(MM_PROCESS_FAILURE)<Error>(),
  clear: createAction(MM_PROCESS_CLEAR)()
}

export const MM_VALIDATE_ACCOUNT = `@modules/@transfers/@moneymovement/MM_VALIDATE_ACCOUNT`
export const MM_VALIDATE_ACCOUNT_SUCCESS = `@modules/@transfers/@moneymovement/MM_VALIDATE_ACCOUNT_SUCCESS`
export const MM_VALIDATE_ACCOUNT_FAILURE = `@modules/@transfers/@moneymovement/MM_VALIDATE_ACCOUNT_FAILURE`
export const MM_VALIDATE_ACCOUNT_CLEAR = `@modules/@transfers/@moneymovement/MM_VALIDATE_ACCOUNT_CLEAR`

export const MMWIRE_SAVEAS_DRAFT = `@modules/@transfers/@moneymovement/MMWIRE_SAVEAS_DRAFT`
export const MMWIRE_SAVEAS_DRAFT_SUCCESS = `@modules/@transfers/@moneymovement/MMWIRE_SAVEAS_DRAFT_SUCCESS`
export const MMWIRE_SAVEAS_DRAFT_FAILURE = `@modules/@transfers/@moneymovement/MMWIRE_SAVEAS_DRAFT_FAILURE`
export const MMWIRE_SAVEAS_DRAFT_CLEAR = `@modules/@transfers/@moneymovement/MMWIRE_SAVEAS_DRAFT_CLEAR`
export const MMWIRE_SAVE_AND_REFETCH_DOCS = `@modules/@transfers/@moneymovement/MMWIRE_SAVE_AND_REFETCH_DOCS`

export const mmValidateAccountActions = {
  request: createAction(MM_VALIDATE_ACCOUNT)<IMMValidateRequest>(),
  success: createAction(MM_VALIDATE_ACCOUNT_SUCCESS)<IMMValidateResponse>(),
  failure: createAction(MM_VALIDATE_ACCOUNT_FAILURE)<Error>(),
  clear: createAction(MM_VALIDATE_ACCOUNT_CLEAR)()
}
export const mmWireSaveAsDraftActions = {
  request: createAction(MMWIRE_SAVEAS_DRAFT)<{
    paymentVerificationData?: IPaymentVerificationForm
    wireData: IWireInitiationForm
    isWireInitiation?: boolean
    ismarginpaperworkrequired?: boolean
    paymentType?: string
  }>(),
  success: createAction(MMWIRE_SAVEAS_DRAFT_SUCCESS)(),
  failure: createAction(MMWIRE_SAVEAS_DRAFT_FAILURE)<Error>(),
  clear: createAction(MMWIRE_SAVEAS_DRAFT_CLEAR)(),
  saveAndRefetchDocs: createAction(MMWIRE_SAVE_AND_REFETCH_DOCS)<{
    paymentVerificationData?: IPaymentVerificationForm
    wireData: IWireInitiationForm
    mmValidateResponse?: IMMValidateResponse
    paymentType?: string
  }>()
}

export const FETCH_WIRE_TYPE_REQUEST = `@modules/@transfers/@moneymovement/FETCH_WIRE_TYPE_REQUEST`
export const FETCH_WIRE_TYPE_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_WIRE_TYPE_SUCCESS`
export const FETCH_WIRE_TYPE_FAILURE = `@modules/@transfers/@moneymovement/FETCH_WIRE_TYPE_FAILURE`
export const FETCH_WIRE_TYPE_CLEAR = `@modules/@transfers/@moneymovement/FETCH_WIRE_TYPE_CLEAR`

export const fetchWireTypeActions = {
  request: createAction(FETCH_WIRE_TYPE_REQUEST)<{
    account?: string
    country?: string
  }>(),
  success: createAction(FETCH_WIRE_TYPE_SUCCESS)<IgetWireTypeResponse>(),
  failure: createAction(FETCH_WIRE_TYPE_FAILURE)<Error>(),
  clear: createAction(FETCH_WIRE_TYPE_CLEAR)()
}

export const FETCH_FRG_ACCOUNT_BALANCE_REQUEST = `@modules/@transfers/@moneymovement/FETCH_FRG_ACCOUNT_BALANCE_REQUEST`
export const FETCH_FRG_ACCOUNT_BALANCE_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_FRG_ACCOUNT_BALANCE_SUCCESS`
export const FETCH_FRG_ACCOUNT_BALANCE_FAILURE = `@modules/@transfers/@moneymovement/FETCH_FRG_ACCOUNT_BALANCE_FAILURE`
export const FETCH_FRG_ACCOUNT_BALANCE_CLEAR = `@modules/@transfers/@moneymovement/FETCH_FRG_ACCOUNT_BALANCE_CLEAR`

export const fetchForeignAccountBalanceActions = {
  request: createAction(FETCH_FRG_ACCOUNT_BALANCE_REQUEST)<{
    account?: string
    currency?: string
  }>(),
  success: createAction(
    FETCH_FRG_ACCOUNT_BALANCE_SUCCESS
  )<IForeignCurrencyPosition>(),
  failure: createAction(FETCH_FRG_ACCOUNT_BALANCE_FAILURE)<Error>(),
  clear: createAction(FETCH_FRG_ACCOUNT_BALANCE_CLEAR)()
}

export const FETCH_ACCOUNT_BALANCE_REQUEST = `@modules/@transfers/@moneymovement/FETCH_ACCOUNT_BALANCE_REQUEST`
export const FETCH_ACCOUNT_BALANCE_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_ACCOUNT_BALANCE_SUCCESS`
export const FETCH_ACCOUNT_BALANCE_FAILURE = `@modules/@transfers/@moneymovement/FETCH_ACCOUNT_BALANCE_FAILURE`
export const FETCH_ACCOUNT_BALANCE_CLEAR = `@modules/@transfers/@moneymovement/FETCH_ACCOUNT_BALANCE_CLEAR`

export const fetchAccountBalanceActions = {
  request: createAction(FETCH_ACCOUNT_BALANCE_REQUEST)<string>(),
  success: createAction(FETCH_ACCOUNT_BALANCE_SUCCESS)<IAccountBalance>(),
  failure: createAction(FETCH_ACCOUNT_BALANCE_FAILURE)<Error>(),
  clear: createAction(FETCH_ACCOUNT_BALANCE_CLEAR)()
}

export const FETCH_ANTICIPATED_BALANCE_REQUEST = `@modules/@transfers/@moneymovement/FETCH_ANTICIPATED_BALANCE_REQUEST`
export const FETCH_ANTICIPATED_BALANCE_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_ANTICIPATED_BALANCE_SUCCESS`
export const FETCH_ANTICIPATED_BALANCE_FAILURE = `@modules/@transfers/@moneymovement/FETCH_ANTICIPATED_BALANCE_FAILURE`
export const FETCH_ANTICIPATED_BALANCE_CLEAR = `@modules/@transfers/@moneymovement/FETCH_ANTICIPATED_BALANCE_CLEAR`

export const fetchAnticipatedBalanceActions = {
  request: createAction(FETCH_ANTICIPATED_BALANCE_REQUEST)<string>(),
  success: createAction(
    FETCH_ANTICIPATED_BALANCE_SUCCESS
  )<IAnticipatedBalance>(),
  failure: createAction(FETCH_ANTICIPATED_BALANCE_FAILURE)<Error>(),
  clear: createAction(FETCH_ANTICIPATED_BALANCE_CLEAR)()
}
export const CLIENT_SEARCH_REQUEST = `@modules/@transfers/@moneymovement/CLIENT_SEARCH_REQUEST`
export const CLIENT_SEARCH_SUCCESS = `@modules/@transfers/@moneymovement/CLIENT_SEARCH_SUCCESS`
export const CLIENT_SEARCH_FAILURE = `@modules/@transfers/@moneymovement/CLIENT_SEARCH_FAILURE`
export const CLIENT_SEARCH_CLEAR = `@modules/@transfers/@moneymovement/CLIENT_SEARCH_CLEAR`

export const clientSearchActions = {
  request: createAction(CLIENT_SEARCH_REQUEST)<string | undefined>(),
  success: createAction(CLIENT_SEARCH_SUCCESS)<ISearchResult<IClient>>(),
  failure: createAction(CLIENT_SEARCH_FAILURE)<Error>(),
  clear: createAction(CLIENT_SEARCH_CLEAR)()
}

export const ACCOUNT_SEARCH_REQUEST = `@modules/@transfers/@moneymovement/ACCOUNT_SEARCH_REQUEST`
export const ACCOUNT_SEARCH_SUCCESS = `@modules/@transfers/@moneymovement/ACCOUNT_SEARCH_SUCCESS`
export const ACCOUNT_SEARCH_FAILURE = `@modules/@transfers/@moneymovement/ACCOUNT_SEARCH_FAILURE`
export const ACCOUNT_SEARCH_CLEAR = `@modules/@transfers/@moneymovement/ACCOUNT_SEARCH_CLEAR`

export const accountSearchActions = {
  request: createAction(ACCOUNT_SEARCH_REQUEST)<string | undefined>(),
  success: createAction(ACCOUNT_SEARCH_SUCCESS)<ISearchResult<IAccount>>(),
  failure: createAction(ACCOUNT_SEARCH_FAILURE)<Error>(),
  clear: createAction(ACCOUNT_SEARCH_CLEAR)()
}

export const FETCH_PAYMENT_RISK_EVALUATION = `@modules/@transfers/@moneymovement/FETCH_PAYMENT_RISK_EVALUATION`
export const FETCH_PAYMENT_RISK_EVALUATION_SUCCESS = `@modules/@transfers/@moneymovement/FETCH_PAYMENT_RISK_EVALUATION_SUCCESS`
export const FETCH_PAYMENT_RISK_EVALUATION_FAILURE = `@modules/@transfers/@moneymovement/FETCH_PAYMENT_RISK_EVALUATION_FAILURE`
export const CLEAR_PAYMENT_RISK_EVALUATION = `@modules/@transfers/@moneymovement/CLEAR_PAYMENT_RISK_EVALUATION`

export const fetchPaymentRiskEvalResultActions = {
  request: createAction(FETCH_PAYMENT_RISK_EVALUATION)<{
    callerRefid: string
    role?: string
  }>(),
  success: createAction(
    FETCH_PAYMENT_RISK_EVALUATION_SUCCESS
  )<IAttensionResponse>(),
  failure: createAction(FETCH_PAYMENT_RISK_EVALUATION_FAILURE)<Error>(),
  clear: createAction(CLEAR_PAYMENT_RISK_EVALUATION)()
}

export type FetchAbaDetailActionType = ActionType<typeof FetchAbaDetailActions>

export type FetchWirePaymentActionType = ActionType<
  typeof FetchWirePaymentActions
>

export type SubmitPaymentReviewActionType = ActionType<
  typeof SubmitPaymentReviewActions
>

export type SubmitOPSAssignmentActionType = ActionType<
  typeof SubmitOPSAssignmentActions
>

export type FetchPaymentApproveDetailsActionType = ActionType<
  typeof FetchPaymentApproveDetailsActions
>

export type RiskAcknowledgementActionType = ActionType<
  typeof RiskAcknowledgementActions
>

export const ACCOUNT_HOLDERS = `@modules/@transfers/@moneymovement/ACCOUNT_HOLDERS`
export const ACCOUNT_HOLDERS_SUCCESS = `@modules/@transfers/@moneymovement/ACCOUNT_HOLDERS_SUCCESS`
export const ACCOUNT_HOLDERS_FAILURE = `@modules/@transfers/@moneymovement/ACCOUNT_HOLDERS_FAILURE`
export const ACCOUNT_HOLDERS_CLEAR = `@modules/@transfers/@moneymovement/ACCOUNT_HOLDERS_ERROR`

export const fetchAccountHolderActions = {
  request: createAction(ACCOUNT_HOLDERS)<string>(),
  success: createAction(ACCOUNT_HOLDERS_SUCCESS)<{
    isIndividual: string | undefined
    owners: IOwners[]
    rspStatus: IAccountHolderStatus
  }>(),
  failure: createAction(ACCOUNT_HOLDERS_FAILURE)<{
    isIndividual: string | undefined
    owners: IOwners[] | undefined
    error: Error | undefined
  }>(),
  clear: createAction(ACCOUNT_HOLDERS_CLEAR)()
}

export const ACCOUNT_HOLDERS_COUNT = `@modules/@transfers/@moneymovement/ACCOUNT_HOLDERS_COUNT`
export const ACCOUNT_HOLDERS_COUNT_SUCCESS = `@modules/@transfers/@moneymovement/ACCOUNT_HOLDERS_COUNT_SUCCESS`

export const fetchAccountHolderCountActions = {
  request: createAction(ACCOUNT_HOLDERS_COUNT)<IOwners[]>(),
  success: createAction(ACCOUNT_HOLDERS_COUNT_SUCCESS)<IOwners[]>(),
  // failure: createAction(ACCOUNT_HOLDERS_FAILURE)<Error>(),
  clear: createAction(ACCOUNT_HOLDERS_CLEAR)()
}

export const GET_MM_LIMIT = `@modules/@transfers/@moneymovement/GET_MM_LIMIT`
export const GET_MM_LIMIT_SUCCESS = `@modules/@transfers/@moneymovement/GET_MM_LIMIT_SUCCESS`
export const GET_MM_LIMIT_FAILURE = `@modules/@transfers/@moneymovement/GET_MM_LIMIT_FAILURE`
export const GET_MM_LIMIT_CLEAR = `@modules/@transfers/@moneymovement/GET_MM_LIMIT_CLEAR`

export const getMMLimitActions = {
  request: createAction(GET_MM_LIMIT)(),
  success: createAction(GET_MM_LIMIT_SUCCESS)<IStandingInstructionLimit[]>(),
  failure: createAction(GET_MM_LIMIT_FAILURE)<Error>(),
  clear: createAction(GET_MM_LIMIT_CLEAR)()
}

export const GET_MM_CONFIG_REQUEST = `@modules/@transfers/@moneymovement/GET_MM_CONFIG_REQUEST`
export const GET_MM_CONFIG_SUCCESS = `@modules/@transfers/@moneymovement/GET_MM_CONFIG_SUCCESS`
export const GET_MM_CONFIG_FAILURE = `@modules/@transfers/@moneymovement/GET_MM_CONFIG_FAILURE`
export const GET_MM_CONFIG_CLEAR = `@modules/@transfers/@moneymovement/GET_MM_CONFIG_CLEAR`

export const getMMConfigActions = {
  request: createAction(GET_MM_CONFIG_REQUEST)(),
  success: createAction(GET_MM_CONFIG_SUCCESS)<IMMConfig[]>(),
  failure: createAction(GET_MM_CONFIG_FAILURE)<Error>(),
  clear: createAction(GET_MM_CONFIG_CLEAR)()
}

export const GET_UI_DEFINED_ERROR = `@modules/@transfers/@moneymovement/GET_UI_DEFINED_ERROR`
export const GET_UI_DEFINED_WARNING = `@modules/@transfers/@moneymovement/GET_UI_DEFINED_WARNING`
export const GET_UI_DEFINED_ERROR_CLEAR = `@modules/@transfers/@moneymovement/GET_UI_DEFINED_ERROR_CLEAR`

export const getUIDefinedErrorActions = {
  error: createAction(GET_UI_DEFINED_ERROR)<Error>(),
  warning: createAction(GET_UI_DEFINED_WARNING)<string>(),
  clear: createAction(GET_UI_DEFINED_ERROR_CLEAR)<{
    error?: Error
    warning?: string
  }>()
}

export type WireActions =
  | FetchAbaDetailActionType
  | FetchWirePaymentActionType
  | SubmitPaymentReviewActionType
  | SubmitOPSAssignmentActionType
  | FetchPaymentApproveDetailsActionType
  | RiskAcknowledgementActionType
  | ActionType<typeof FetchSwiftCodeDetailActions>
  | ActionType<typeof fetchHolidaysActions>
  | ActionType<typeof fetchCountriesListActions>
  | ActionType<typeof fetchPartyPhoneListActions>
  | ActionType<typeof fetchAuthUserListActions>
  | ActionType<typeof processMMDeviceInfoActions>
  | ActionType<typeof fetchCurrencyCodeListActions>
  | ActionType<typeof fetchInsufficientFundSolListActions>
  | ActionType<typeof fetchWireDocTypeListActions>
  | ActionType<typeof uploadWireDocumentActions>
  | ActionType<typeof viewWireDocumentActions>
  | ActionType<typeof deleteWireDocumentActions>
  | ActionType<typeof fetchMethodOfInstructionListActions>
  | ActionType<typeof setFormDataActions>
  | ActionType<typeof createPaymentActions>
  | ActionType<typeof updateFedRefNoActions>
  | ActionType<typeof fetchPaymentAttensionActions>
  | ActionType<typeof fetchPaymentStandingInstructionsActions>
  | ActionType<typeof fetchPastPaymentsActions>
  | ActionType<typeof reprocessTransactionActions>
  | ActionType<typeof declineTransactionActions>
  | ActionType<typeof closeTransactionActions>
  | ActionType<typeof mmValidatePaymentActions>
  | ActionType<typeof mmWireSaveAsDraftActions>
  | ActionType<typeof mmValidateAccountActions>
  | ActionType<typeof fetchAccountBalanceActions>
  | ActionType<typeof fetchAnticipatedBalanceActions>
  | ActionType<typeof fetchForeignAccountBalanceActions>
  | ActionType<typeof fetchWireTypeActions>
  | ActionType<typeof clientSearchActions>
  | ActionType<typeof accountSearchActions>
  | ActionType<typeof fetchPaymentRiskEvalResultActions>
  | ActionType<typeof deleteTransactionActions>
  | ActionType<typeof mmProcessActions>
  | ActionType<typeof fetchPastStandingDataActions>
  | ActionType<typeof fetchAccountHolderActions>
  | ActionType<typeof fetchAccountHolderCountActions>
  | ActionType<typeof getMMLimitActions>
  | ActionType<typeof getMMConfigActions>
  | ActionType<typeof getUIDefinedErrorActions>
  | ActionType<typeof fetchPeriodicHistoryActions>
