import { css } from '@emotion/react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { format } from 'date-fns'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { parseDateISOStringInLocalTimezone } from 'shared'
import {
  Dropdown,
  IndeterminateProgressIndicator
} from '../../../components/shared'
import DatePicker from '../../../components/shared/DatePicker'
import { SnackBar } from '../../../components/shared/Snackbar'
import TileHeading, {
  BaseHeaderProps,
  ViewType
} from '../../../components/TileHeading'
import { useClientDashboardTilePreferences } from '../../../hooks/useClientDashboardPreferences'
import { useHandleViewTypeChange } from '../../../hooks/useHandleViewTypeChange'
import { ClientDashboardTiles } from '../../../shared/types'
import {
  useGetActivitySummaryGroupQuery,
  useGetActivitySummaryQuery
} from '../../../store/rdot360AnalyticsApi'
import {
  useRdot360Context,
  useRdot360SelectedAccountsApiContext
} from '../../../store/rdot360Context'
import { useActivityDetailUiState } from '../../Activity/activityDetailsUiState'
import {
  durationDropdownOptions,
  IActivityDurationOptions
} from '../../Activity/ActivityDetailView'
import { useSharedActivityStore } from '../../shared/activity'
import { ActivitySummaryChart } from './ActivitySummaryChart'
import { ActivitySummaryTable } from './ActivitySummaryTable'
import { Last10Activities } from './Last10Activities'

export type ActivityTableType = 'summary' | 'last10'

const parseDate = (date?: string) =>
  date ? parseDateISOStringInLocalTimezone(date) : undefined

const tileName = ClientDashboardTiles.activityTile

const activityStyles = {
  tableTypeButton: css({
    padding: '5px',
    border: 'none',
    backgroundColor: 'transparent',
    borderBottomStyle: 'none',
    cursor: 'pointer'
  }),
  selectedButton: css({
    borderBottomStyle: 'solid'
  })
}

const AccountActivity: FC<BaseHeaderProps> = ({ onExpand, isCobSupported }) => {
  const { handleViewTypeChange, viewType } = useHandleViewTypeChange(tileName)

  const { setTilePreferences, tilePreferences } =
    useClientDashboardTilePreferences(tileName)

  const { activityTableType = 'summary' } = tilePreferences ?? {}

  const {
    dateSelection,
    setDateSelection,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateRangeString,
    setCustomRange
  } = useActivityDetailUiState()

  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()
  const skipSummary =
    !apiContextAccounts?.length ||
    !dateRangeString ||
    (activityTableType !== 'summary' && viewType !== ViewType.graph)
  const {
    data: summary,
    isFetching: isSummaryFetching,
    error: summaryError,
    isUninitialized: isSummaryUninitialized
  } = useGetActivitySummaryGroupQuery(
    skipSummary
      ? skipToken
      : {
          contextAccounts: apiContextAccounts,
          range: dateRangeString
        }
  )
  const summaryData = skipSummary || !!summaryError ? undefined : summary
  const skipLast10 =
    !apiContextAccounts?.length || activityTableType !== 'last10'
  const { asOfDate = 'intraday' } = useRdot360Context()
  const {
    data: last10,
    isFetching: isLast10Fetching,
    error: last10Error,
    isUninitialized: last10Uninitialized
  } = useGetActivitySummaryQuery(
    skipLast10 ? skipToken : { accounts: apiContextAccounts, asOfDate }
  )
  const last10Data = skipLast10 || !!last10Error ? undefined : last10

  const isError = useMemo(
    () => !!summaryError || !!last10Error,
    [last10Error, summaryError]
  )
  const errorMessage =
    (summaryError as Error)?.message ||
    (last10Error as Error)?.message ||
    'An unknown Error Occurred'
  const { setCategories } = useSharedActivityStore()
  const onExpandClicked = useCallback(() => {
    setCategories(undefined)
    onExpand && onExpand()
  }, [onExpand, setCategories])
  useEffect(() => {
    !!startDate &&
      !!endDate &&
      dateSelection === 'Custom Range' &&
      setCustomRange(startDate, endDate)
  }, [dateSelection, endDate, setCustomRange, startDate])

  return (
    <>
      <div
        css={{
          position: 'relative',
          flexDirection: 'column',
          display: 'flex',
          height: '100%'
        }}
      >
        <div
          css={{
            fontSize: '14px',
            lineHeight: '19px',
            textTransform: 'uppercase',
            fontWeight: 500,
            flex: '0 0 32px'
          }}
        >
          <TileHeading
            viewType={viewType}
            onViewtypeChange={handleViewTypeChange}
            onExpand={onExpandClicked}
            graphIconType="chart"
            isCobSupported={isCobSupported}
          >
            Activity
          </TileHeading>
        </div>

        {isError && (
          <div css={{ paddingBottom: '5px' }}>
            <SnackBar message={errorMessage} type="Failure" />
          </div>
        )}

        <div
          css={{
            paddingBottom: '10px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}
        >
          {viewType === ViewType.table && (
            <div css={{ height: 34 }}>
              <button
                css={[
                  activityStyles.tableTypeButton,
                  activityTableType === 'summary' &&
                    activityStyles.selectedButton
                ]}
                onClick={() =>
                  setTilePreferences({
                    activityTableType: 'summary'
                  })
                }
              >
                Summary
              </button>
              <button
                css={[
                  activityStyles.tableTypeButton,
                  activityTableType === 'last10' &&
                    activityStyles.selectedButton
                ]}
                onClick={() =>
                  setTilePreferences({
                    activityTableType: 'last10'
                  })
                }
              >
                Last 10 Activities
              </button>
            </div>
          )}
          {activityTableType === 'summary' && (
            <div css={{ display: 'flex', columnGap: 15, alignItems: 'start' }}>
              <Dropdown
                options={durationDropdownOptions}
                selectedKey={dateSelection}
                onChange={(_, option) => {
                  setDateSelection(option?.key as IActivityDurationOptions)
                }}
                css={{ width: '150px' }}
              />
              {dateSelection === 'Custom Range' && (
                <div
                  css={{ display: 'flex', columnGap: 5, alignItems: 'center' }}
                >
                  <DatePicker
                    value={startDate ? parseDate(startDate) : undefined}
                    onSelectDate={(date) =>
                      date && setStartDate(format(date, 'yyyy-MM-dd'))
                    }
                    formatDate={(date) =>
                      date ? format(date, 'yyyy-MM-dd') : ''
                    }
                    maxDate={endDate ? parseDate(endDate) : new Date()}
                    placeholder="YYYY-MM-DD"
                    style={{ width: '135px' }}
                  />
                  <div>-</div>
                  <DatePicker
                    value={endDate ? parseDate(endDate) : undefined}
                    onSelectDate={(date) =>
                      date && setEndDate(format(date, 'yyyy-MM-dd'))
                    }
                    formatDate={(date) =>
                      date ? format(date, 'yyyy-MM-dd') : ''
                    }
                    minDate={startDate ? parseDate(startDate) : undefined}
                    maxDate={new Date()}
                    placeholder="YYYY-MM-DD"
                    style={{ width: '135px' }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {viewType === ViewType.graph && (
          <div className="test" css={{ flexGrow: 1 }}>
            <ActivitySummaryChart data={summaryData} />
          </div>
        )}
        {viewType === ViewType.table && (
          <>
            {activityTableType === 'summary' ? (
              <ActivitySummaryTable
                data={summaryData}
                isLoading={isSummaryFetching}
                isUninitialized={isSummaryUninitialized}
              />
            ) : (
              <Last10Activities
                data={last10Data}
                isUninitialized={last10Uninitialized}
                isLoading={isLast10Fetching}
              />
            )}
          </>
        )}
      </div>

      {(isSummaryFetching || isLast10Fetching) && (
        <div
          css={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%'
          }}
        >
          <IndeterminateProgressIndicator />
        </div>
      )}
    </>
  )
}

export default AccountActivity
