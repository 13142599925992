import { IDropdownOption, IDropdownStyles, mergeStyles } from '@fluentui/react'
import { useEffect, useMemo, useState } from 'react'
import { Dropdown } from '../../../components/shared'
import { usePerformanceReportsUiState } from './reportsUiState'
import { usePerformanceReportsData } from './usePerformanceReportsData'

type SelectableOption = { key: string; text: string }

const dropdownStyles: Partial<IDropdownStyles> = {
  root: { width: 300 },
  dropdownOptionText: undefined,
  dropdownItemsWrapper: { maxHeight: 500, overflowY: 'auto' },
  dropdownItem: mergeStyles({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
}

const ReportCategoryFilter: React.FC = () => {
  const [categories, setCategories] = useState([] as SelectableOption[])
  const { category = 'ALL', setCategory } = usePerformanceReportsUiState()
  const { data } = usePerformanceReportsData()

  // get categories
  const reportCategories = useMemo(
    () => data?.map((report) => report.reportCategory),
    [data]
  )

  // get unique categories
  const uniqueCategories = useMemo(
    () => Array.from(new Set(reportCategories)),
    [reportCategories]
  )

  const categoryMenu = useMemo(
    () =>
      uniqueCategories.map((category) => ({
        key: category || '',
        text: category || ''
      })),
    [uniqueCategories]
  )

  const categoryDropdownOptions: SelectableOption[] = useMemo(
    () => [{ key: 'ALL', text: 'ALL' }, ...categoryMenu],
    [categoryMenu]
  )

  useEffect(() => {
    setCategories(categoryDropdownOptions)
  }, [categoryDropdownOptions])

  const onRenderOption = (option?: IDropdownOption): JSX.Element | null => {
    if (!option) {
      return null
    }
    return (
      <div
        title={option.text}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '290px'
        }}
      >
        {option.text}
      </div>
    )
  }

  return (
    <Dropdown
      label="Report Category"
      selectedKey={category}
      title={category.length > 36 ? category : undefined}
      options={categories}
      onRenderOption={onRenderOption}
      onChange={(_, option) => {
        setCategory(option?.text || 'ALL')
      }}
      styles={dropdownStyles}
    />
  )
}

export default ReportCategoryFilter
