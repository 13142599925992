import { combineReducers } from 'redux'
import { hurdlesFeaturesReducer, hurdlesFeaturesSagas } from '../features'
import { hurdleDeleteReducer, hurdleDeleteSagas } from './hurdleDelete'
import { hurdlePostReducer, hurdlePostSagas } from './hurdlePost'

export const hurdlesModuleReducer = combineReducers({
  hurdleDelete: hurdleDeleteReducer,
  hurdlePost: hurdlePostReducer,
  features: hurdlesFeaturesReducer
})

export const hurdlesModuleSagas = [
  ...hurdlePostSagas,
  ...hurdleDeleteSagas,
  ...hurdlesFeaturesSagas
]
