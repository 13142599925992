import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  createOrUpdateHurdle,
  getHurdles,
  IHurdle
} from '../../../../../../../api/datahub'
import { AppState } from '../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import { hurdleDefinitionListUpdateActions } from '../features/HurdleDefinitionList/store'

export const hurdlePostActions = createAsyncAction(
  '@modules/@teams/@hurdlePost/REQUEST',
  '@modules/@teams/@hurdlePost/SUCCESS',
  '@modules/@teams/@hurdlePost/FAILURE'
)<IHurdle | undefined, IHurdle | undefined, Error>()

export const hurdlePostReducer = createAsyncReducer(hurdlePostActions)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.hurdlePost

export const {
  getError: getHurdlePostError,
  getIsLoading: getHurdlePostLoading,
  getResult: getHurdlePostResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof hurdlePostActions.request>
) {
  try {
    if (!action.payload) {
      throw new Error('No hurdle found to create')
    }
    const apiOptions = yield* call(getRockefellerApiOptions)
    const result = yield* call(createOrUpdateHurdle, apiOptions, action.payload)

    if (!result) {
      throw new Error('No result returned from dynamics api')
    }

    yield put(hurdlePostActions.success(result))
  } catch (e: any) {
    yield put(hurdlePostActions.failure(e))
  }
}

const onSuccess = function* (
  action: ReturnType<typeof hurdlePostActions.success>
) {
  try {
    if (!action.payload) {
      return
    }

    const apiOptions = yield* call(getRockefellerApiOptions)
    const result = yield* call(getHurdles, apiOptions, {
      filters: [`hurdleId eq ${action.payload.hurdleId}`],
      expand: ['measurements($expand=metrics($expand=payouts))']
    })

    const hurdle = result?.value?.[0]

    if (!hurdle) {
      throw new Error('Failed to fetch created/updated hurdle')
    }

    yield put(hurdleDefinitionListUpdateActions.addOrUpdate(hurdle))
  } catch (e: any) {
    yield put(hurdlePostActions.failure(e))
  }
}

export const hurdlePostSagas = [
  () => takeLatest(hurdlePostActions.request, onRequest),
  () => takeLatest(hurdlePostActions.success, onSuccess)
]
