import { css } from '@emotion/react'
import { performanceDetailTableColumnNames } from './PerformanceTableColumns'
import { colNames as reportDetailTableColumnNames } from './Reports/ReportsDetailTable/columnDefinitions'

const commonCellStyles = {
  left: css({
    textAlign: 'left'
  }),
  right: css({
    textAlign: 'right'
  }),
  center: css({
    textAlign: 'center'
  }),
  ellipsis: css({
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }),
  widthReportName: css({
    width: '35%'
  }),
  widthReportDate: css({
    width: '10%'
  }),
  widthAccountKey: css({
    width: '15%'
  }),
  widthClientName: css({
    width: '30%'
  }),
  widthShareReport: css({
    width: '5%'
  }),
  widthAction: css({
    width: '1%'
  }),
  pointer: css({
    cursor: 'pointer'
  })
}

export const reportCellStyles = {
  [reportDetailTableColumnNames.reportName]: css([
    commonCellStyles.left,
    commonCellStyles.ellipsis,
    commonCellStyles.widthReportName
  ]),
  [reportDetailTableColumnNames.reportDate]: css([
    commonCellStyles.left,
    commonCellStyles.ellipsis,
    commonCellStyles.widthReportDate
  ]),
  [reportDetailTableColumnNames.accountKey]: css([
    commonCellStyles.left,
    commonCellStyles.ellipsis,
    commonCellStyles.widthAccountKey
  ]),
  [reportDetailTableColumnNames.clientName]: css([
    commonCellStyles.left,
    commonCellStyles.ellipsis,
    commonCellStyles.widthClientName
  ]),
  [reportDetailTableColumnNames.shareReport]: css([
    commonCellStyles.widthShareReport
  ]),
  [reportDetailTableColumnNames.action]: css([
    commonCellStyles.center,
    commonCellStyles.widthAction
  ])
}

export const cellStyles = {
  [performanceDetailTableColumnNames.group]: css([
    commonCellStyles.left,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.inceptionDate]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.startValue]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.netFlows]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.investmentEarnings]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.endingValue]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetTWRMTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetTWRQTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetTWRYTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetTWRITD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetIrrMTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetIrrQTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetIrrYTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetIrrITD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ])
}
