import { Icon } from '../../../features/Icons/Icon'

export const Searchbox: React.FC<{
  searchText?: string
  onChange?: (searchText?: string) => void
  title?: string
  width?: string
}> = ({ searchText, onChange, title, width }) => {
  return (
    <div
      css={{
        background: '#fff',
        borderRadius: '4px',
        border: '1px solid #AFB1B6',
        width: width ? width : '250px',
        height: 34,
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        ':focus-within': { outline: '2px solid black' }
      }}
      title={title}
    >
      <div
        title={title}
        aria-hidden="true"
        css={{ padding: '8px 8px', height: 34 }}
      >
        <Icon type="Search" height={14} width={14} title="title" />
      </div>
      <input
        autoComplete="off"
        id="SearchBox"
        type="search"
        css={{
          border: 0,
          borderRadius: '8px',
          width: '100%',
          height: '100%',
          ':focus': {
            outline: 0
          },
          '&:focus::placeholder': {
            color: 'transparent'
          }
        }}
        placeholder="Find"
        value={searchText}
        onChange={(ev) => {
          onChange && onChange(ev.target.value)
        }}
      />
    </div>
  )
}
