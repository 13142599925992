import { Theme, useTheme } from '@emotion/react'
import { Row, Table, flexRender } from '@tanstack/react-table'
import { maskAccountNumber } from 'modules/Advisory/modules/Rdot360/shared/utilities'
import { useRdot360AccountContext } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { ReactComponent as Logo } from 'modules/Advisory/shared/images/bannerRockefellerGlobalFamilyOffice.svg'
import { BalanceItem } from '../../BalanceSummaryTableColumns'
import { balancesDetailTableColumnNames } from '../../shared'

interface IPrintTableProps {
  table: Table<BalanceItem>
  skipCag: boolean
  masked: boolean
}

export const PrintTable: React.FC<IPrintTableProps> = ({
  table,
  skipCag,
  masked
}) => {
  const theme = useTheme()
  const headers = table.getFlatHeaders()
  const rows = table.getRowModel().rows
  const fullSpan = headers.length

  const currencyStyle = (value: number) => {
    if (value > 0) {
      return theme.colors.extraGreen1
    } else if (value < 0) {
      return theme.colors.primaryRed
    }
    return theme.colors.primaryBlack
  }

  return (
    <table className="print-table">
      <colgroup>
        {headers.map((header) => (
          <col key={`col${header.id}`} />
        ))}
      </colgroup>
      <thead>
        <tr>
          <th className="logo-col" colSpan={fullSpan}>
            <div className="top-margin-table" />
            <div className="header-columns logo-row">
              <div className="header-column-half">
                <h1>Balances</h1>
              </div>
              <div className="header-column-half header-right">
                <Logo style={{ width: '252px', height: '37px' }} />
              </div>
            </div>
          </th>
        </tr>
        <tr className="header-table">
          {headers.map((header) => {
            return (
              <th key={`th${header.id}`}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </th>
            )
          })}
        </tr>
        <tr className="table-spacer">
          <th colSpan={fullSpan} />
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => {
          const subRows = row.subRows
          return (
            <>
              <BalancesTableGroupRow
                row={row}
                currencyStyle={currencyStyle}
                theme={theme}
                key={row.id}
              />
              {subRows.map((subRow) => (
                <BalancesTableRow
                  row={subRow}
                  currencyStyle={currencyStyle}
                  theme={theme}
                  key={subRow.id}
                  masked={masked}
                />
              ))}
            </>
          )
        })}
        {skipCag && (
          <tr className="footer-table">
            {headers.map((header) => {
              let cellColor = theme.colors.primaryBlack
              if (header.id === balancesDetailTableColumnNames.todaysChange) {
                const daysChange = table
                  .getRowModel()
                  .rows?.[0]?.getValue(header.id) as number
                cellColor = currencyStyle(daysChange)
              }
              return (
                <td key={header.id} style={{ color: cellColor }}>
                  {header.column.columnDef.footer
                    ? flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )
                    : null}
                </td>
              )
            })}
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={fullSpan} className="footer-note">
            {skipCag ? (
              <>Note: Priced Investments Includes Cash Equivalents</>
            ) : (
              <>
                Note: Priced investments include cash equivalents. Please
                consider account(s) that may be in multiple groups while
                reviewing the group totals.
              </>
            )}
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

const AccountCell: React.FC<{
  accountKey: string | undefined
  masked: boolean
}> = ({ accountKey, masked }) => {
  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()
  const { preferredNickname, CustodyAccount } =
    accountLookupByAccountIdOrKey[accountKey || ''] || {}
  const accountNumber = masked
    ? maskAccountNumber(CustodyAccount)
    : CustodyAccount || ''
  return (
    <div className="masked">
      <div>{preferredNickname}</div>
      <div>{accountNumber}</div>
    </div>
  )
}

interface IBalancesTableRowProps {
  row: Row<BalanceItem>
  currencyStyle: (value: number) => string
  theme: Theme
  masked: boolean
}

const BalancesTableRow: React.FC<IBalancesTableRowProps> = ({
  row,
  currencyStyle,
  theme,
  masked
}) => {
  const cells = row.getVisibleCells()
  return (
    <tr className="balances-table-row">
      {cells.map((cell) => {
        let cellColor = theme.colors.primaryBlack
        let leftPadding = '0'
        const isAccountColumn = cell.id.includes(
          balancesDetailTableColumnNames.accountNumber
        )
        if (cell.id.includes(balancesDetailTableColumnNames.todaysChange)) {
          const daysChange = cell.getContext().getValue() as number
          cellColor = currencyStyle(daysChange)
        }
        if (isAccountColumn) {
          leftPadding = '40px' // indent
        }
        return (
          <td
            key={cell.id}
            style={{ color: cellColor, paddingLeft: leftPadding }}
          >
            {isAccountColumn && masked ? (
              <AccountCell accountKey={cell.row.original.key} masked={masked} />
            ) : (
              flexRender(cell.column.columnDef.cell, cell.getContext())
            )}
          </td>
        )
      })}
    </tr>
  )
}

interface IBalancesTableGroupRowProps {
  row: Row<BalanceItem>
  currencyStyle: (value: number) => string
  theme: Theme
}

const BalancesTableGroupRow: React.FC<IBalancesTableGroupRowProps> = ({
  row,
  currencyStyle,
  theme
}) => {
  const cells = row.getVisibleCells()
  return (
    <tr className="balances-table-group-row">
      {cells.map((cell, i) => {
        let cellColor = theme.colors.primaryBlack
        if (cell.id.includes(balancesDetailTableColumnNames.todaysChange)) {
          const daysChange = cell.getContext().getValue() as number
          cellColor = currencyStyle(daysChange)
        }
        return (
          <td key={cell.id} style={{ color: cellColor }}>
            {!!row.groupingValue && i === 0 && (
              <div>{row.groupingValue as string}</div>
            )}
            {i > 0 &&
              flexRender(
                cell.column.columnDef.aggregatedCell,
                cell.getContext()
              )}
          </td>
        )
      })}
    </tr>
  )
}
