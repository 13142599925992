import { TagDescription } from '@reduxjs/toolkit/dist/query/react'
import { format, subDays } from 'date-fns'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { rdot360Api } from 'store/api/rdot360'
import { apiConstants } from '../apis'
import { ITemplateResponse } from './ITemplateResponse'

export interface ITemplateApiBaseValueResponse<T> {
  value: T[]
}

const { cacheTime } = apiConstants

type TemplateApiTagType = 'template'
const templateApiTags: TemplateApiTagType[] = ['template']
const templateApiWithTags = rdot360Api.enhanceEndpoints({
  addTagTypes: templateApiTags
})

const templateApi = templateApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getTemplate: builder.query<ITemplateResponse | undefined, void>({
      query: () => ({
        url: `/templateapi/api/Template/StaticConfig?version=v2`,
        method: 'GET'
      }),
      keepUnusedDataFor: cacheTime,
      providesTags: ['template']
    })
  })
})

export const { useGetTemplateQuery, useLazyGetTemplateQuery } = templateApi

export const useTemplateApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<TemplateApiTagType>[]) =>
      dispatch(templateApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}

export const useGetStaticConfig = () => {
  const result = useGetTemplateQuery()
  const { data: staticConfig } = result
  const currentBusinessDate =
    staticConfig?.ActivityBusinessDate?.env?.currentbusinessdate
  const previousBusinessDate = staticConfig
    ? staticConfig?.ActivityBusinessDate?.env?.previousbusinessdate ||
      format(subDays(new Date(), 1), 'yyyy-MM-dd')
    : undefined
  return { staticConfig, currentBusinessDate, previousBusinessDate }
}

export const useLazyGetStaticConfig = () => {
  const [triggerRetrieveStaticConfig] = useLazyGetTemplateQuery()

  const trigger = useCallback(
    (preferCacheValue = true) => {
      const result = triggerRetrieveStaticConfig(undefined, preferCacheValue)
      result.unsubscribe()
      return result
    },
    [triggerRetrieveStaticConfig]
  )

  return trigger
}
