import { IFacetResult } from 'api/common.types'
import { IOrderBy } from 'api/odata.types'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import {
  IOdataListColumnDefinition,
  IWithGetValue
} from '../features/OdataList/common/types'

export interface IAlert {
  '@search.score': number
  id: string
  alertType: string
  registrationType: string
  source: string
  status: string
  description: string
  created: string
  due: string
  client: string
  clientid: string
  householdId: string
  householdName: string
  repCode: string
  accountNumber: string
  accountNumberId: string
  accountType: string
  receivedWeek: string
  holdingsMarketValue: number
  rid: string
  owner?: string
  isArchived?: boolean
  isDeleted?: boolean
  age?: string
  currency?: string
  archivedOn?: string
  alertDetails: IAlertDetail[]
}

export interface IAlertDetail {
  key: string
  value: string
}

export interface IAlertsSearchParameters {
  search?: string
  select?: string
  $top?: number
  $skip?: number
  $filter?: string
  $orderby?: string
  $count?: boolean
}

export interface IOdataFacetResult extends IOdataResult<IAlert> {
  '@search.facets': Record<string, IFacetResult[]>
}

export type AlertColumnName =
  | 'pin'
  | 'tag'
  | 'created'
  | 'source'
  | 'alertType'
  | 'description'
  | 'status'
  | 'client'
  | 'accountNumber'
  | 'registrationType'
  | 'holdingsMarketValue'
  | 'repCode'
  | 'owner'
  | 'action'
  | 'due'

export enum AlertColumnIdsEnum {
  pin = 'pin',
  tag = 'tag',
  created = 'created',
  source = 'source',
  alertType = 'alertType',
  description = 'description',
  status = 'status',
  client = 'client',
  accountNumber = 'accountNumber',
  registrationType = 'registrationType',
  holdingsMarketValue = 'holdingsMarketValue',
  repCode = 'repCode',
  owner = 'owner',
  action = 'action',
  due = 'due'
}

export const fieldMapping: Record<string, string> = {
  tag: 'Tag',
  created: 'Created',
  source: 'Source',
  alertType: 'Alert Type',
  description: 'Description',
  status: 'Status',
  client: 'Account Name',
  accountNumber: 'Account #',
  registrationType: 'Registration Type',
  holdingsMarketValue: 'Alert Amount',
  repCode: 'Rep Code',
  actions: 'Action',
  due: 'Due',
  owner: 'Owner',
  isArchived: 'Is Archived'
} as const

export interface IAlertColumnDefinition
  extends IOdataListColumnDefinition,
    IWithGetValue<IAlert> {
  name: string
  minDate?: Date
  maxDate?: Date
  searchable?: boolean
}

export interface IOdataRequest {
  orderby?: IOrderBy[]
  filters?: string[]
  select?: string[]
  expand?: string[]
  top?: number
  skip?: number
  search?: string
  searchFields?: string[]
  count?: boolean
  apply?: string
  facet?: string
}
