import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Overlay,
  Panel,
  PanelType,
  PrimaryButton,
  Stack
} from '@fluentui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { IHurdle } from '../../../../../../../../api/datahub'
import { ErrorComponent } from '../../../../../../../../shared/components/Error'
import { LoadingComponent } from '../../../../../../../../shared/components/Loading'
import { HurdleEditForm } from './HurdleEditForm'
import { getHurdleGroup } from './store/hurdleEditForm'
import {
  getHurdleEditPanelError,
  getIsHurdleEditPanelLoading,
  getIsHurdleEditPanelOpen,
  hurdleEditPanelActions
} from './store/hurdleEditPanel'
export interface IHurdleForm {
  hurdle: IHurdle
}

export const HurdleEditPanel: React.FC = () => {
  const dispatch = useDispatch()
  const methods = useForm<IHurdleForm>()
  const { reset, handleSubmit, formState } = methods
  const { dirtyFields, isDirty } = formState
  const isPanelOpen = useSelector(getIsHurdleEditPanelOpen)
  const onDismiss = useCallback(() => {
    dispatch(hurdleEditPanelActions.close())
    reset()
  }, [dispatch, reset])

  const error = useSelector(getHurdleEditPanelError)
  const onSubmit = useCallback(
    (data?: { hurdle?: IHurdle }) => {
      if (!data) {
        return
      }

      const dirtyNames = Object.entries(dirtyFields?.hurdle || {})
        .filter(([, value]) => !!value)
        .map(([name]) => name)
      const patchFields = [
        'name',
        'entityStartDate',
        'accrualStartDate',
        'term',
        'notes'
      ]
      const canPatch =
        dirtyNames.filter((name) => !patchFields.includes(name)).length < 1

      dispatch(
        hurdleEditPanelActions.submit({
          ...data.hurdle,
          measurements:
            canPatch && data.hurdle?.hurdleId
              ? undefined
              : data.hurdle?.measurements
        })
      )
    },
    [dirtyFields?.hurdle, dispatch]
  )

  const [showValidationError, setShowValidationError] = useState(false)
  useEffect(() => {
    if (!isPanelOpen) {
      return
    }
    setShowValidationError(false)
  }, [isPanelOpen])
  const onError = useCallback(() => {
    setShowValidationError(true)
  }, [])

  const onRenderFooterContent = useCallback(() => {
    return (
      <Stack tokens={{ childrenGap: 10 }}>
        {error && (
          <MessageBar messageBarType={MessageBarType.error}>
            {error?.message}
          </MessageBar>
        )}
        {showValidationError && (
          <ErrorComponent errorMessage="The form failed to validate." />
        )}
        <Stack
          horizontal={true}
          tokens={{ childrenGap: 10 }}
          horizontalAlign="space-between"
        >
          <Stack
            horizontal={true}
            tokens={{ childrenGap: 10 }}
            verticalAlign="center"
          >
            <PrimaryButton type="submit" form="hurdleForm" disabled={!isDirty}>
              Save
            </PrimaryButton>

            <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
          </Stack>
        </Stack>
      </Stack>
    )
  }, [error, isDirty, onDismiss, showValidationError])

  const isLoading = useSelector(getIsHurdleEditPanelLoading)
  const selectedHurdle = useSelector(getHurdleGroup)

  return (
    <FormProvider {...methods}>
      <form id="hurdleForm" onSubmit={handleSubmit(onSubmit, onError)}>
        <Panel
          headerText={`Create New Hurdle`}
          isOpen={isPanelOpen}
          onDismiss={onDismiss}
          isLightDismiss={true}
          closeButtonAriaLabel="Close"
          type={PanelType.custom}
          customWidth="700px"
          onRenderFooterContent={onRenderFooterContent}
          isFooterAtBottom={true}
          styles={{ content: { flexGrow: 1 } }}
        >
          {isLoading && (
            <Overlay styles={{ root: { zIndex: 1 } }}>
              <LoadingComponent />
            </Overlay>
          )}

          <HurdleEditForm selectedHurdle={selectedHurdle} />
        </Panel>
      </form>
    </FormProvider>
  )
}
