import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { IClient } from 'api/client.types'
import { ISearchResult } from 'api/common.types'
import { IOdataRequest } from 'api/odata.types'
import axios from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { datahubApi } from 'store/api/datahub'
import { AxiosBaseArgs, arrayCommaParamsSerializer } from 'store/api/shared'
import {
  IAlert,
  IOdataFacetResult,
  IAlertsSearchParameters
} from './alerts.types'
import { constructOdataQuery } from './odata'

type DatahubApiTagType = 'alerts' | 'source' | 'status' | 'facets'
const datahubApiTags: DatahubApiTagType[] = [
  'alerts',
  'source',
  'status',
  'facets'
]

const datahubWithAlertsTags = datahubApi.enhanceEndpoints({
  addTagTypes: datahubApiTags
})

export const alertsDatahubApi = datahubWithAlertsTags.injectEndpoints({
  endpoints: (builder) => ({
    alerts_search: builder.query<
      ISearchResult<IAlert> | undefined,
      IAlertsSearchParameters | undefined
    >({
      queryFn: async (searchParameters, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IAlert>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/alerts',
          paramsSerializer: arrayCommaParamsSerializer
        }
        const baseApiParams = {
          $count: true,
          $top: 50,
          ...(searchParameters ?? {})
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      providesTags: ['alerts']
    }),
    alerts_facets: builder.query<
      IOdataFacetResult | undefined,
      string | undefined
    >({
      queryFn: async (filter, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<IOdataFacetResult, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: `/search/alerts?${filter}`,
          paramsSerializer: arrayCommaParamsSerializer
        }
        const result = (await baseQuery({
          ...baseApiArgs
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return result
      },
      providesTags: ['alerts', 'facets']
    }),
    alerts_source: builder.query<IOdataFacetResult | undefined, void>({
      queryFn: async (payload, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<IOdataFacetResult, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: '/search/alerts?$top=0&facet=source,count:99',
          paramsSerializer: arrayCommaParamsSerializer
        }
        const baseApiParams = {
          $filter: 'isDeleted ne true'
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return result
      },
      providesTags: ['alerts', 'source']
    }),
    alerts_status: builder.query<
      IOdataFacetResult | undefined,
      { sources: string[]; enabledArchiveFilter: boolean }
    >({
      queryFn: async (payload, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<IOdataFacetResult, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: '/search/alerts?$top=0&facet=status,count:99',
          paramsSerializer: arrayCommaParamsSerializer
        }
        const baseApiParams = {
          $filter: `${
            payload.sources.length > 0
              ? "search.in(source, '" +
                payload.sources.join('|') +
                "', '|') and "
              : ''
          }isArchived ${
            payload.enabledArchiveFilter ? 'eq true' : 'ne true'
          } and isDeleted ne true`
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return result
      },
      providesTags: ['alerts', 'status']
    }),
    getIndividualsFromLegalEntityID: builder.query<
      IClient[] | undefined,
      string
    >({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IClient>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/clients',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const baseApiParams = {
          $filter: `LegalEntityID eq '${id}'`,
          $count: true,
          select: [
            'LegalEntityName',
            'householdId',
            'householdList',
            'contactdetails/emailid',
            'legalEntityType',
            'Account',
            'LegalEntityID',
            'LegalEntityName',
            'loginDetails'
          ],
          queryType: 'full'
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data?.value
        }
      }
    }),
    updateAlertStatus: builder.query<
      any | undefined,
      {
        options?: IApiOptions
        id?: string
        receivedWeek?: string
        isArchived?: boolean
        changedBy?: string
        archivedOn?: string
      }
    >({
      query: ({
        options,
        id,
        receivedWeek,
        isArchived,
        changedBy,
        archivedOn
      }) => ({
        url: `${options?.apiRoot}/func-rdot/updatedocument/${id}/${receivedWeek}`,
        method: 'post',
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${options?.accessToken}`
        },
        data: { isArchived: isArchived, changedBy: changedBy, archivedOn }
      }),
      providesTags: ['alerts', 'facets'],
      transformResponse: (x: IOdataResult<any[]>) => x
    }),
    updateOwner: builder.query<
      any | undefined,
      {
        options?: IApiOptions
        id?: string
        receivedWeek?: string
        owner?: string | null
        changedBy?: string
      }
    >({
      query: ({ options, id, receivedWeek, owner, changedBy }) => ({
        url: `${options?.apiRoot}/func-rdot/updatedocument/${id}/${receivedWeek}`,
        method: 'post',
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${options?.accessToken}`
        },
        data: { owner: owner, changedBy: changedBy }
      }),
      providesTags: ['alerts']
    })
  })
})

export const {
  useAlerts_searchQuery,
  useAlerts_facetsQuery,
  useAlerts_sourceQuery,
  useAlerts_statusQuery,
  useLazyUpdateOwnerQuery,
  useGetIndividualsFromLegalEntityIDQuery,
  useLazyUpdateAlertStatusQuery
} = alertsDatahubApi

const apiVersion = '1.0'
const defaultHeaders = {
  'api-version': apiVersion
}

export const getAlerts = (options: IApiOptions, request: IOdataRequest) => {
  const { apiRoot, cancelToken, accessToken } = options
  const query = constructOdataQuery(request)
  const url = `${apiRoot}/datahub/search/alerts?${query}`

  return axios
    .get<IOdataResult<IAlert>>(url, {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}
