import { IconButton, IContextualMenuItem, Stack } from '@fluentui/react'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { navigationActions } from 'store/ui/actions'
import { RdotUserRoleEnum } from 'store/user/rdotUser'
import { getRdotUserRoles } from 'store/user/selectors'
import { IHousehold } from '../../api/household.types'
import { editHouseholdNamePanelActions } from './features/EditHouseholdName'
import { changeRequestEditUiActions } from './features/HouseholdChangeRequest/store'
import { householdDetailUiActions } from './features/HouseholdDetail'
import { householdFeePanelActions } from './features/HouseholdFee/store/householdFeePanel'

interface IHouseholdMenuContainerProps {
  household: IHousehold
}

export const HouseholdMenuContainer: React.FC<IHouseholdMenuContainerProps> = ({
  household
}) => {
  const dispatch = useDispatch()
  const userRoles = useSelector(getRdotUserRoles)
  const isOptionsIncomeAndExpenseReportUser = useMemo(
    () =>
      userRoles?.some(
        (role) =>
          role ===
          RdotUserRoleEnum.Advisory_Feature_OptionsIncomeAndExpenseReport
      ),
    [userRoles]
  )
  const { setHousehold } = useRdot360Context()
  const navigate = useNavigate()
  const navigateToRdot360 = useCallback(() => {
    const { householdId } = household
    if (!householdId) {
      return
    }
    setHousehold({ householdId })
    navigate('/advisory/client')
  }, [household, navigate, setHousehold])
  return (
    <Stack
      horizontal={true}
      tokens={{ childrenGap: 10 }}
      styles={{
        root: {
          alignItems: 'center'
        }
      }}
    >
      <IconButton
        title="Household Menu"
        ariaLabel="Menu"
        disabled={false}
        checked={false}
        menuIconProps={{ iconName: 'MoreVertical' }}
        menuProps={{
          shouldFocusOnMount: true,
          items: [
            {
              key: 'view-detail',
              onClick: () => {
                dispatch(
                  dispatch(
                    householdDetailUiActions.openPanel(household?.householdId)
                  )
                )
              },
              text: 'View Detail'
            },
            {
              key: 'edit-household-name',
              onClick: () => {
                dispatch(
                  editHouseholdNamePanelActions.openPanel({
                    id: household.id,
                    name: household.householdName
                  })
                )
              },
              text: 'Edit Household Name'
            },
            {
              key: 'new-change-request',

              onClick: () => {
                dispatch(
                  changeRequestEditUiActions.openPanel({
                    targetHouseholdId: household.householdId,
                    targetHouseholdName: household.householdName
                  })
                )
              },
              text: 'New Change Request'
            },
            household.householdId && {
              key: 'edit-fee-details',

              onClick: () => {
                if (!household.householdId) {
                  return
                }
                dispatch(
                  householdFeePanelActions.openPanel(household.householdId)
                )
              },
              text: 'Edit Billing Methodology'
            },
            isOptionsIncomeAndExpenseReportUser && {
              key: 'options-income-and-expense-report',

              onClick: () => {
                dispatch(
                  navigationActions.launchUrl({
                    url: `/advisory/reports/options-income-and-expense-report?householdId=${household.id}`
                  })
                )
              },
              text: 'Options Report'
            },
            {
              key: 'launch-client-dashboard',

              onClick: navigateToRdot360,
              text: 'Client Dashboard'
            }
          ].filter((x) => x) as IContextualMenuItem[]
        }}
      />
    </Stack>
  )
}
