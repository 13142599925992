import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { viewByOptions } from '../../components/shared/filterOptions'
import { useClientDashboardTilePreferences } from '../../hooks/useClientDashboardPreferences'
import { ClientDashboardTiles } from '../../shared/types'

export enum IncomeViewType {
  account = 'account',
  summary = 'summary'
}

export interface IIncomeDetailUiState {
  searchText: string
}

const { actions, reducer } = createSlice({
  name: 'incomeDetailsUiState',
  initialState: {
    searchText: ''
  } as IIncomeDetailUiState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    }
  }
})

export { reducer as incomeDetailsUiStateReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.income.incomeDetailsUiState

const getSearchText = flow(rootSelector, (x) => x.searchText)

const tileName = ClientDashboardTiles.incomeTile

const defaultViewByKey = viewByOptions[0].key as IncomeViewType

export const useIncomeDetailsUiState = () => {
  const dispatch = useDispatch()

  const { tilePreferences, setTilePreferences } =
    useClientDashboardTilePreferences(tileName)

  const { viewByKey = defaultViewByKey } = tilePreferences ?? {}

  const searchText = useSelector(getSearchText)

  const setSearchText = useCallback(
    (search?: string) => {
      dispatch(actions.setSearchText(search || ''))
    },
    [dispatch]
  )
  const setViewByKey = useCallback(
    (viewByKey: IncomeViewType) => {
      setTilePreferences({
        viewByKey
      })
    },
    [setTilePreferences]
  )

  return {
    searchText,
    setSearchText,
    viewByKey,
    setViewByKey
  }
}
