import { css } from '@emotion/react'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IHouseholdInsight } from 'store/api/insights'
import { getEnvironmentConfigApps } from 'store/system'
import { navigationActions } from 'store/ui/actions'

const classes = {
  button: css({
    border: 'solid 1px #D9CFBB',
    backgroundColor: '#FBFBF9',
    color: '#2A565B',
    cursor: 'pointer',
    padding: '8px 12px'
  }),
  buttonContainer: css({
    textAlign: 'right'
  })
}

export interface IInsightDetailProps {
  insight: IHouseholdInsight
}

export const InsightDetail: React.FC<IInsightDetailProps> = ({ insight }) => {
  const dispatch = useDispatch()
  const appsConfig = useSelector(getEnvironmentConfigApps)

  const sortedStatements = useMemo(() => {
    const statements = insight.statements
    if (!statements) {
      return
    }
    return [...statements].sort((a, b) => a.order - b.order)
  }, [insight])

  const onViewDetailsClicked = useCallback(() => {
    if (!appsConfig) {
      return
    }
    dispatch(
      navigationActions.launchUrl({
        url: `${appsConfig.insightsDashboard?.url}${insight.ins_details_url}`
      })
    )
  }, [appsConfig, dispatch, insight])

  return (
    <>
      <p>
        <b>{insight.ins_name}</b>
      </p>
      {sortedStatements?.map((s, index) => (
        <p
          key={index}
          dangerouslySetInnerHTML={{
            __html: s.statement
          }}
        />
      ))}
      {insight.ins_details_url && (
        <div css={classes.buttonContainer}>
          <button css={classes.button} onClick={onViewDetailsClicked}>
            View Details
          </button>
        </div>
      )}
    </>
  )
}
