import { Stack, Link as FluentLink, ILinkProps } from '@fluentui/react'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { navigationActions } from 'store/ui/actions'
import { Separator } from '../../../shared/components/Separator'

const Link: React.FC<ILinkProps> = ({ href, target, onClick, ...props }) => {
  const dispatch = useDispatch()
  const onLinkClick = useCallback(
    (event: React.MouseEvent<any>) => {
      event.preventDefault()
      href && dispatch(navigationActions.launchUrl({ url: href, target }))
      onClick?.(event)
    },
    [dispatch, href, onClick, target]
  )
  return <FluentLink {...props} onClick={onLinkClick} />
}

export const FeedbackContainer: React.FC<{ onLinkClick?: () => void }> = ({
  onLinkClick
}) => {
  const onClick = useCallback(() => onLinkClick?.(), [onLinkClick])

  return (
    <Stack
      styles={{ root: { padding: '10px', minWidth: '270px' } }}
      tokens={{ childrenGap: 20 }}
    >
      <div>
        <header style={{ paddingBottom: '10px' }}>
          <h3>Tutorials</h3>
        </header>
        <div style={{ paddingBottom: '10px' }}>
          <Link
            href="https://rockco.sharepoint.com/:p:/s/RockcoCommunications/EW9rXOrNxrNOnH1eGyaaT84BFVcFFrAZu2uHILeypKOXUQ?e=qRdalY"
            target="_blank"
            onClick={onClick}
          >
            Book Dashboard Tutorial
          </Link>
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <Link
            href="https://rockco.sharepoint.com/:w:/s/RockcoCommunications/ES_-wT59tJ9Jq23KiJGvbWcB1xjfr6qcu6w_Fm9NIoHx-A?e=6byx9p"
            target="_blank"
            onClick={onClick}
          >
            Account Linking Tutorial
          </Link>
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <Link
            href="https://rockco.sharepoint.com/:p:/s/RockcoCommunications/Ea4Tc0YpmMRAhLd_FOZBL_ABebih-nYmY1huPRW9G0cV2w?e=bnQOat"
            target="_blank"
            onClick={onClick}
          >
            Household Change Request Tutorial
          </Link>
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <Link
            href="https://rockco.sharepoint.com/:b:/s/RockcoCommunications/EZww_PHzn6tFpGJOa9nQxM0BvNfH7sH39QBeSpY2h9Pe-Q?e=4A1zGh"
            target="_blank"
            onClick={onClick}
          >
            Margin Rate Adjustment Tool
          </Link>
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <Link
            href="https://rockco.sharepoint.com/:b:/s/RockcoCommunications/RRC/EY4zSSGGc8FKhRs14jNKF-YBjONzBOnMaHbmZEcZbcZcyQ?e=44C8ac"
            target="_blank"
            onClick={onClick}
          >
            Client Dashboard Tutorial
          </Link>
        </div>
        <Separator />
        <div style={{ paddingBottom: '10px' }}>
          <Link
            href="https://rockco.sharepoint.com/:b:/s/RockcoCommunications/RRC/EY5-_Gmj5nFKpardOOJUfpQBGET7tupOvsSGN-QjCa9QHg?e=r9pefW"
            target="_blank"
            onClick={onClick}
          >
            Rockefeller Connect Release Notes
          </Link>
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <Link
            href="https://rockco.sharepoint.com/:b:/s/RockcoCommunications/RRC/EVLEk6FE78FMjebBQDfj1DkBZOSDrphBiAuhXRtuGUilvA?e=GdamwB"
            target="_blank"
            onClick={onClick}
          >
            Rockefeller Digital Release Notes
          </Link>
        </div>
        <Separator />
        <div style={{ paddingBottom: '10px' }}>
          <Link
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=pSo1dIPYSk6EI3his0Jwjo_ujov4xFFDvcvF3yR02f5UQTlEOENTUFZUWTVOMUtSRVJLQkhDTjVIQi4u"
            target="_blank"
            onClick={onClick}
          >
            Feedback?
          </Link>
        </div>
        <div>
          <Link
            href="https://servicedesk.rockco.com "
            target="_blank"
            onClick={onClick}
          >
            Report an Issue
          </Link>
        </div>
      </div>
    </Stack>
  )
}
