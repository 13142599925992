import { css } from '@emotion/react'
import {
  ExpandedState,
  Header,
  Row,
  Table,
  flexRender
} from '@tanstack/react-table'
import { sum } from 'lodash'
import { ITableHeaderColumnSize } from 'modules/Advisory/modules/Rdot360/shared/ITableHeaderColumnSize'
import { ICategoryPosition } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPositionResponse'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useWidthObserver } from 'shared/hooks/useResizeObserver'
import {
  InvestmentsTableBody,
  InvestmentsTableGroups
} from '../InvestmentsTable/InvestmentsTable'
import { investmentsTableColumnNames as colNames } from '../InvestmentsTable/shared'
import { cellStyles } from '../InvestmentsTable/styles'

const getClasses = () => ({
  printTableContainer: css({
    breakAfter: 'page',
    marginTop: '20px',
    ['& .investments-table > tbody > tr > td:first-of-type span']: {
      overflow: 'visible',
      textOverflow: 'unset',
      whiteSpace: 'normal'
    },
    ['& .investments-table > tbody > tr > td:first-of-type div']: {
      overflow: 'visible',
      textOverflow: 'unset',
      whiteSpace: 'normal'
    },
    ['& td svg']: {
      display: 'none'
    },
    ['& .investments-table *']: {
      textDecoration: 'none'
    }
  }),
  printTable: css({
    width: '100%',
    borderCollapse: 'collapse',
    tableLayout: 'fixed'
  }),
  colWidth: css({
    width: '100%'
  }),
  printTableHeader: css({
    marginTop: '120px',
    backgroundColor: '#EFF3F7',
    width: '100%',
    th: css({
      padding: '10px 8px'
    }),
    'th:first-child': css({
      paddingLeft: '25px'
    }),
    'th:last-child': css({
      paddingRight: '25px'
    })
  }),
  printTableTotals: css({
    backgroundColor: '#EFF3F7',
    td: css({
      padding: '10px 8px'
    })
  }),
  headerPadding: css({
    padding: '20px 0'
  })
})

interface IPrintTableProps {
  table: Table<ICategoryPosition>
  currentTable: Record<string, boolean>
  setExpanded: React.Dispatch<React.SetStateAction<ExpandedState>>
  maxDepth: number
}

export const PrintTable: React.FC<IPrintTableProps> = ({
  table,
  currentTable,
  setExpanded,
  maxDepth
}) => {
  const expandRowsToDepth = (
    rows: Row<ICategoryPosition>[],
    depth: number,
    expandRows: Record<string, boolean>
  ) => {
    if (depth === 0) {
      return
    }
    rows.forEach((row) => {
      expandRows[row.id] = true
      const canExpand = row.getCanExpand()
      row.subRows &&
        canExpand &&
        expandRowsToDepth(row.subRows, depth - 1, expandRows)
    })
  }

  const headers = table.getFlatHeaders()
  const rows = table.getPreExpandedRowModel().rows
  const { secid } = colNames
  const expandRows: Record<string, boolean> = {}
  expandRowsToDepth(rows, maxDepth, expandRows)

  useEffect(() => {
    setExpanded(expandRows)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tableRefContainer = useRef<HTMLDivElement>(null)
  const containerWidth = useWidthObserver(tableRefContainer)
  const tableWidth = Math.max(1450, containerWidth || 0)
  const classes = useMemo(() => getClasses(), [])

  const getAdjustedHeaderSizes = (
    sizes: ITableHeaderColumnSize[],
    tableWidth: number,
    depth = 0,
    firstColumnOffset = 9,
    lastColumnOffset = 9
  ) => {
    const totalSize = sum(sizes.map(({ width }) => width))
    const ratio = tableWidth ? tableWidth / totalSize : 1

    return sizes.map(({ width, id }, i) => {
      const isFirst = i === 0
      const len = sizes?.length ?? 0
      const isLast = i === len - 1
      const depthAdjustment =
        isFirst || isLast
          ? depth * (isFirst ? firstColumnOffset : lastColumnOffset)
          : 0

      return { id, width: width * ratio - depthAdjustment }
    })
  }

  const getHeaderSizes = useCallback(
    (depth?: number) => {
      const sizes = getAdjustedHeaderSizes(
        headers.map((x) => ({ id: x.id || '', width: x.getSize() || 0 })),
        // -2 for the border around the table
        tableWidth - 2,
        depth
      )
      return sizes
    },
    [headers, tableWidth]
  )

  const sizes = getHeaderSizes()
  const fullSpan = sizes?.length ?? 0

  return (
    <div css={classes.printTableContainer} ref={tableRefContainer}>
      <table css={classes.printTable}>
        <colgroup css={classes.colWidth}>
          <ColWidth sizes={sizes} />
        </colgroup>
        <thead>
          <tr>
            <td colSpan={fullSpan}>
              <div css={classes.headerPadding}>&nbsp;</div>
            </td>
          </tr>
          <tr css={classes.printTableHeader}>
            <TableHeader headers={headers} />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={fullSpan} css={{ paddingBottom: 0 }}>
              {currentTable[secid] ? (
                <InvestmentsTableBody
                  rows={rows}
                  getHeaderSizes={getHeaderSizes}
                  depth={0}
                />
              ) : (
                <InvestmentsTableGroups
                  rows={rows}
                  depth={0}
                  getHeaderSizes={getHeaderSizes}
                />
              )}
            </td>
          </tr>
          <tr css={classes.printTableTotals}>
            <TableTotals headers={headers} />
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const ColWidth: React.FC<{
  sizes: ITableHeaderColumnSize[]
}> = ({ sizes }) => {
  return (
    <>
      {sizes.map(({ width, id }) => {
        return (
          <col
            key={id}
            style={{
              width,
              maxWidth: width
            }}
          />
        )
      })}
    </>
  )
}

const TableHeader: React.FC<{
  headers: Header<ICategoryPosition, unknown>[]
}> = ({ headers }) => {
  return (
    <>
      {headers.map((header) => {
        return (
          <th key={header.id} css={[cellStyles[header.id]]}>
            {flexRender(header.column.columnDef.header, header.getContext())}
          </th>
        )
      })}
    </>
  )
}

const TableTotals: React.FC<{
  headers: Header<ICategoryPosition, unknown>[]
}> = ({ headers }) => {
  return (
    <>
      {headers.map((header) => (
        <td key={header.id} css={[cellStyles[header.column.id]]}>
          {header.column.columnDef.footer
            ? flexRender(header.column.columnDef.footer, header.getContext())
            : null}
        </td>
      ))}
    </>
  )
}
