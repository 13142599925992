import { subMonths } from 'date-fns'
import { range, unionWith } from 'lodash'
import { useMemo } from 'react'
import { IndeterminateProgressIndicator } from '../../../components/shared'
import { IncomeChart, useIncomeFeatureStore } from '../../../features/Income'
import { IIncomeSummaryChartResponseSubTotal } from '../../../store/holdingsApi/IIncomeSummaryResponse'
import { useGetHistoricalIncomeChartQueryForSelectedAccounts } from '../../../store/rdot360Context'
import { IncomeDisclaimer } from './IncomeDisclaimer'
import { IncomeWidgetHeader } from './IncomeWidgetHeader'

export const IncomeWidgetChartHistorical: React.FC = () => {
  const { includeOptionPremiums } = useIncomeFeatureStore()

  const {
    data: incomeWidgetChartResponse,
    isFetching,
    isLoading,
    isError
  } = useGetHistoricalIncomeChartQueryForSelectedAccounts(12)

  const data = useMemo(
    () =>
      isFetching || isError || !incomeWidgetChartResponse
        ? undefined
        : incomeWidgetChartResponse,
    [isFetching, isError, incomeWidgetChartResponse]
  )

  const dataWith12Months = useMemo(() => {
    const now = new Date()
    const empty = range(0, 13)
      .map((i): Date => subMonths(now, i))
      .map(
        (x): IIncomeSummaryChartResponseSubTotal => ({
          monSeq: x.getMonth() + 1,
          year: x.getFullYear()
        })
      )

    return unionWith(
      data?.incomeSubTotal || [],
      empty,
      (a, b) =>
        (a.monSeq || a.monseq) === (b.monSeq || b.monseq) && a.year === b.year
    )
  }, [data?.incomeSubTotal])

  return (
    <>
      <IncomeWidgetHeader data={data} />
      <IncomeChart
        data={dataWith12Months}
        includeOptionPremiums={includeOptionPremiums}
      />
      <IncomeDisclaimer />
      <div
        css={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%'
        }}
      >
        {(isLoading || isFetching) && <IndeterminateProgressIndicator />}
      </div>
    </>
  )
}
