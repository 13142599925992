import { Stack, Text } from '@fluentui/react'
import React, { memo } from 'react'
import { IAccount } from '../../../api/account.types'
import {
  ConnectedMasked,
  ConnectedMaskedText
} from '../../../shared/components/MaskedText'
import { AccountMenuContainer } from '../../Accounts/AccountMenuContainer'

interface IOwnProps {
  account: IAccount
  menu?: React.ReactElement | null
}

export const AccountNumberCell: React.FC<IOwnProps> = memo(
  ({ account, menu }) => {
    const {
      AdvisorAddedNickName,
      CustodyAccount,
      registrationtype,
      accountStatus,
      Shortname
    } = account

    const additionalInfo = [AdvisorAddedNickName || Shortname].filter((x) => x)

    return (
      <Stack
        horizontal={true}
        tokens={{ childrenGap: 5 }}
        styles={{ root: { alignItems: 'center', minWidth: 0 } }}
      >
        {menu === undefined ? <AccountMenuContainer account={account} /> : menu}
        <Stack styles={{ root: { minWidth: 0, overflow: 'hidden' } }}>
          <Stack
            horizontal={true}
            tokens={{ childrenGap: 3 }}
            verticalAlign="center"
            styles={{ root: { minWidth: 0, overflow: 'hidden' } }}
          >
            <div
              style={{
                ...(accountStatus === 'Closed'
                  ? { textDecoration: 'line-through', fontStyle: 'italic' }
                  : {}),
                minWidth: 0,
                overflow: 'hidden'
              }}
            >
              <ConnectedMaskedText nowrap={true} text={CustodyAccount} />
            </div>
            {registrationtype && (
              <Text
                variant="small"
                nowrap={true}
                styles={{ root: { fontWeight: 'bold' } }}
              >
                ({registrationtype})
              </Text>
            )}
          </Stack>
          <Stack
            horizontal={true}
            styles={{ root: { minWidth: 0 } }}
            tokens={{ childrenGap: 3 }}
          >
            {additionalInfo.length > 0 &&
              additionalInfo
                .map((info, i) => (
                  <Text key={i} variant="small" nowrap={true}>
                    <ConnectedMasked text={info} />
                  </Text>
                ))
                .reduce((a, x) =>
                  a === null ? (
                    x
                  ) : (
                    <>
                      {a}
                      <Text variant="small">|</Text>
                      {x}
                    </>
                  )
                )}
          </Stack>
        </Stack>
      </Stack>
    )
  }
)
