import {
  ContextualMenuItemType,
  IconButton,
  IContextualMenuItem,
  Stack
} from '@fluentui/react'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IAccount } from '../../api/account.types'
import { navigationActions } from '../../store/ui/actions'
import { marginRatePanelActions } from '../MarginRateAdjustment/store/marginRatePanel'
import { relatedPartyPanelActions } from '../RelatedPartyPanel/store'

export interface IAccountMenuContainerProps {
  account: IAccount
  addAccountToRelatedPartyLaunchParams?: boolean
}

export const AccountMenuContainer: React.FC<IAccountMenuContainerProps> = ({
  account,
  addAccountToRelatedPartyLaunchParams
}) => {
  const dispatch = useDispatch()
  const {
    CustodyAccount,
    CustodianCode,
    CustodianType,
    id: accountId = '',
    accountId: uniqueId = ''
  } = account
  const tamaracAccountId = account?.VendorTamarac?.UploadAccountId

  const launchWealthscape = () =>
    dispatch(
      navigationActions.launchWealthscape(
        `account/profile?AcctNum=${CustodyAccount}`
      )
    )
  const launchTamarac = () =>
    dispatch(
      navigationActions.launchConfigApplication({
        id: 'tamarac',
        postLoginRedirectPath: `#/?accountId=${tamaracAccountId}`
      })
    )

  const openRelatedPartyPanel = () => {
    dispatch(
      relatedPartyPanelActions.openPanel({
        idType: 'accountId',
        id: uniqueId,
        displayName: CustodyAccount || 'Account',
        launchParameters: addAccountToRelatedPartyLaunchParams
          ? { sAcc: accountId }
          : undefined
      })
    )
  }

  const onClickMarginRateAdjustment = useCallback(() => {
    account != null &&
      dispatch(marginRatePanelActions.open(account?.householdId))
  }, [account, dispatch])

  const { setHousehold } = useRdot360Context()
  const navigate = useNavigate()
  const navigateToRdot360 = useCallback(() => {
    const { householdId, id } = account
    if (!householdId || !id) {
      return
    }
    setHousehold({
      householdId,
      selectionType: 'account',
      selectionIds: [id]
    })
    navigate('/advisory/client')
  }, [account, navigate, setHousehold])

  const isNFS = CustodianCode === 'NFS'

  return (
    <Stack
      horizontal={true}
      tokens={{ childrenGap: 10 }}
      styles={{
        root: {
          alignItems: 'center'
        }
      }}
    >
      <IconButton
        title="Account Menu"
        ariaLabel="Menu"
        disabled={false}
        checked={false}
        menuIconProps={{ iconName: 'MoreVertical' }}
        menuProps={{
          shouldFocusOnMount: true,
          items: [
            {
              key: 'navigation',
              itemType: ContextualMenuItemType.Header,
              text: 'Navigation'
            },
            isNFS && {
              key: 'nfs-account-summary',
              onClick: () => {
                launchWealthscape()
              },
              text: 'Wealthscape Account Summary'
            },
            isNFS && {
              key: 'margin-rate-request-tool',
              onClick: () => {
                onClickMarginRateAdjustment()
              },
              text: 'Margin Rate Adjustment'
            },
            !!tamaracAccountId && {
              key: 'tamarac',
              onClick: () => {
                launchTamarac()
              },
              text: 'Tamarac Portfolio'
            },
            CustodianType !== 'External' && {
              key: 'related-parties',
              onClick: () => {
                openRelatedPartyPanel()
              },
              text: 'Related Parties'
            },
            {
              key: 'launch-client-dashboard',
              onClick: navigateToRdot360,
              text: 'Client Dashboard'
            }
          ].filter((x) => x) as IContextualMenuItem[]
        }}
      />
    </Stack>
  )
}
