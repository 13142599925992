export const ReceiveorDisbursement = {
  Receipt: 'Receipt',
  Disbursement: 'Disbursement',
  Both: ''
}

export const RecipientType = {
  FirstParty: 'First Party',
  ThirdParty: 'Third Party',
  ThirdPartyCommon: 'Third Party Common'
}

export const CheckingorSavings = {
  Checking: 'checking',
  Savings: 'savings'
}

export const TransactionType = {
  EFT: 'AD',
  Wire: 'WD',
  Journal: 'JNL'
}

export const SIMMDirection = {
  Both: 'B',
  Disbursement: 'S',
  Receive: 'R'
}
