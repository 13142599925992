import { addHours, differenceInDays, format, startOfDay } from 'date-fns'
import { groupBy, sortBy } from 'lodash'
import { memo, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  DailyTimeSeriesChart,
  ITimeSeriesChartProps,
  ITimeSeriesChartSeries
} from '../../../components/shared/charts/DailyTimeSeriesChart'
import { IGroupedActivitySummary } from '../../../store/types'
import { useActivityDetailUiState } from '../../Activity/activityDetailsUiState'
import { useSharedActivityStore } from '../../shared/activity'
import { useActivityMappings } from './activityMappings'

export const ActivitySummaryChart: React.FC<{
  data?: IGroupedActivitySummary
}> = ({ data }) => {
  const { setCategories } = useSharedActivityStore()
  const { selectedDateRange } = useActivityDetailUiState()
  const navigate = useNavigate()
  const { colorLookup, categoryCodeLookup, categoryLookup, orderLookup } =
    useActivityMappings()
  const onLegendClick = useCallback(
    (categories?: string[]) => {
      setCategories(categories)
      navigate('../activity')
    },
    [navigate, setCategories]
  )
  const onItemClick = useCallback(
    (categories?: string[]) => {
      setCategories(categories)
      navigate('../activity')
    },
    [navigate, setCategories]
  )
  const series: ITimeSeriesChartSeries[] = useMemo(() => {
    const allActivities = data?.groupedActivities?.flatMap((groupedActivity) =>
      groupedActivity.groupedActivityType?.map((activityType) => ({
        date: groupedActivity?.date
          ? startOfDay(new Date(groupedActivity.date))
          : undefined,
        type: activityType?.type,
        mappedType:
          (activityType?.type && categoryLookup[activityType.type]) ||
          categoryLookup['Other'],
        value: activityType?.totalActivities
      }))
    )
    const activityGroups = groupBy(allActivities, (x) => x?.mappedType)
    return sortBy(
      Object.entries(activityGroups)?.map(([key, value]) => ({
        name: key,
        data: value?.map((x) => ({ date: x?.date, value: x?.value })),
        color:
          (value?.[0]?.type && colorLookup[value?.[0]?.type]) ||
          colorLookup['Other'],
        legendIndex: orderLookup[key] || orderLookup['Other']
      })),
      (x) => x.legendIndex
    )
  }, [categoryLookup, colorLookup, data?.groupedActivities, orderLookup]).map(
    (x, i) => ({ ...x, legendIndex: i })
  )
  const min = useMemo(
    () =>
      selectedDateRange?.startDate
        ? new Date(selectedDateRange?.startDate).getTime()
        : undefined,
    [selectedDateRange?.startDate]
  )
  const max = useMemo(
    () =>
      selectedDateRange?.endDate
        ? addHours(new Date(selectedDateRange?.endDate), 8).getTime()
        : undefined,
    [selectedDateRange?.endDate]
  )
  const options: Highcharts.Options = useMemo(
    () => ({
      xAxis: {
        min,
        max
      },
      yAxis: {
        tickLength: 0,
        gridLineColor: '#d6d6d6'
      },
      plotOptions: {
        column: {
          events: {
            click: (ev?: any) => {
              onItemClick(categoryCodeLookup?.[ev?.point?.series?.name] || [])
            }
          }
        },
        series: {
          events: {
            legendItemClick: (ev?: any) => {
              ev?.preventDefault && ev.preventDefault()
              onLegendClick(categoryCodeLookup?.[ev?.target?.name] || [])
            }
          }
        }
      },
      tooltip: {
        formatter: function formatTooltip(
          this: Highcharts.TooltipFormatterContextObject
        ) {
          return `<b>${format(this.x as number, `dd-MMM`)}</b><br/>${
            this.series.name
          }: ${this.y as number}
        <br/>TOTAL: ${this.total}`
        }
      }
    }),
    [categoryCodeLookup, max, min, onItemClick, onLegendClick]
  )

  return (
    <MemoActivityChart
      chartType="column"
      series={series}
      options={options}
      removeDailyTick={
        !!max && !!min && differenceInDays(new Date(max), new Date(min)) > 45
      }
    />
  )
}

const ActivityChart: React.FC<ITimeSeriesChartProps> = ({
  series,
  options,
  chartType,
  removeDailyTick
}) => {
  return (
    <DailyTimeSeriesChart
      chartType={chartType}
      series={series}
      options={options}
      removeDailyTick={removeDailyTick}
    />
  )
}

const MemoActivityChart = memo(ActivityChart)
