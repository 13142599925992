import { FC } from 'react'
import TileHeading, { BaseHeaderProps } from '../../../components/TileHeading'
import { HistoricalBalancesChart } from '../BalanceSummary/HistoricalBalancesChart'

export const HistoricalValueWidgetContainer: FC<BaseHeaderProps> = ({
  onExpand
}) => {
  return (
    <>
      <TileHeading onExpand={onExpand}>
        <div>Historical Value</div>
      </TileHeading>
      <div style={{ height: 280 }}>
        <HistoricalBalancesChart />
      </div>
    </>
  )
}
