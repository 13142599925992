import { Link, Stack, Text } from '@fluentui/react'
import React, { useCallback } from 'react'
import { FormattedNumber } from 'react-intl'
import { useDispatch } from 'react-redux'
import { IAccount } from '../../../api/account.types'
import { ConnectedMaskedText } from '../../../shared/components/MaskedText'
import { navigationActions } from '../../../store/ui/actions'
import { ManagedAccountFeeHoverCard } from '../../Accounts/ManagedAccountFeeHoverCard'
import { PendingDebitHoverCard } from '../../Accounts/PendingDebitHoverCard'
import { AusCell } from '../core/components/AusCell'
import { DateCell } from '../core/components/DateCell'
import { IListsCellComponent } from '../core/components/ListsDataTable'
import { PercentCell } from '../core/components/PercentCell'
import { TextCell } from '../core/components/TextCell'
import { USDCell } from '../core/components/USDCell'
import { AccountNumberCell } from '../shared/AccountNumberCell'
import { AssetAllocationBarContainer } from '../shared/AssetAllocationBarContainer'
import { ProductAllocationBarContainer } from '../shared/ProductAllocationBarContainer'
import { AccountColumnIds } from './ColumnDefinitions'

export const createAccountCell = (cellComponents: {
  [key: string]: React.FC<{ account: IAccount }>
}) => {
  const AccountCellComponent: IListsCellComponent<IAccount> = ({
    columnDefinition,
    item
  }) => {
    const Cell = cellComponents[columnDefinition.id]
    return Cell && <Cell account={item} />
  }

  return AccountCellComponent
}

export const AccountCellComponents: {
  [key: string]: React.FC<{ account: IAccount }>
} = {
  [AccountColumnIds.team]: ({ account }) => (
    <TextCell>{account.ClientAdvisorTeam}</TextCell>
  ),
  [AccountColumnIds.aum]: ({ account }) => (
    <Stack styles={{ root: { minWidth: 0 } }}>
      <USDCell value={account.AccountKPIs?.AumManaged} fractionDigits={0} />
      {!!account.feeSummary?.clientFeePercent && (
        <ManagedAccountFeeHoverCard accountId={account.id || ''}>
          <Text variant="small">
            <Link>
              <FormattedNumber
                value={account.feeSummary.clientFeePercent}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
              />
              %
            </Link>
          </Text>
        </ManagedAccountFeeHoverCard>
      )}
    </Stack>
  ),
  [AccountColumnIds.aus]: ({ account }) => (
    <AusCell KPI={account.AccountKPIs} />
  ),
  [AccountColumnIds.brokerageAssets]: ({ account }) => (
    <USDCell value={account.AccountKPIs?.AumBrokerage} fractionDigits={0} />
  ),
  [AccountColumnIds.purchasingPower]: ({ account }) => {
    const dispatch = useDispatch()
    const launchWealthscape = useCallback(
      () =>
        dispatch(
          navigationActions.launchWealthscape(
            `account/balances?AcctNum=${account.id}`
          )
        ),
      [account.id, dispatch]
    )
    const usd = (
      <USDCell value={account.AccountKPIs?.cashAvlToTrade} fractionDigits={0} />
    )
    return <Link onClick={launchWealthscape}>{usd}</Link>
  },
  [AccountColumnIds.assets_liabilities]: ({ account }) => (
    <USDCell
      value={account.AccountKPIs?.AssetsLiabilities}
      fractionDigits={0}
    />
  ),
  [AccountColumnIds.loansOutstanding]: ({ account }) => (
    <USDCell value={account.AccountKPIs?.LoanOutstanding} fractionDigits={0} />
  ),
  [AccountColumnIds.netNewMoney]: ({ account }) => {
    const dispatch = useDispatch()
    const launchWealthscape = useCallback(
      () =>
        dispatch(
          navigationActions.launchWealthscape(
            `account/history?AcctNum=${account.id}`
          )
        ),
      [account.id, dispatch]
    )
    const usd = (
      <USDCell value={account.AccountKPIs?.NetNewMoney} fractionDigits={0} />
    )
    return <Link onClick={launchWealthscape}>{usd}</Link>
  },
  [AccountColumnIds.netNewAssets]: ({ account }) => {
    const dispatch = useDispatch()
    const launchWealthscape = useCallback(
      () =>
        dispatch(
          navigationActions.launchWealthscape(
            `account/history?AcctNum=${account.id}`
          )
        ),
      [account.id, dispatch]
    )
    const usd = <USDCell value={account.AccountKPIs?.TOA} fractionDigits={0} />
    return <Link onClick={launchWealthscape}>{usd}</Link>
  },
  [AccountColumnIds.legalEntityName]: ({ account }) => (
    <TextCell>{account.LegalEntityName}</TextCell>
  ),
  [AccountColumnIds.legalEntityId]: ({ account }) => (
    <TextCell>{account.LegalEntityID}</TextCell>
  ),
  [AccountColumnIds.clientAdvisor]: ({ account }) => (
    <TextCell>{account.ClientAdvisor}</TextCell>
  ),
  [AccountColumnIds.clientAdvisorId]: ({ account }) => (
    <TextCell>{account.ClientAdvisorID}</TextCell>
  ),
  [AccountColumnIds.accountNumber]: ({ account }) => (
    <AccountNumberCell account={account} />
  ),
  [AccountColumnIds.accountManagedType]: ({ account }) => (
    <TextCell>{account?.accounttype}</TextCell>
  ),
  [AccountColumnIds.accountRegistrationType]: ({ account }) => (
    <TextCell>{account?.registrationtype}</TextCell>
  ),
  [AccountColumnIds.accountStatus]: ({ account }) => (
    <TextCell>{account?.accountStatus}</TextCell>
  ),
  [AccountColumnIds.accountTaxableStatus]: ({ account }) => (
    <TextCell>{account?.taxstatus}</TextCell>
  ),
  [AccountColumnIds.accountModelName]: ({ account }) => (
    <TextCell>{account?.modelName}</TextCell>
  ),
  [AccountColumnIds.accountModelType]: ({ account }) => (
    <TextCell>{account?.modelType}</TextCell>
  ),
  [AccountColumnIds.qtdNetReturn]: ({ account }) => (
    <PercentCell value={account?.AcctPerformance?.QTDNetReturn} />
  ),
  [AccountColumnIds.mtdNetReturn]: ({ account }) => (
    <PercentCell value={account?.AcctPerformance?.MTDNetReturn} />
  ),
  [AccountColumnIds.ytdNetReturn]: ({ account }) => (
    <PercentCell value={account?.AcctPerformance?.YTDNetReturn} />
  ),
  [AccountColumnIds.estRMDAmount]: ({ account }) => (
    <USDCell value={account?.RMD?.estRMDAmount} />
  ),
  [AccountColumnIds.rmdRemaining]: ({ account }) => (
    <USDCell value={account?.RMD?.estRMDAmountRemain} />
  ),
  [AccountColumnIds.lastUpdated]: ({ account }) => (
    <DateCell value={account.LastUpdatedAt} />
  ),
  [AccountColumnIds.retirementAccount]: ({ account }) => (
    <TextCell>{account?.retirementAccount}</TextCell>
  ),
  [AccountColumnIds.revenueMTD]: ({ account }) => (
    <USDCell value={account?.revenueDet?.MTDcompRevenue} fractionDigits={0} />
  ),
  [AccountColumnIds.revenueQTD]: ({ account }) => (
    <USDCell value={account?.revenueDet?.QTDcompRevenue} fractionDigits={0} />
  ),
  [AccountColumnIds.revenueYTD]: ({ account }) => (
    <USDCell value={account?.revenueDet?.YTDcompRevenue} fractionDigits={0} />
  ),
  [AccountColumnIds.revenueT12]: ({ account }) => (
    <USDCell value={account?.revenueDet?.ttmrevenue} fractionDigits={0} />
  ),
  [AccountColumnIds.custodianName]: ({ account }) => (
    <TextCell>{account?.CustodianName}</TextCell>
  ),
  [AccountColumnIds.custodianCode]: ({ account }) => (
    <TextCell>{account?.CustodianCode}</TextCell>
  ),
  [AccountColumnIds.custodianType]: ({ account }) => (
    <TextCell>{account?.CustodianType}</TextCell>
  ),
  [AccountColumnIds.productAllocation]: ({ account }) => (
    <ProductAllocationBarContainer
      productAllocations={account?.productAllocation || []}
    />
  ),
  [AccountColumnIds.assetAllocation]: ({ account }) => (
    <AssetAllocationBarContainer
      assetAllocations={account?.assetAllocationLvl1 || []}
    />
  ),
  [AccountColumnIds.nickname]: ({ account }) => (
    <TextCell>{account?.AdvisorAddedNickName}</TextCell>
  ),
  [AccountColumnIds.clientNickname]: ({ account }) => (
    <TextCell>{account?.AccountNickname}</TextCell>
  ),
  [AccountColumnIds.shortname]: ({ account }) => (
    <TextCell>{account?.Shortname}</TextCell>
  ),
  [AccountColumnIds.annuity]: ({ account }) => (
    <USDCell value={account?.AccountKPIs?.annuity} />
  ),
  [AccountColumnIds.cashAvaliable]: ({ account }) => (
    <USDCell value={account?.AccountKPIs?.cashAvlToTrade} />
  ),
  [AccountColumnIds.legalEntityType]: ({ account }) => (
    <TextCell>{account?.legalEntityType}</TextCell>
  ),
  [AccountColumnIds.householdId]: ({ account }) => (
    <TextCell>{account?.householdId}</TextCell>
  ),
  [AccountColumnIds.householdName]: ({ account }) => (
    <TextCell>{account?.householdName}</TextCell>
  ),
  [AccountColumnIds.clientFeePercent]: ({ account }) => (
    <PercentCell value={account?.feeSummary?.clientFeePercent} />
  ),
  [AccountColumnIds.managerFeePercent]: ({ account }) => (
    <PercentCell value={account?.feeSummary?.managerFeePercent} />
  ),
  [AccountColumnIds.feeDebitAccount]: ({ account }) => {
    const debitAccount = account.feeSummary?.debitAccount
    return debitAccount ? <ConnectedMaskedText text={debitAccount} /> : <>--</>
  },
  [AccountColumnIds.cashAvailableAfterNextFee]: ({ account }) => (
    <USDCell
      currencySign="accounting"
      value={account.feeSummary?.cashAfterFees}
    />
  ),
  [AccountColumnIds.pendingClientFee]: ({ account }) => (
    <USDCell currencySign="accounting" value={account.feeSummary?.clientFee} />
  ),
  [AccountColumnIds.rockefellerFee]: ({ account }) => (
    <USDCell
      currencySign="accounting"
      value={account.feeSummary?.rockefellerFee}
    />
  ),
  [AccountColumnIds.managerFee]: ({ account }) => (
    <USDCell currencySign="accounting" value={account.feeSummary?.managerFee} />
  ),
  [AccountColumnIds.pendingManagedAccountFeeDebit]: ({ account }) => {
    return account.feeSummary?.totalPendingAccountDebit &&
      account.feeSummary?.totalPendingAccountDebit !==
        account.feeSummary?.clientFee ? (
      <Stack>
        <PendingDebitHoverCard id={account.id || ''}>
          <Text variant="small">
            <Link>
              <USDCell
                currencySign="accounting"
                value={account.feeSummary?.totalPendingAccountDebit}
              />
            </Link>
          </Text>
        </PendingDebitHoverCard>
      </Stack>
    ) : (
      <USDCell
        currencySign="accounting"
        value={account.feeSummary?.totalPendingAccountDebit}
      />
    )
  },
  [AccountColumnIds.marginAgreement]: ({ account }) => (
    <TextCell>{account?.marginAgreement}</TextCell>
  ),
  [AccountColumnIds.multipleMargin]: ({ account }) => (
    <TextCell>{account?.multipleMargin}</TextCell>
  ),
  [AccountColumnIds.cashAvailableToWithdraw]: ({ account }) => {
    const dispatch = useDispatch()
    const launchWealthscape = useCallback(
      () =>
        dispatch(
          navigationActions.launchWealthscape(
            `account/balances?AcctNum=${account.id}`
          )
        ),
      [account.id, dispatch]
    )
    const usd = (
      <USDCell
        currencySign="accounting"
        value={account.AccountKPIs?.cashAvlToWithDraw}
        fractionDigits={0}
      />
    )
    return <Link onClick={launchWealthscape}>{usd}</Link>
  },
  [AccountColumnIds.marginInterestRate]: ({ account }) => (
    <>
      {account?.marginInterestRate != null && <Text>OBFR +&nbsp;</Text>}
      <PercentCell value={account?.marginInterestRate} />
    </>
  ),
  [AccountColumnIds.accountPrimaryEmailAddress]: ({ account }) => (
    <TextCell>{account?.accountPrimaryEmailAddress}</TextCell>
  ),
  [AccountColumnIds.RDOTAccountCategoryCode]: ({ account }) => (
    <TextCell>{account?.RDOTAccountCategoryCode}</TextCell>
  ),
  [AccountColumnIds.RDOTAccountCategoryName]: ({ account }) => (
    <TextCell>{account?.RDOTAccountCategoryName}</TextCell>
  ),
  [AccountColumnIds.liquidAssets]: ({ account }) => (
    <USDCell value={account?.liquidAssets} fractionDigits={0} />
  ),
  [AccountColumnIds.netWorth]: ({ account }) => (
    <USDCell value={account?.NetWorth} fractionDigits={0} />
  ),
  [AccountColumnIds.AdvisedOnly]: ({ account }) => (
    <USDCell value={account.AccountKPIs?.AdvisedOnly} fractionDigits={0} />
  ),
  [AccountColumnIds.LoanOutstandingOutsideNfs]: ({ account }) => (
    <USDCell
      value={account.AccountKPIs?.LoanOutstandingOutsideNfs}
      fractionDigits={0}
    />
  ),
  [AccountColumnIds.LoanOutstandingNfs]: ({ account }) => (
    <USDCell
      value={account.AccountKPIs?.LoanOutstandingNfs}
      fractionDigits={0}
    />
  ),
  [AccountColumnIds.AdminReporting]: ({ account }) => (
    <USDCell
      value={account.AccountKPIs?.AdminReportingAssets}
      fractionDigits={0}
    />
  ),
  [AccountColumnIds.AccountKey]: ({ account }) => (
    <TextCell>{account?.accountkey}</TextCell>
  ),
  [AccountColumnIds.RockConnectAccBalance]: ({ account }) => (
    <USDCell value={account.RockConnectAccBalance} fractionDigits={0} />
  ),
  [AccountColumnIds.Source]: ({ account }) => (
    <TextCell>{account?.Accountsource}</TextCell>
  ),
  [AccountColumnIds.Division]: ({ account }) => (
    <TextCell>{account?.RegionName}</TextCell>
  ),
  [AccountColumnIds.Office]: ({ account }) => (
    <TextCell>{account?.HubName}</TextCell>
  ),
  [AccountColumnIds.BusinessSegment]: ({ account }) => (
    <TextCell>{account?.BusinessSegment}</TextCell>
  ),
  [AccountColumnIds.EmployeeAccount]: ({ account }) => (
    <TextCell>{account?.employeeCode}</TextCell>
  ),
  [AccountColumnIds.investmentObjective1]: ({ account }) => (
    <TextCell>{account?.investmentObjective1}</TextCell>
  ),
  [AccountColumnIds.timeHorizon]: ({ account }) => (
    <TextCell>{account?.timeHorizon}</TextCell>
  ),
  [AccountColumnIds.investmentPurpose]: ({ account }) => (
    <TextCell>{account?.investmentPurpose}</TextCell>
  ),
  [AccountColumnIds.riskTolerance]: ({ account }) => (
    <TextCell>{account?.riskTolerance}</TextCell>
  ),
  [AccountColumnIds.YTD_shortTermLoss]: ({ account }) => (
    <USDCell value={account?.shortTermLoss} fractionDigits={0} />
  ),
  [AccountColumnIds.YTD_shortTermGain]: ({ account }) => (
    <USDCell value={account?.shortTermGain} fractionDigits={0} />
  ),
  [AccountColumnIds.YTD_longTermLoss]: ({ account }) => (
    <USDCell value={account?.longTermLoss} fractionDigits={0} />
  ),
  [AccountColumnIds.YTD_longTermGain]: ({ account }) => (
    <USDCell value={account?.longTermGain} fractionDigits={0} />
  ),
  [AccountColumnIds.YTD_rlzdNetGainLoss]: ({ account }) => (
    <USDCell value={account?.rlzdNetGainLoss} fractionDigits={0} />
  )
}

export const AccountCell = createAccountCell(AccountCellComponents)
