import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useActivityDetailUiState } from '../modules/Activity/activityDetailsUiState'
import { useGetDetailActivitySummaryQuery } from '../store/rdot360AnalyticsApi'
import { useRdot360SelectedAccountsApiContext } from '../store/rdot360Context'

export const useActivityDetails = () => {
  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()
  const { dateRangeString, categories } = useActivityDetailUiState()

  const skip =
    !apiContextAccounts?.length || !dateRangeString || !categories?.length
  const { data, isFetching, error, isUninitialized, isError } =
    useGetDetailActivitySummaryQuery(
      skip
        ? skipToken
        : {
            contextAccounts: apiContextAccounts,
            categories: categories,
            dateRange: dateRangeString
          }
    )

  const activityData = skip || isError ? undefined : data

  return {
    activityData,
    isFetching,
    error,
    isUninitialized
  }
}
