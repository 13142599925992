import {
  DatePicker,
  DateRangeType,
  Dropdown,
  Icon,
  IDropdownOption,
  Label,
  PrimaryButton,
  Stack,
  Text
} from '@fluentui/react'
import { format, startOfMonth } from 'date-fns'
import { cloneDeepWith, get, has } from 'lodash'
import { flow } from 'lodash/fp'
import React, { useCallback, useMemo, useState } from 'react'
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form'
import {
  IHurdleMeasurement,
  IHurdleMetric
} from '../../../../../../../../api/datahub'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../shared'
import { Separator } from '../../../../../../../../shared/components/Separator'
import { isNotNullOrUndefined } from '../../../../../../../../shared/guards'
import { Accordion, AccordionSummary } from './Accordion'
import { IHurdleForm } from './HurdleEditPanel'
import { MetricAccordion } from './MetricAccordion'

const intervalOptions: IDropdownOption[] = [
  { key: 'Annual', text: 'Annual' },
  { key: 'Quarterly', text: 'Quarterly' },
  { key: 'Monthly', text: 'Monthly' }
]

type index = number
type MeasurementItemPath = `hurdle.measurements.${index}`
type MetricsPath = `${MeasurementItemPath}.metrics`

const formatTimestamp = (timestamp?: string) =>
  timestamp && flow(parseDateISOStringInLocalTimezone, formatDate)(timestamp)

const formatDate = (date: Date | undefined | null) =>
  date ? format(date, `MMM yyyy`) : ''

export const MeasurementAccordion: React.FC<{
  onMeasurementDelete?: () => void
  onMeasurementCopy: () => void
  expand: boolean
  title: string
  name: MeasurementItemPath
  disabled?: boolean
}> = ({
  onMeasurementDelete,
  onMeasurementCopy,
  expand,
  title,
  name,
  disabled
}) => {
  const register = useFormContext<IHurdleForm>()
  const errors = get(register.formState.errors, name)
  const measurementPath = name as MeasurementItemPath
  const metricsPath: MetricsPath = `${measurementPath}.metrics`
  const measurement = useWatch<IHurdleForm>({
    name: measurementPath
  }) as IHurdleMeasurement
  const metricFields = useFieldArray<IHurdleForm>({
    name: metricsPath
  })
  const watchedArray = useWatch<IHurdleForm>({
    name: metricsPath as MetricsPath
  }) as IHurdleMetric[]
  const deleteMeasurement = useCallback(
    (ev: any) => {
      ev.stopPropagation()
      if (disabled) {
        return
      }
      onMeasurementDelete?.()
    },
    [disabled, onMeasurementDelete]
  )

  const onCopyMeasurement = useCallback(
    (ev: any) => {
      ev.stopPropagation()
      if (disabled) {
        return
      }
      onMeasurementCopy()
    },
    [disabled, onMeasurementCopy]
  )

  const [expandAll, setExpandAll] = useState<boolean>(true)

  const headerText = useMemo(
    () =>
      measurement?.targetDate || measurement?.intervalOfMeasurement
        ? [
            measurement?.intervalOfMeasurement,
            (measurement?.metrics?.length || 0) > 1
              ? `${watchedArray?.length} Metrics`
              : [
                  watchedArray?.[0]?.metricType,
                  watchedArray?.[0]?.metricValue
                    ? watchedArray?.[0]?.metricType === 'AUS'
                      ? `$${watchedArray?.[0]?.metricValue?.toLocaleString()}`
                      : `${watchedArray?.[0]?.metricValue}%`
                    : undefined
                ]
                  .filter(isNotNullOrUndefined)
                  .join(' | ')
          ]
            .filter(isNotNullOrUndefined)
            .join(' | ')
        : ``,
    [measurement, watchedArray]
  )
  const onMetricCopy = useCallback(
    (metricIndex: number) => {
      const metricCopy = cloneDeepWith(watchedArray[metricIndex], (x) => {
        if (has(x, 'measurementId')) {
          x.measurementId = undefined
        }
        if (has(x, 'metricId')) {
          x.metricId = undefined
        }
        if (has(x, 'payoutId')) {
          x.payoutId = undefined
        }
      })
      metricFields.insert(metricIndex + 1, metricCopy)
    },
    [metricFields, watchedArray]
  )
  const addMetric = useCallback(() => {
    metricFields.append({
      metricType: 'T-12 From Hurdle',
      payouts: []
    })
  }, [metricFields])
  const onMetricDelete = useCallback(
    (metricIndex: number) => {
      metricFields.remove(metricIndex)
    },
    [metricFields]
  )

  return (
    <Accordion defaultExpanded={expand} expandAll={expand} invalid={!!errors}>
      <AccordionSummary>
        <Stack
          horizontal={true}
          verticalAlign="center"
          tokens={{ childrenGap: 5 }}
        >
          <Stack.Item styles={{ root: { width: '220px' } }}>
            <Text variant="medium" styles={{ root: { fontWeight: 'bold' } }}>
              {title}
              {measurement?.targetDate && (
                <span> - {formatTimestamp(measurement?.targetDate)}</span>
              )}
            </Text>
          </Stack.Item>
          <Stack.Item grow={1}>
            <Text variant="small">{headerText}</Text>
          </Stack.Item>
          <Stack.Item>
            <Icon
              iconName="Copy"
              onClick={onCopyMeasurement}
              title="Copy Measurement"
              style={{ opacity: disabled ? '0.5' : '1' }}
            />
          </Stack.Item>
          {onMeasurementDelete && (
            <Stack.Item>
              <Icon
                iconName="Delete"
                onClick={deleteMeasurement}
                title="Delete Measurement"
                style={{ opacity: disabled ? '0.5' : '1' }}
              />
            </Stack.Item>
          )}
        </Stack>
      </AccordionSummary>

      <Stack tokens={{ childrenGap: 3 }}>
        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
          <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
            <Label required>Target Date</Label>
            <Controller
              name={`${measurementPath}.targetDate`}
              control={register.control}
              rules={{ required: 'Select an Interval of Measurement' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => {
                return (
                  <Stack>
                    <DatePicker
                      placeholder="Date"
                      value={
                        value
                          ? parseDateISOStringInLocalTimezone(value)
                          : undefined
                      }
                      onSelectDate={(date) =>
                        onChange(date ? startOfMonth(date).toISOString() : '')
                      }
                      calendarProps={{
                        dateRangeType: DateRangeType.Month,
                        isDayPickerVisible: false
                      }}
                      formatDate={formatDate}
                      styles={{ statusMessage: { margin: '0' } }}
                      textField={error && { errorMessage: error.message }}
                      disabled={disabled}
                    />
                  </Stack>
                )
              }}
            />
          </Stack>
          <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
            <Label required>Measurement Interval</Label>
            <Controller
              name={`${measurementPath}.intervalOfMeasurement`}
              control={register.control}
              rules={{ required: 'Select an Interval of Measurement' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => {
                return (
                  <Dropdown
                    selectedKey={value || null}
                    errorMessage={error && error.message}
                    onChange={(_e, interval) => {
                      onChange(interval?.key)
                    }}
                    placeholder="Select an Interval"
                    options={intervalOptions}
                    disabled={disabled}
                  />
                )
              }}
            />
          </Stack>
        </Stack>
        <Separator />
        <Stack
          horizontal={true}
          horizontalAlign="space-between"
          verticalAlign="start"
        >
          <div
            onClick={() => setExpandAll(!expandAll)}
            style={{ cursor: 'pointer' }}
          >
            <Stack
              horizontal={true}
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
            >
              <Icon iconName={expandAll ? 'ChevronUp' : 'ChevronDown'} />
              <h4 style={{ margin: '5px' }}>Metrics</h4>
            </Stack>
          </div>
          <PrimaryButton
            onClick={addMetric}
            iconProps={{ iconName: 'Add' }}
            disabled={disabled}
          >
            Metric
          </PrimaryButton>
        </Stack>
        <Stack>
          {metricFields?.fields?.map((metric, metricIndex) => (
            <MetricAccordion
              onMetricDelete={
                metricFields?.fields?.length > 1
                  ? () => onMetricDelete(metricIndex)
                  : undefined
              }
              onMetricCopy={() => onMetricCopy(metricIndex)}
              expand={expandAll}
              key={metric.id}
              title={`Metric ${metricIndex + 1}`}
              name={`${metricsPath}.${metricIndex}`}
              disabled={disabled}
            />
          ))}
        </Stack>
      </Stack>
    </Accordion>
  )
}
