import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  IChoiceGroupOption,
  IconButton,
  Label,
  MessageBar,
  MessageBarType,
  Overlay,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Text,
  TooltipHost
} from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { some } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchClientsPilotInfoQuery } from 'store/api/datahub'
import { AccountLinkingValidationRequestType } from '../../api/dynamics'
import { ErrorComponent } from '../../shared/components/Error'
import { LoadingComponent } from '../../shared/components/Loading'
import { isNotNullOrEmpty, isNotNullOrUndefined } from '../../shared/guards'
import { AccountDelinking } from './AccountDelinking'
import { AccountLinkingValidation } from './AccountLinkingValidation'
import {
  accountLinkingPanelActions,
  fidelityCharitable,
  getAccountLinkingPanelError,
  getIsAccountLinkingPanelLoading,
  getIsAccountLinkingPanelOpen
} from './store/accountLinkingPanel'
import {
  accountLinkingValidationActions,
  getAccountLinkingValidationRequestType,
  getAccountLinkingValidations,
  getAccountLinkingValidationSelectedClients,
  getAccountLinkingValidationValidatedAccounts,
  getAccountLinkingValidationValidatedAccountsChecksum,
  getAccountLinkingValidationVerification,
  getIsRockcoEmail
} from './store/accountLinkingValidation'
import { isInvalidEmail } from './store/emailValitdation'

const requestTypeOptions: IChoiceGroupOption[] = [
  { key: 'existing', text: 'Add Accounts to Existing Client / 3rd Party' },
  { key: 'new', text: 'Create New 3rd Party' },
  { key: 'delink', text: 'Delink Accounts' }
]

export const AccountLinkingValidationPanel: React.FC = () => {
  const [attestationIsChecked, setAttestationIsChecked] = useState(false)
  const handleCheckboxChange = useCallback(
    () => setAttestationIsChecked(!attestationIsChecked),
    [attestationIsChecked]
  )
  const dispatch = useDispatch()
  const isPanelOpen = useSelector(getIsAccountLinkingPanelOpen)
  const onDismiss = useCallback(() => {
    dispatch(accountLinkingPanelActions.close())
  }, [dispatch])

  useEffect(() => {
    setAttestationIsChecked(false)
  }, [isPanelOpen])

  const clients = useSelector(getAccountLinkingValidationSelectedClients)
  const accounts = useSelector(getAccountLinkingValidationValidatedAccounts)
  const requestType = useSelector(getAccountLinkingValidationRequestType)

  const checksum = useSelector(
    getAccountLinkingValidationValidatedAccountsChecksum
  )

  const validations = useSelector(getAccountLinkingValidations)

  const validationContent = useMemo(
    () => (
      <Stack>
        {validations.map((validation, i) => (
          <MessageBar messageBarType={MessageBarType.severeWarning} key={i}>
            {validation}
          </MessageBar>
        ))}
      </Stack>
    ),
    [validations]
  )

  const verification = useSelector(getAccountLinkingValidationVerification)
  const error = useSelector(getAccountLinkingPanelError)
  const invalidEmailAddress = useSelector(isInvalidEmail)
  const isLoading = useSelector(getIsAccountLinkingPanelLoading)
  const onRequestTypeChanged = useCallback(
    (ev?: any, option?: IChoiceGroupOption) => {
      if (!option?.key) {
        return
      }

      dispatch(
        accountLinkingValidationActions.setRequestType(
          option.key as AccountLinkingValidationRequestType
        )
      )

      dispatch(accountLinkingValidationActions.setSelectedClients([]))
    },
    [dispatch]
  )
  const selectedClients = useSelector(
    getAccountLinkingValidationSelectedClients
  )
  const selectedClientIds = useMemo(
    () => selectedClients?.map((x) => x.id)?.filter(isNotNullOrUndefined),
    [selectedClients]
  )
  const {
    currentData: pilotInfo,
    isFetching: isPilotInfoFetching,
    isError: isPilotInfoFetchingError
  } = useFetchClientsPilotInfoQuery(
    selectedClientIds?.length ? selectedClientIds : skipToken
  )
  const showPilotMessage = useMemo(
    () =>
      isPilotInfoFetchingError ||
      (!isPilotInfoFetching &&
        some(pilotInfo?.map((x) => !!x.multiCustodianPilotInfo)) &&
        (pilotInfo?.length || 0) > 1),
    [isPilotInfoFetching, isPilotInfoFetchingError, pilotInfo]
  )
  const disableForPilotCheck = useMemo(
    () => showPilotMessage || isPilotInfoFetching,
    [isPilotInfoFetching, showPilotMessage]
  )

  const [delinkingAccounts, setDelinkingAccounts] = useState<string[]>([])
  const addAccount = useCallback(
    (account: string) => {
      setDelinkingAccounts([...(delinkingAccounts || []), account])
    },
    [delinkingAccounts]
  )
  const removeAccount = useCallback(
    (account: string) => {
      setDelinkingAccounts(delinkingAccounts?.filter((x) => x !== account))
    },
    [delinkingAccounts]
  )
  useEffect(() => {
    setDelinkingAccounts([])
  }, [requestType, selectedClients])
  const onSubmit = useCallback(() => {
    dispatch(accountLinkingPanelActions.submit(delinkingAccounts))
  }, [delinkingAccounts, dispatch])
  const isRockcoEmail = useSelector(getIsRockcoEmail)

  const onRenderFooterContent = useCallback(() => {
    const [firstClient] = clients || []
    const { fullname, loginid, role, mfaPhones } = firstClient || {}
    const [firstAccount] = accounts || []
    const { date, time, clientFirstName, clientLastName, method } =
      verification || {}
    const isValidClient =
      requestType === 'existing'
        ? true
        : [fullname, loginid, role, mfaPhones].every(isNotNullOrEmpty)

    const isValidEmail = requestType === 'new' ? !invalidEmailAddress : true
    const canSubmit =
      isValidEmail &&
      !!firstClient &&
      !!firstAccount &&
      ([
        date?.toDateString(),
        time,
        clientFirstName,
        clientLastName,
        method
      ].every(isNotNullOrEmpty) ||
        method === fidelityCharitable) &&
      isValidClient &&
      attestationIsChecked === true &&
      !isRockcoEmail &&
      !disableForPilotCheck

    return (
      <>
        {isLoading && <Overlay styles={{ root: { zIndex: 1 } }} />}
        <Stack tokens={{ childrenGap: 10 }}>
          {error && (
            <ErrorComponent
              errorMessage={`An error occurred: ${
                error?.message || 'Unknown Error'
              }`}
            />
          )}
          {requestType !== 'delink' && (
            <Stack
              horizontal={true}
              tokens={{ childrenGap: 10 }}
              horizontalAlign="space-between"
            >
              <Checkbox
                checked={attestationIsChecked}
                onChange={handleCheckboxChange}
              />
              <MessageBar messageBarType={MessageBarType.warning}>
                Linking these accounts will give the third party access to the
                client’s household summary statement, which may include accounts
                the owner did not intend for the third party to view. By
                submitting this request you acknowledge that you have reviewed
                the household statement linking for accounts being linked and
                that there are no privacy concerns.
              </MessageBar>
            </Stack>
          )}
          {showPilotMessage && (
            <MessageBar messageBarType={MessageBarType.error}>
              A single request for the multiple clients you have selected is not
              available at this time, please submit a single client.
            </MessageBar>
          )}
          <Stack
            horizontal={true}
            tokens={{ childrenGap: 10 }}
            horizontalAlign="space-between"
          >
            <Stack
              horizontal={true}
              tokens={{ childrenGap: 10 }}
              verticalAlign="center"
            >
              {validations && validations.length > 0 && (
                <div>
                  <TooltipHost
                    content={validationContent}
                    tooltipProps={{ maxWidth: '600px' }}
                  >
                    <IconButton
                      iconProps={{ iconName: 'Warning' }}
                      styles={{
                        rootHovered: {
                          color: '#d83b01'
                        },
                        root: {
                          color: '#d83b01'
                        }
                      }}
                    />
                  </TooltipHost>
                </div>
              )}
              {requestType === 'delink' ? (
                <PrimaryButton
                  onClick={onSubmit}
                  disabled={!delinkingAccounts?.length}
                >
                  Submit
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  onClick={onSubmit}
                  disabled={!canSubmit || isLoading}
                >
                  Submit For Approval
                </PrimaryButton>
              )}
              <DefaultButton onClick={onDismiss} disabled={isLoading}>
                Cancel
              </DefaultButton>
            </Stack>
            {checksum && (
              <Stack.Item align="end" styles={{ root: { marginLeft: 'auto' } }}>
                <Text variant="small">{checksum}</Text>
              </Stack.Item>
            )}
          </Stack>
        </Stack>
      </>
    )
  }, [
    clients,
    accounts,
    verification,
    requestType,
    invalidEmailAddress,
    attestationIsChecked,
    isRockcoEmail,
    showPilotMessage,
    isLoading,
    error,
    handleCheckboxChange,
    validations,
    validationContent,
    onSubmit,
    delinkingAccounts?.length,
    onDismiss,
    checksum,
    disableForPilotCheck
  ])

  return (
    <Panel
      headerText="Account Linking"
      isOpen={isPanelOpen}
      onDismiss={onDismiss}
      onOuterClick={() => {
        return
      }}
      isLightDismiss={true}
      closeButtonAriaLabel="Close"
      type={PanelType.custom}
      customWidth="780px"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
      styles={{ content: { flexGrow: 1 } }}
    >
      {isLoading && (
        <Overlay styles={{ root: { zIndex: 1 } }}>
          <LoadingComponent />
        </Overlay>
      )}
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack>
          <Label required>1. Choose a Request Type</Label>
          <ChoiceGroup
            options={requestTypeOptions}
            selectedKey={requestType}
            onChange={onRequestTypeChanged}
          />
        </Stack>
        {requestType !== 'delink' && <AccountLinkingValidation />}
        {requestType === 'delink' && (
          <AccountDelinking
            addAccount={addAccount}
            removeAccount={removeAccount}
            delinkingAccounts={delinkingAccounts}
          />
        )}
      </Stack>
    </Panel>
  )
}
