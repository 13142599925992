import { css } from '@emotion/react'
import { FC, useMemo } from 'react'
import { IndeterminateProgressIndicator } from '../../../components/shared/ProgressIndicator/IndeterminateProgressIndicator'
import TileHeading, { BaseHeaderProps } from '../../../components/TileHeading'
import { useRdot360PerformanceContext } from '../../../store/rdot360Context/useRdot360PerformanceContext'
import PerformanceTable from './PerformanceTable'

const getClasses = () => ({
  performanceContainer: css({
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 5
  }),
  progressIndicatorContainer: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  })
})

const Performance: FC<BaseHeaderProps> = ({ disableExpand, onExpand }) => {
  const classes = useMemo(() => getClasses(), [])
  const { isFetching } = useRdot360PerformanceContext()

  return (
    <>
      <div css={classes.performanceContainer}>
        <TileHeading disableExpand={disableExpand} onExpand={onExpand}>
          Performance
        </TileHeading>
        <PerformanceTable />
      </div>
      {isFetching && (
        <div css={classes.progressIndicatorContainer}>
          <IndeterminateProgressIndicator />
        </div>
      )}
    </>
  )
}

export default Performance
