import { partial } from 'lodash/fp'
import { IPosition } from '../../../api/position.types'
import { AppState } from '../../../store'
import { createListsStore } from '../core/store'
import {
  createDefaultColumnState,
  mapColumnsToFilterDefinitions
} from '../shared/lists'
import { PositionColumnDefinitions } from './ColumnDefinitions'
import { postionExportDefinitions } from './ExportDefinitions'

export const constructInitialPositionListColumnState = partial(
  createDefaultColumnState,
  [PositionColumnDefinitions]
)

export const positionsListStore = createListsStore<IPosition>(
  'position',
  '@features/@lists/@positions',
  {
    columnDefinitions: PositionColumnDefinitions,
    filterDefinitions: mapColumnsToFilterDefinitions(PositionColumnDefinitions)
  },
  (state: AppState) => state.features.lists.position,
  postionExportDefinitions
)

export const { reducer, sagas, actions, selectors } = positionsListStore
