import {
  DatePicker,
  Dropdown,
  IChoiceGroupOption,
  IDropdownOption,
  IStackItemStyles,
  Label,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  TextField
} from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import md5 from 'crypto-js/md5'
import { debounce, keyBy, trim, uniq } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchEnhancedProfileByWealthscapeIdQuery } from 'store/api/datahub'
import { AccountLinkingValidationRequestType } from '../../api/dynamics'
import { ErrorComponent } from '../../shared/components/Error'
import { Separator } from '../../shared/components/Separator'
import { isNotNullOrEmpty } from '../../shared/guards'
import { AccountLinkingAccountList } from './AccountLinkingAccountList'
import { ExistingClient3rdPartySearch } from './ExistingClient3rdPartySearch'
import { fidelityCharitable } from './store/accountLinkingPanel'
import {
  accountLinkingValidationActions,
  getAccountLinkingValidationInputAccounts,
  getAccountLinkingValidationRequestType,
  getAccountLinkingValidations,
  getAccountLinkingValidationSelectedClients,
  getAccountLinkingValidationValidatedAccounts,
  getAccountLinkingValidationVerification,
  getIsRockcoEmail
} from './store/accountLinkingValidation'
import {
  emailValidationFetchActions,
  getEmailValidationFetchError,
  getEmailValidationFetchLoading,
  getEmailValidationFetchResult,
  getPrimaryEmailWarning,
  showInvalidEmailMessage,
  showValidEmailMessage
} from './store/emailValitdation'
import {
  getAccountLinkingValidationAccountsFetchError,
  getAccountLinkingValidationAccountsFetchLoading,
  getAccountLinkingValidationAccountsFetchResult
} from './store/validationAccountsFetch'

const evenWrappedStack: IStackItemStyles = {
  root: {
    maxWidth: '100%',
    minWidth: '175px',
    flexBasis: 0
  }
}

const verificationMethodOptions = [
  { key: 'Phone Call', text: 'Phone Call' },
  { key: 'Video Call', text: 'Video Call' },
  { key: 'In Person', text: 'In Person' },
  { key: fidelityCharitable, text: fidelityCharitable }
]

export const AccountLinkingValidation: React.FC = () => {
  const dispatch = useDispatch()
  const validationAccounts = useSelector(
    getAccountLinkingValidationAccountsFetchResult
  )
  const validationAccountsLoading = useSelector(
    getAccountLinkingValidationAccountsFetchLoading
  )
  const validationAccountsError = useSelector(
    getAccountLinkingValidationAccountsFetchError
  )
  const inputAccounts = useSelector(getAccountLinkingValidationInputAccounts)
  const maxAccountLength = 100

  const [parsedInputAccounts, setParsedInputAccounts] = useState<string[]>([])
  const onAccountListTextChanged = useCallback((_: any, newValue?: string) => {
    const parsedAccounts = uniq(
      trim(newValue)
        .toUpperCase()
        .split(/[\s+,;]/g)
        .map((text) => text?.replace(/[^\w/.]|_/g, ''))
        .filter(isNotNullOrEmpty)
    )

    setParsedInputAccounts(parsedAccounts)
  }, [])

  const debouncedOnAccountListTextChanged = useMemo(
    () => debounce(onAccountListTextChanged, 1500),
    [onAccountListTextChanged]
  )

  const validInputAccounts = useMemo(
    () => parsedInputAccounts.sort().filter((number) => number.length > 3),
    [parsedInputAccounts]
  )

  const validInputAccountsKey = useMemo(
    () => validInputAccounts?.join(''),
    [validInputAccounts]
  )

  const selectedClients = useSelector(
    getAccountLinkingValidationSelectedClients
  )

  const { currentData: enhancedProfile } =
    useFetchEnhancedProfileByWealthscapeIdQuery(
      selectedClients?.length === 1 && selectedClients[0].wsportaluserid
        ? selectedClients[0].wsportaluserid
        : skipToken
    )

  useEffect(() => {
    dispatch(
      accountLinkingValidationActions.setInputAccounts([
        ...validInputAccounts,
        ...(enhancedProfile?.relatedAccounts || [])
          .filter((x) => !x.isInProfile)
          .map(({ accountKey }) => accountKey)
      ])
    )
    dispatch(accountLinkingValidationActions.setValidatedAccounts([]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, validInputAccountsKey, enhancedProfile])

  const validInputAccountsChecksum = useMemo(() => {
    return md5(validInputAccounts.join('')).toString()
  }, [validInputAccounts])

  const validatedAccounts = useSelector(
    getAccountLinkingValidationValidatedAccounts
  )

  const onValidatedAccountSelectionChanged = useCallback(
    (newAccounts?: string[]) => {
      dispatch(
        accountLinkingValidationActions.setValidatedAccounts(newAccounts)
      )
    },
    [dispatch]
  )

  const requestType = useSelector(getAccountLinkingValidationRequestType)

  const resetEmail = useCallback(() => {
    dispatch(emailValidationFetchActions.request(undefined))
  }, [dispatch])

  const validationAccountsLookup = useMemo(
    () =>
      keyBy(
        validationAccounts,
        ({ CustodyAccount }) => CustodyAccount?.toUpperCase() || ''
      ),
    [validationAccounts]
  )

  const notFoundParsedInputAccounts = useMemo(
    () =>
      parsedInputAccounts?.filter(
        (number) => !validationAccountsLookup[number]
      ),
    [parsedInputAccounts, validationAccountsLookup]
  )
  const onRequestTypeChanged = useCallback(
    (ev?: any, option?: IChoiceGroupOption) => {
      if (!option?.key) {
        return
      }

      dispatch(
        accountLinkingValidationActions.setRequestType(
          option.key as AccountLinkingValidationRequestType
        )
      )

      dispatch(accountLinkingValidationActions.setSelectedClients([]))
    },
    [dispatch]
  )
  const [firstClient] = selectedClients || []
  const on3rdPartyNameChange = useCallback(
    (ev: any, text?: string) => {
      if (text && text.length > 100) {
        return
      }
      dispatch(
        accountLinkingValidationActions.setSelectedClients([
          { ...firstClient, fullname: text }
        ])
      )
    },
    [dispatch, firstClient]
  )

  const on3rdPartyEmailBlur = useCallback(
    (ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(emailValidationFetchActions.request(ev.target.value.trim()))
      dispatch(
        accountLinkingValidationActions.setSelectedClients([
          { ...firstClient, loginid: ev.target.value }
        ])
      )
    },
    [dispatch, firstClient]
  )
  const isRockcoEmail = useSelector(getIsRockcoEmail)

  const on3rdPartyRoleChange = useCallback(
    (ev: any, text?: string) => {
      if (text && text.length > 100) {
        return
      }
      dispatch(
        accountLinkingValidationActions.setSelectedClients([
          { ...firstClient, role: text }
        ])
      )
    },
    [dispatch, firstClient]
  )

  const on3rdPartyPhoneChange = useCallback(
    (ev: any, text?: string) => {
      if (text && text.length > 100) {
        return
      }
      dispatch(
        accountLinkingValidationActions.setSelectedClients([
          { ...firstClient, mfaPhones: text }
        ])
      )
    },
    [dispatch, firstClient]
  )

  const validations = useSelector(getAccountLinkingValidations)

  const verification = useSelector(getAccountLinkingValidationVerification)
  const onVerificationDateChanged = useCallback(
    (date?: Date | null) => {
      dispatch(
        accountLinkingValidationActions.setVerification({
          ...verification,
          date: date || undefined
        })
      )
    },
    [dispatch, verification]
  )

  const onVerificationTimeChanged = useCallback(
    (ev?: any, time?: string) => {
      dispatch(
        accountLinkingValidationActions.setVerification({
          ...verification,
          time
        })
      )
    },
    [dispatch, verification]
  )

  const onVerificationClientFirstNameChanged = useCallback(
    (ev: any, clientFirstName?: string) => {
      if (clientFirstName && clientFirstName.length > 1500) {
        return
      }
      dispatch(
        accountLinkingValidationActions.setVerification({
          ...verification,
          clientFirstName
        })
      )
    },
    [dispatch, verification]
  )

  const onVerificationClientLastNameChanged = useCallback(
    (ev: any, clientLastName?: string) => {
      if (clientLastName && clientLastName.length > 1500) {
        return
      }
      dispatch(
        accountLinkingValidationActions.setVerification({
          ...verification,
          clientLastName
        })
      )
    },
    [dispatch, verification]
  )

  const onVerificationMethodChanged = useCallback(
    (ev: any, methodOption?: IDropdownOption) => {
      const method = methodOption?.key as string
      dispatch(
        accountLinkingValidationActions.setVerification({
          ...verification,
          method
        })
      )
    },
    [dispatch, verification]
  )

  const showInvalidEmail = useSelector(showInvalidEmailMessage)
  const showValidEmail = useSelector(showValidEmailMessage)
  const emailValidationLoading = useSelector(getEmailValidationFetchLoading)
  const emailCheckError = useSelector(getEmailValidationFetchError)
  const emailCheckResult = useSelector(getEmailValidationFetchResult)
  const showPrimaryEmailWarning = useSelector(getPrimaryEmailWarning)

  const changeToExisting = useCallback(() => {
    onRequestTypeChanged('', {
      key: 'existing',
      text: 'Add Accounts to Existing Client / 3rd Party'
    })
    dispatch(
      accountLinkingValidationActions.setSelectedClients(
        emailCheckResult?.accounts
      )
    )
  }, [dispatch, emailCheckResult?.accounts, onRequestTypeChanged])

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Separator />

      {requestType === 'existing' && <ExistingClient3rdPartySearch />}

      {requestType === 'new' && (
        <Stack>
          <Label required>
            2. Enter Name, Email Address, Role, and Phone Number for the 3rd
            Party
          </Label>
          <Stack tokens={{ childrenGap: 10 }}>
            <MessageBar>
              For Role, enter the relationship of the 3rd party to the client,
              i.e.: CPA, Attorney, Family Member
            </MessageBar>
            <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
              <Stack.Item styles={evenWrappedStack} grow={1}>
                <TextField
                  value={firstClient?.fullname}
                  placeholder="Name"
                  onChange={on3rdPartyNameChange}
                />
              </Stack.Item>
              <Stack.Item styles={evenWrappedStack} grow={1}>
                <TextField
                  placeholder="Email Address"
                  onBlur={on3rdPartyEmailBlur}
                  onFocus={resetEmail}
                />
              </Stack.Item>
              <Stack.Item styles={evenWrappedStack} grow={1}>
                <TextField
                  value={firstClient?.role}
                  placeholder="Role"
                  onChange={on3rdPartyRoleChange}
                />
              </Stack.Item>
            </Stack>
            <MessageBar>
              {`This phone number will be used for MFA setup (ex: 3rd Party's cell phone #)`}
            </MessageBar>
            <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
              <Stack.Item styles={evenWrappedStack} grow={1}>
                <TextField
                  value={firstClient?.mfaPhones}
                  placeholder="MFA Phone Number"
                  onChange={on3rdPartyPhoneChange}
                />
              </Stack.Item>
              <Stack.Item styles={evenWrappedStack} grow={1}>
                <></>
              </Stack.Item>
              <Stack.Item styles={evenWrappedStack} grow={1}>
                <></>
              </Stack.Item>
            </Stack>
            {emailCheckError && (
              <ErrorComponent
                errorMessage={`Failed to validate email address: ${
                  emailCheckError?.message || 'Unknown Error Occurred'
                }`}
              />
            )}
            {showInvalidEmail && !showPrimaryEmailWarning && !isRockcoEmail && (
              <MessageBar messageBarType={MessageBarType.severeWarning}>
                <span>
                  Email address is being used by exiting online profile.{' '}
                </span>
                <Link onClick={changeToExisting}>
                  Change to Existing Client
                </Link>
              </MessageBar>
            )}
            {isRockcoEmail && (
              <MessageBar messageBarType={MessageBarType.error}>
                Email address with @rockco.com is not allowed.
              </MessageBar>
            )}
            {!showInvalidEmail && showPrimaryEmailWarning && !isRockcoEmail && (
              <MessageBar messageBarType={MessageBarType.severeWarning}>
                Email address is found on an Account Holder or previous 3rd
                Party request.
              </MessageBar>
            )}
            {showValidEmail && !showPrimaryEmailWarning && !isRockcoEmail && (
              <MessageBar messageBarType={MessageBarType.success}>
                Email not in use
              </MessageBar>
            )}
            {emailValidationLoading && (
              <MessageBar>Validating email address is not in use</MessageBar>
            )}
          </Stack>
        </Stack>
      )}

      <Separator />

      <>
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack>
            <Label required>3. Verification</Label>
            <MessageBar>
              Provide the date and time of verification, the client&lsquo;s name
              who requested adding the accounts below, and the method of
              verification (phone call, etc.)
            </MessageBar>
          </Stack>
          <Stack horizontal={true} tokens={{ childrenGap: 5 }} wrap>
            {verification?.method !== fidelityCharitable && (
              <>
                <Stack.Item styles={evenWrappedStack}>
                  <DatePicker
                    placeholder="Date of Verification"
                    value={verification?.date}
                    onSelectDate={onVerificationDateChanged}
                  />
                </Stack.Item>
                <Stack.Item styles={evenWrappedStack}>
                  <TextField
                    type="time"
                    value={verification?.time}
                    placeholder="Time of Verification"
                    onChange={onVerificationTimeChanged}
                  />
                </Stack.Item>
                <Stack.Item styles={evenWrappedStack}>
                  <TextField
                    placeholder="Client First Name"
                    value={verification?.clientFirstName}
                    onChange={onVerificationClientFirstNameChanged}
                  />
                </Stack.Item>
                <Stack.Item styles={evenWrappedStack}>
                  <TextField
                    placeholder="Client Last Name"
                    value={verification?.clientLastName}
                    onChange={onVerificationClientLastNameChanged}
                  />
                </Stack.Item>
              </>
            )}
            <Stack.Item styles={evenWrappedStack}>
              <Dropdown
                options={verificationMethodOptions}
                placeholder="Verification Method"
                selectedKey={verification?.method}
                onChange={onVerificationMethodChanged}
              />
            </Stack.Item>
          </Stack>
        </Stack>

        <Separator />

        <div>
          <Stack
            horizontal={true}
            horizontalAlign="space-between"
            verticalAlign="baseline"
          >
            <Label required>4. Enter Accounts</Label>
            {validInputAccountsChecksum && (
              <Text
                block={true}
                variant="small"
                styles={{ root: { textAlign: 'right' } }}
              >
                {validInputAccountsChecksum}
              </Text>
            )}
          </Stack>
          <TextField
            placeholder="Type or paste list of account numbers, comma or new line separated, ie: RJL-111111, RJM-000000"
            multiline={true}
            styles={{ field: { minHeight: '150px', maxHeight: '500px' } }}
            onChange={debouncedOnAccountListTextChanged}
          />
        </div>

        {validationAccountsError && (
          <ErrorComponent errorMessage={validationAccountsError?.message} />
        )}
        {notFoundParsedInputAccounts && notFoundParsedInputAccounts.length ? (
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
          >
            The following accounts were not found:{' '}
            {notFoundParsedInputAccounts.join(', ')}
          </MessageBar>
        ) : null}
        {inputAccounts && inputAccounts.length > maxAccountLength && (
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
          >
            A maximum of {maxAccountLength} accounts can be requested at one
            time.
          </MessageBar>
        )}

        <Separator />
        <div>
          <Label required>5. Validate Accounts</Label>
          <MessageBar isMultiline={true} dismissButtonAriaLabel="Close">
            Validate the accounts in the list below by clicking the checkbox to
            the left of each account that you would like to add to the Client or
            3rd Party&apos;s account linking. Once you have validated the
            accounts, click the <b>Submit For Approval</b> button to submit the
            request.
          </MessageBar>
        </div>
        <AccountLinkingAccountList
          accounts={validationAccounts}
          selectedAccounts={validatedAccounts}
          loading={validationAccountsLoading}
          onSelectionChanged={onValidatedAccountSelectionChanged}
          enhancedProfile={enhancedProfile}
        />
        <Separator />
        <Stack>
          <Label>6. Review Warnings</Label>
          {validations &&
            validations.length > 0 &&
            validations.map((validation, i) => (
              <MessageBar messageBarType={MessageBarType.severeWarning} key={i}>
                {validation}
              </MessageBar>
            ))}
          {!validations.length && (
            <MessageBar messageBarType={MessageBarType.success}>
              No validation warnings
            </MessageBar>
          )}
        </Stack>
      </>
    </Stack>
  )
}
